import {useNavigate, useParams} from "react-router-dom";
import {useCallback, useContext, useEffect, useMemo, useRef} from "react";
import {MeetingContext} from "../../Povider";
import VideoTiles from "./VideoTiles";
// import

const Meeting = () => {

    const params = useParams();
    const navigate = useNavigate();
    const meetingContext:any = useContext(MeetingContext);

    const videoTiles = useMemo(() => Object.values(meetingContext.realtimeAttendees), [meetingContext?.realtimeAttendees]);

    const videoRef = useRef(null);

    const joinMeeting = useCallback(async () => {
        if (!params.id) {
            navigate("/Calls")
        } else {
            const meeting = await meetingContext.getMeeting({meetingId: params.id});
            const attendee = await meetingContext.createAttendee({meetingId: params.id});
            meetingContext.joinMeeting({
                meetingId: meeting.chimeMeetingId,
                attendeeId: attendee.chimeAttendeeId,
            });
            await meetingContext.configureAudioOutput();
            await meetingContext.startAudioInput();
            await meetingContext.startVideoInput();
            await meetingContext.startPreviewLocalVideo(videoRef.current);
            await meetingContext.startSession();
        }
    }, [params.id])

    useEffect(() => {
        (async () => await joinMeeting())();
    }, [joinMeeting]);

    return (<>
        <video ref={videoRef}/>
        {
            Object.keys(meetingContext.realtimeAttendees).length
        }
        <div>
            <button children={"start screen share"} onClick={meetingContext?.startScreenShare}/>
            <button children={"pause screen share"} onClick={meetingContext?.pauseScreenShare}/>
            <button children={"unpause screen share"} onClick={meetingContext?.unpauseScreenShare}/>
            <button children={"stop screen share"} onClick={meetingContext?.stopScreenShare}/>
        </div>
        <div>
            {
                videoTiles.map((tile:any) => <VideoTiles {...tile}/>)
            }
        </div>
    </>);
};

export default Meeting;