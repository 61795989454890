import { Box, Stack, Typography } from "@mui/material";
import React from "react";
import {
  AvatarMedium,
  BgColorCode,
  GreenArrowButton,
  WelcomeBannerPublicLong,
} from "../../../common";
import { AnonymousComponent } from "../../../common/TopNavbar/Anonymous";
import styles from "./TopPublicNavbar.module.css";
import { useParams } from "react-router-dom";

interface TopPublicNavbarProps {
  drawerOpen: boolean;
  drawerOpenOrCloser: () => void;
}

const TopPublicNavbar = ({
  drawerOpen,
  drawerOpenOrCloser,
}: TopPublicNavbarProps) => {
  const params = useParams<{
    SharedfileId?: string;
  }>();
  return (
    <Stack direction="row" justifyContent="end">
      <Stack
        sx={{
          width: `calc(100% - ${drawerOpen ? "275px" : "60px"})`,
          transition: "width 0.3s ease-in-out",
        }}
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        className={styles.topNavbar}
      >
        <Stack width={"100%"} direction={"row"} alignItems={"center"} gap={2}>
          {!params?.SharedfileId && (
            <GreenArrowButton
              sx={{
                display: drawerOpen ? "none" : "block",
                transform: "rotate(180deg)",
              }}
              onClick={drawerOpenOrCloser}
            />
          )}
          <AnonymousComponent />
        </Stack>
        <Stack
          minWidth={"24rem"}
          maxWidth={"54rem"}
          direction="row"
          gap={1}
          alignItems="center"
        >
          <Typography
          
            sx={{ font: "normal normal 600 15px/21px Source Serif Pro", width:"100%" }}
            children={"Viewing as: [ First name, Last name or email ]"}
          />
          <Box className={styles.divider} />
          <AvatarMedium
            showBadge={true}
            imgSrc={
              "https://miro.medium.com/v2/resize:fit:736/1*YqfVlyCe06DfcPsR3kpYrw.jpeg"
            }
            userName={"Rrrrrr Sssss"}
            bgColorCode={BgColorCode?.Purple}
            onClick={(e) => console.log("Clicked")}
          />
        </Stack>
      </Stack>
      <Stack position={"fixed"} zIndex={"10009"} bottom={"50px"} right={"30%"}>
        <WelcomeBannerPublicLong />
      </Stack>
    </Stack>
  );
};

export default TopPublicNavbar;
