import React, { lazy, Suspense, useState } from "react";
import { Stack, IconButton, Typography } from "@mui/material";
import { XCircleIcon } from "../../../../../images";
import {
  a11yProps,
  StyledTab,
  TabPanel,
  TabsWithState,
} from "../../../../common/CardTabsWithoutIcon";


import  LazyContactsTab from "./tabs/ContactsTab/ContactsTab";
import LazyDetailsTab from "./tabs/DetailsTab/DetailsTab";
interface ChannelDetailsMenuProps {
  channelDetails?:any
  onChangedData?:any
  closeChannelDetailSection?:any
}

const ChannelDetailsMenu: React.FC<ChannelDetailsMenuProps> = ({
  channelDetails , onChangedData,closeChannelDetailSection
}) => {
  const [tabValue, setTabValue] = useState(0);

  const tabs = [
    {
      label: "Details",
      content: <LazyDetailsTab onChannelDataChange={onChangedData}  {...channelDetails} channelDetails={channelDetails} />,
    },
    {
      label: "Contacts",
      content: <LazyContactsTab channelDetails={channelDetails} />,
    },
  ];

  return (
    <Stack
      sx={{
        width: "375px",
        height: "430px",
        bgcolor: "white",
        padding: "15px",
        boxSizing: "border-box",
        gap: "15px",
        zIndex: "200"
        ,position:"fixed",
        top:"174px",
        left:"284px"
       
      }}
    >
      <Stack
        sx={{
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <Typography
          sx={{ font: "normal normal 700 15px/19px Source Serif Pro" }}
        >
          Channel Name
        </Typography>

        <IconButton onClick={closeChannelDetailSection} sx={{ p: 0 }} disableRipple>
          <XCircleIcon w={15} />
        </IconButton>
      </Stack>

      <TabsWithState
        ariaLabel="channel details tabs"
        value={tabValue}
        onChange={(event, newValue) => setTabValue(newValue)}
      >
        {tabs.map((tab, index) => (
          <StyledTab
            key={index}
            disableRipple
            label={tab.label}
            {...a11yProps(index)}
          />
        ))}
      </TabsWithState>

      {tabs.map((tab, index) => (
        <TabPanel key={index} value={tabValue} index={index}>
          <Suspense fallback={<div>Loading...</div>}>{tab.content}</Suspense>
        </TabPanel>
      ))}
    </Stack>
  );
};

export default ChannelDetailsMenu;
