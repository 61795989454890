import React from "react";
import { Avatar, Box, Button, Typography } from "@mui/material";
import { getHTMLContent } from "../../pages/Message/constant";

interface MessageReplyBar {
  message?: string;
  handleEmojiSelect?: any;
  handleSearchChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  handleKeyPress?: (e: React.KeyboardEvent<HTMLInputElement>) => void;
  handleClick?: () => void;
  replyMessage?: string;
  handleCancelReply?: () => void;
  currentChannel: any;
}

const MessageReplyBar: React.FC<MessageReplyBar> = ({
  replyMessage,
  handleCancelReply,
  currentChannel,
}) => {
  return (
    <Box display="flex" flexDirection="column" width="100%">
      {/* Replying to section */}
      {replyMessage && (
        <Box
          style={{
            padding: "10px",
            borderRadius: "4px",
            border: "1px solid #ccc",
          }}
        >
          <Box
            sx={{
              backgroundColor: "#f4f6fa", // Light background for reply section
              borderLeft: "38px solid #775EE2", // Add left border
              padding: "8px 16px", // Padding for the section
              borderRadius: "4px", // Rounded corners
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Box>
              <Typography
                variant="subtitle2"
                sx={{
                  fontWeight: "bold",
                  color: "#3C4043", // Darker color for the text
                }}
              >
                Replying to...
              </Typography>
              <Box style={{ display: "flex" }}>
                <Avatar
                  style={{ margin: "4px" }}
                  alt={"nameLetters"}
                  src={"user.imgSrc"}
                >
                  <Typography>{currentChannel.displayName}</Typography>
                </Avatar>
                <Box style={{ display: "flex", flexDirection: "column" }}>
                  <Typography component="span" sx={{ fontStyle: "italic" }}>
                    {currentChannel.displayName}
                  </Typography>
                  <Typography
                    component="span"
                    sx={{ fontStyle: "italic" }}
                    dangerouslySetInnerHTML={{
                      __html: getHTMLContent(replyMessage),
                    }}
                  ></Typography>
                </Box>
              </Box>
            </Box>
            <Button
              size="small"
              onClick={handleCancelReply}
              sx={{
                textTransform: "none",
                fontSize: "12px",
                padding: "2px 8px",
                borderRadius: "16px",
                color: "#f50057", // Red cancel button
                ":hover": {
                  backgroundColor: "rgba(245, 0, 87, 0.1)", // Hover effect
                },
              }}
            >
              Cancel
            </Button>
          </Box>
        </Box>
      )}
    </Box>
  );
};

export default MessageReplyBar;
