import { Box, Divider, IconButton, Slide, Stack } from "@mui/material";
import React from "react";
import {
  ActivtiyIcon,
  BearishIcon,
  FirstMessageIcon,
  FoldReachIcon,
  LockIcon,
  QuickActionIcon,
  UnlockIcon,
  WorkspaceCIcon,
} from "../../../../../../images";
import {
  BebaAiCloud,
  GreenArrowButton,
  ProjectsSidebar,
  QuickActions,
} from "../../../../../common";
import { CommentFilePanel } from "./CommentFilePanel";
import { useDispatch } from "react-redux";
import { changeLayoutState } from "../../../../../../redux";

type RightSidePanelProps = {
  setRightPanelOpen: any;
  rightPanelOpen: any;
  fileData?: any;
  handleGetRPC?: (data: any) => void;
  rightPanelComponent: string;
};

const RightSidePanel = ({
  rightPanelOpen,
  setRightPanelOpen,
  fileData,
  handleGetRPC,
  rightPanelComponent,
}: RightSidePanelProps) => {
  const dispatch = useDispatch();
  const toggleDrawer = (isOpen: any) => {
    dispatch(
      changeLayoutState({
        type: "isDrawer_CloudStorage",
        value: !isOpen,
      })
    );
  };

  const handlePanelClick = (componentName: any) => {
    // Check if the current component is the same as the clicked one
    const isSameComponent = rightPanelComponent === componentName;

    // Determine if the right panel should be opened
    const shouldOpenRightPanel = !isSameComponent;

    // Update the right panel state
    setRightPanelOpen(shouldOpenRightPanel);

    // Call handleGetRPC with an empty string if closing the right panel
    if (!shouldOpenRightPanel) {
      handleGetRPC && handleGetRPC(""); // Call with empty string when closing
    } else {
      // Call the API function if it exists for opening
      handleGetRPC && handleGetRPC(componentName);
    }

    // Toggle drawer state based on right panel state
    toggleDrawer(shouldOpenRightPanel);
  };

  return (
    <Box sx={{ background: "white", height: "100%", padding: "15px" }}>
      <Stack
        direction={"row"}
        alignItems={"flex-start"}
        height={"93%"}
        justifyContent={"flex-start"}
      >
        <Stack
          direction={"column"}
          alignItems={"center"}
          justifyContent={"space-between"}
          height={"100%"}
        >
          <Stack
            direction={"column"}
            gap={2}
            alignItems={"center"}
            justifyContent={"center"}
          >
            <Stack mt={3} direction={"column"} gap={3}>
              <IconButton sx={{ p: 0, borderRadius: 0 }}>
                <FirstMessageIcon w={25} />
              </IconButton>
              <IconButton
                onClick={() => handlePanelClick("activity")}
                sx={{ p: 0, borderRadius: 0 }}
              >
                <ActivtiyIcon w={25} />
              </IconButton>
              <IconButton sx={{ p: 0, borderRadius: 0 }}>
                {fileData?.locking?.isLocked ? (
                  <LockIcon w={25} />
                ) : (
                  <UnlockIcon w={25} />
                )}
              </IconButton>
            </Stack>
            <Divider
              orientation="horizontal"
              sx={{ borderColor: "#E9EDF2", width: "25px", height: "10px" }}
            />
            <Stack mt={3} direction={"column"} gap={3}>
              <IconButton
                onClick={() => handlePanelClick("project")}
                sx={{ p: 0, borderRadius: 0 }}
              >
                <FoldReachIcon color="#008D4C" w={25} />
              </IconButton>
              <IconButton
                onClick={() => handlePanelClick("calendar")}
                sx={{ p: 0, borderRadius: 0 }}
              >
                <WorkspaceCIcon w={25} />
              </IconButton>
              <IconButton
                onClick={() => handlePanelClick("quickAction")}
                sx={{ p: 0, borderRadius: 0 }}
              >
                <QuickActionIcon w={25} />
              </IconButton>
              <IconButton
                onClick={() => handlePanelClick("bebeAI")}
                sx={{ p: 0, borderRadius: 0 }}
              >
                <BearishIcon width={25} height={25} color="orange" />
              </IconButton>
            </Stack>
          </Stack>

          <Stack>
            <Divider orientation="horizontal" sx={{ borderColor: "#E9EDF2" }} />
            <Stack direction={"column"} gap={2}>
              <GreenArrowButton onClick={() => handlePanelClick("comments")} />
            </Stack>
          </Stack>
        </Stack>

        <Slide direction="left" in={rightPanelOpen} mountOnEnter unmountOnExit>
          <Stack width={"375px"} height={"100%"}>
            {rightPanelComponent === "comments" && <CommentFilePanel />}
            {rightPanelComponent === "project" && <ProjectsSidebar />}
            {rightPanelComponent === "quickAction" && <QuickActions />}
            {rightPanelComponent === "bebeAI" && <BebaAiCloud />}
          </Stack>
        </Slide>
      </Stack>
    </Box>
  );
};

export default RightSidePanel;
