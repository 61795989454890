import React, { useState } from "react";
import { Stack, Typography } from "@mui/material";
import g from "./CreateGroupPopUp.module.css";
import {
  ButtonSmall,
  ButtonType,
  CheckboxMedium,
  IconTextLongDropdown,
  IconTextLongDropdownOptionInterface,
  LargeInputBox,
  SmallButtonColor,
} from "../../../../common";
import {
  AddtoGroupIcon,
  CompaniesIcon,
  DealsIcon,
  PeopleIcon,
} from "../../../../../images";
import { CRMListTemplate } from "../../common/CRMListTemplate";

interface CreateGroupPopUpProps {
  variant: "group" | "groupWithoutObj";
}

const CreateGroupPopUp: React.FC<CreateGroupPopUpProps> = ({ variant }) => {
  const [showTemplate, setShowTemplate] = useState(false);

  const toggleTemplate = () => {
    setShowTemplate((prev) => !prev);
  };

  const getObjComponent = () => {
    switch (variant) {
      case "group":
        return {
          dropdown: (
            <IconTextLongDropdown
              label="Object type"
              options={DUMMY_IconTextLongDropdownOption}
              placeholder="..."
              onChange={(value) => console.log(value)}
              width="100%"
            />
          ),
          iconArea: (
            <Stack
              direction={"row"}
              alignItems={"center"}
              gap={1.2}
              justifyContent={"flex-start"}
            >
              <img
                src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcT8Res_dmXPxLy0JeARawHmzp1NXvjA6Q2StO_5Nfni-RLv5yFKh9wnJ4fnbrQxWmEk1dY&usqp=CAU"
                width={"15px"}
                height={"auto"}
                alt="d"
              />
              <Typography
                sx={{ font: "normal normal 300 10px/13px Source Serif Pro" }}
                children={"Choose your icon"}
              />
            </Stack>
          ),
        };

      case "groupWithoutObj":
        return null;

      default:
        break;
    }
  };

  return (
    <Stack className={g.CreateGroupPopUpBox}>
      <Stack className={g["CreateGroupPopUpBox-inner-box"]}>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="flex-start"
          gap={1}
        >
          <AddtoGroupIcon w={15} />
          <Typography className={g.labelCreateGroupPopUpBox}>Group</Typography>
        </Stack>

        <Stack gap={"15px"}>
          <LargeInputBox
            label={"Group Name"}
            borderColor="#E9EDF2"
            height="30px"
            value=""
            onChange={(e) => {
              const value = typeof e === "string" ? e : e.target.value;
              // setPassword(value);
            }}
          />
          {getObjComponent()?.dropdown}
        </Stack>

        <Stack
          sx={{
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <Stack sx={{ flexDirection: "row", gap: "30px" }}>
            <Stack
              direction={"row"}
              alignItems={"center"}
              gap={1.2}
              justifyContent={"flex-start"}
              onClick={toggleTemplate}
              sx={{ cursor: "pointer" }} // Add cursor pointer to indicate it's clickable
            >
              <Typography
                sx={{ font: "normal normal 300 10px/13px Source Serif Pro" }}
                children={"Start with a template"}
              />
              <CheckboxMedium checked={showTemplate} />{" "}
              {/* Bind the checked state */}
            </Stack>
            {getObjComponent()?.iconArea}
          </Stack>

          {/* buttons here */}
          <Stack
            direction="row"
            alignItems="center"
            gap={1}
            justifyContent="flex-end"
          >
            <ButtonSmall
              label="Cancel"
              types={ButtonType.Button}
              colorVarient={SmallButtonColor.Transparent}
            />
            <ButtonSmall
              label="Create"
              types={ButtonType.Button}
              colorVarient={SmallButtonColor.CRMOrange}
            />
          </Stack>
        </Stack>

        {/* Conditionally render CRMListTemplate */}
        {showTemplate && (
          <Stack>
            <CRMListTemplate />
          </Stack>
        )}
      </Stack>
    </Stack>
  );
};

export default CreateGroupPopUp;

const DUMMY_IconTextLongDropdownOption: IconTextLongDropdownOptionInterface[] =
  [
    {
      id: 1,
      text: "People",
      icon: PeopleIcon,
    },
    {
      id: 2,
      text: "Companies",
      icon: CompaniesIcon,
    },
    {
      id: 3,
      text: "Deals",
      icon: DealsIcon,
    },
  ];
