import { createSlice } from "@reduxjs/toolkit";
import { VideoCenterState } from "./VideoCenterTypes";
import { createMeetingFailure, createMeetingRequest, createMeetingSuccess, getUserMeetingsFailure, getUserMeetingsRequest, getUserMeetingsSuccess } from "./VideoCenterSliceActions";

/*
 * ==============================================
 * Define the initial state for the meeting slice
 * ==============================================
 */

const initialState: VideoCenterState = {
  currentMeeting: {}, // Details of the ongoing meeting
  upcomingMeetings: [], // List of scheduled meetings
  loading: false,
  errors: null,
};

/*
 * ==============================================
 * Create a slice for Fetch meetings
 * ==============================================
 */
const videoCenterSlice = createSlice({
  name: "meeting", // Name of the slice
  initialState, // Initial state of the slice

  reducers: {
    // Reducers to handle different actions

    createMeetingRequest,
    createMeetingSuccess,
    createMeetingFailure,
    getUserMeetingsRequest,
    getUserMeetingsSuccess,
    getUserMeetingsFailure
  },
});

/*
 * ======================================================
 * Export the actions to be used in dispatch calls
 * ======================================================
 */
export const {

    /* get meeting */
    createMeetingRequest: createMeetingRequestAction,
    createMeetingSuccess: createMeetingSuccessAction,
    createMeetingFailure: createMeetingFailureAction,
    /* get meeting */
    getUserMeetingsRequest: getUserMeetingsRequestAction,
    getUserMeetingsSuccess: getUserMeetingsSuccessAction,
    getUserMeetingsFailure: getUserMeetingsFailureAction,

} = videoCenterSlice.actions;

/*
 * ======================================================
 * Export the reducer to be used in the store
 * ======================================================
 */
export default videoCenterSlice.reducer;
