import { Box, Divider, Stack } from "@mui/material";
import { useState, useEffect } from "react";
import { MediumSearchbar, SwitchSmall } from "../../common";
import { ViewMode } from "../Projects";
import ColorTabsEmail from "./components/ColorTabsEmail/ColorTabsEmail";
import { ActivityEmailSidebar, EmailCardManager, InsideEmailTopBar } from "./components";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../redux/store";
import { msalInstance } from "./components/authConfig";
import { AccountInfo } from "@azure/msal-browser";
import { getAllEmails__api } from "../../../redux/Slice/email/emailActions";

type Props = {};
type Params = Record<string, string | undefined>;

type Email = {
  [key: string]: any;
};

const Email = (props: Props) => {
  const { emailId, emailType } = useParams<Params>();
  const [viewMode, setViewMode] = useState<ViewMode>("square");
  const [loading, setLoading] = useState<boolean>(false);
  const [emails, setEmails] = useState<any[]>([]);
  const [error, setError] = useState<string | null>(null);
  const [accessToken, setAccesstoken] = useState<string | null>(null);


  const [isMsalInitialized, setIsMsalInitialized] = useState<boolean>(false);
  const savedUser = localStorage.getItem('loggedInUser');
  const dispatch = useDispatch()

  const fetchEmailsFromBackend = async (accessToken: string, dispatch: any): Promise<void> => {
    try {

      await dispatch(getAllEmails__api(accessToken));
    } catch (err) {
      console.error('Error fetching emails:', err);
    }
  };

  const emailsData = useSelector((state: RootState) => state.emails.emails);


  useEffect(() => {
    const initializeMsal = async () => {
      try {
        await msalInstance.initialize();
        setIsMsalInitialized(true);
        console.log("MSAL is initialized in email");

        const accounts = msalInstance.getAllAccounts();
        let correctAccount: AccountInfo | undefined;

        if (savedUser && accounts.length > 0) {
          correctAccount = accounts.find(account => account.homeAccountId === savedUser);
        }

        if (!correctAccount && accounts.length > 0) {
          correctAccount = accounts[0];
          localStorage.setItem('loggedInUser', correctAccount.homeAccountId);
        }

        if (correctAccount) {
          try {
            const tokenResponse = await msalInstance.acquireTokenSilent({
              account: correctAccount,
              scopes: ['Mail.Read'],
            });
            setAccesstoken(tokenResponse.accessToken)
            localStorage.setItem('accessToken', tokenResponse.accessToken);

            fetchEmailsFromBackend(tokenResponse.accessToken, dispatch)
          } catch (silentError) {
            console.error("Silent token acquisition failed:", silentError);
            setError('Failed to acquire token silently.');
          }
        }
      } catch (err) {
        console.error("MSAL Initialization Error:", err);
        setError('Failed to initialize MSAL');
      }
    };
    initializeMsal()

  }, []);




  const [infoButton, setInfoButton] = useState(false);



  return (
    <Box>


      <Stack p="15px 25px 2px 25px" width={"100%"}>
        <Stack
          direction={"row"}
          alignItems={"flex-start"}
          justifyContent={"flex-start"}
          width={"100%"}
          height={"100%"}
        >
          <Stack width={"100%"} height={"100%"}>
            <Stack
              width={"99.5%"}
              direction={"row"}
              alignItems={"flex-end"}
              justifyContent={"flex-end"}
              gap={2}
              mr={'5px'}
            >
              <SwitchSmall
                viewMode={viewMode}
                setViewMode={setViewMode}
                disabled={Boolean(emailId && emailType)}
              />
              <MediumSearchbar ph={"Search all emails"} />
            </Stack>

            <Stack width={"100%"}>
              <ColorTabsEmail />
              <Divider
                sx={{ borderColor: "#E9EDF2", width: "100%", height: "10px" }}
                orientation="horizontal"
              />
            </Stack>

            <Stack
              direction={"row"}
              alignItems={"flex-start"}
              justifyContent={"flex-start"}
              gap={"5px"}
            >
              <Stack
                sx={{
                  overflow: "hidden",
                  maxHeight: "84%",
                  height: "53rem !important",
                  "&::-webkit-scrollbar": {
                    width: "0 !important",
                  },
                  scrollbarWidth: "none",
                  overflowX: "hidden",
                  overflowY: "scroll",
                  width: "100%",
                }}
              >
                <EmailCardManager viewMode={viewMode} emails={emailsData} />
                {/* <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                >
                  <Typography variant="h6">Please login</Typography>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={handleLogin}
                    sx={{ marginTop: 2 }}
                    disabled={!isMsalInitialized} // Disable login until MSAL is initialized
                  >
                    {isMsalInitialized ? "Login" : "Initializing..."}
                  </Button>
                </Box> */}
              </Stack>

              {emailId && emailType && (
                <Stack minWidth={infoButton ? "72%" : "74%"} mr={'5px'} ml={'20px'}>
                  <InsideEmailTopBar
                    infoButton={() => setInfoButton(!infoButton)}
                  />
                </Stack>
              )}
            </Stack>
          </Stack>

          {emailId && emailType && infoButton && (
            <Stack
              sx={{
                overflow: "hidden",
                maxHeight: "84%",
                height: "53rem !important",
                "&::-webkit-scrollbar": {
                  width: "0 !important",
                },
                scrollbarWidth: "none",
                overflowX: "hidden",
                overflowY: "scroll",
                minWidth: "241px",
              }}
            >
              <ActivityEmailSidebar />
            </Stack>
          )}
        </Stack>
      </Stack>
    </Box>
  );
};

export default Email;
