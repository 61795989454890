import React, { useState, MouseEvent } from "react";
import { IconButton, Stack, Typography, Popover } from "@mui/material";
import { SettingsIcon } from "../../../../../images";
import { CheckboxMedium } from "../../../../common";
import {
  SettingsMessagesMainList,
  SettingsMessagesMainListInterface,
} from "./SettingsMessagesMainTypes";
import { adjustHexColor } from "../../../../../Utils";

interface SettingsMessagesMainProps {
  options?: SettingsMessagesMainListInterface[];
  onChange?: (updatedList: SettingsMessagesMainListInterface[]) => void;
  displayOption?: "left" | "right";
}

const SettingsMessagesMain: React.FC<SettingsMessagesMainProps> = ({
  options = SettingsMessagesMainList,
  onChange,
  displayOption = "left",
}) => {
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const [updatedOptions, setUpdatedOptions] = useState(options);

  const handleClick = (event: MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
    if (onChange) {
      onChange(updatedOptions);
    }
  };

  const handleOptionToggle = (index: number) => {
    const newOptions = updatedOptions.map((item, idx) =>
      idx === index ? { ...item, isSelected: !item.isSelected } : item
    );
    setUpdatedOptions(newOptions);
  };

  const open = Boolean(anchorEl);

  return (
    <>
      <IconButton
        sx={{ p: 0, borderRadius: "5px" }}
        disableRipple
        onClick={handleClick}
      >
        <SettingsIcon w={15} />
      </IconButton>

      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: displayOption === "left" ? "right" : "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: displayOption === "right" ? "left" : "right",
        }}
        PaperProps={{
          style: {
            marginTop: "5px",
            boxShadow: "0px 3px 3px 0px rgba(0, 0, 0, 0.15)",
            borderRadius: "5px",
            padding: "0px",
          },
        }}
      >
        <Stack
          sx={{
            bgcolor: "white",
            borderRadius: "5px",
            minWidth: "150px",
          }}
        >
          {updatedOptions.map((item, index) => (
            <Stack
              key={index}
              sx={{
                height: "30px",
                padding: "0px 10px",
                flexShrink: 0,
                flexDirection: "row",
                alignItems: "center",
                cursor: "pointer",
                gap: "5px",
                ":hover": { backgroundColor: adjustHexColor("#E9EDF2", 50) },
              }}
              onClick={() => handleOptionToggle(index)}
            >
              <CheckboxMedium boxSize={15} checked={item.isSelected} />
              <Typography
                sx={{
                  font: "normal normal 300 11px/14px Source Serif Pro",
                }}
              >
                {item.value}
              </Typography>
            </Stack>
          ))}
        </Stack>
      </Popover>
    </>
  );
};

export default SettingsMessagesMain;
