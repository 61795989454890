import { IconButton, Stack, Tooltip, Typography, Popover } from "@mui/material";
import React, { useState } from "react";
import { AvatarNormal } from "../../../../common";
import { UserCount } from "../EmailCardComponents";
import { InfoIconBlack, TranslateIcon } from "../../../../../images";
import { DUMMY_EMAIL_CARD_USER } from "../EmailCardComponents/UserInfoChip/UserInfoChipType";
import { TranslatePopup } from "../TranslatePopup";

interface EmailPreviewAreaProps {infoButton?:any}

const EmailPreviewArea: React.FC<EmailPreviewAreaProps> = ({infoButton}) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  // Handle Translate button click
  const handleTranslateClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "translate-popover" : undefined;

  return (
    <Stack
      sx={{
        flexGrow: 1,
        height: "100%",
        gap: "20px",
      }}
    >
      <Stack
        sx={{
          flexDirection: "row",
          alignItems: "start",
          justifyContent: "space-between",
        }}
      >
        <Stack sx={{ gap: "10px" }}>
          <Stack
            sx={{ flexDirection: "row", gap: "10px", alignItems: "center" }}
          >
            <AvatarNormal size={30} username="Jay Jenners" />
            <Typography
              sx={{ font: "normal normal 900 13px/16px Source Serif Pro" }}
            >
              Jay Jenners
            </Typography>
            <Typography
              sx={{ font: "normal normal 300 11px/14px Source Serif Pro" }}
            >
              jayjenners@gmail.com
            </Typography>
          </Stack>
          <Stack
            sx={{ flexDirection: "row", alignItems: "center", gap: "10px" }}
          >
            <Typography
              sx={{ font: "normal normal 400 11px/14px Source Serif Pro" }}
            >
              to:
            </Typography>
            <Typography
              sx={{ font: "normal normal 300 11px/14px Source Serif Pro" }}
            >
              me, Johnathan Striker, Johnathan Striker,
            </Typography>
            <UserCount userList={DUMMY_EMAIL_CARD_USER} />
          </Stack>
        </Stack>

        <Stack sx={{ flexDirection: "row", alignItems: "center", gap: "20px" }}>
          <Tooltip title="Details" arrow placement="top">
            <IconButton onClick={() => infoButton()} sx={{ p: "2.5px" }} disableRipple>
              <InfoIconBlack w={15} />
            </IconButton>
          </Tooltip>

          <Tooltip title="Translate" arrow placement="top">
            <IconButton
              sx={{ p: "2.5px" }}
              disableRipple
              onClick={handleTranslateClick}
            >
              <TranslateIcon w={15} />
            </IconButton>
          </Tooltip>

          {/* Popover for Translate Button */}
          <Popover
            id={id}
            open={open}
            anchorEl={anchorEl}
            onClose={handleClose}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "center",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            PaperProps={{
              style: {
                marginTop: "12px",
                boxShadow: "0px 3px 3px 0px rgba(0, 0, 0, 0.15)",
              },
            }}
          >
            <TranslatePopup />
          </Popover>
        </Stack>
      </Stack>

      {/* ---------------------------------------------------------------------- */}

      {/* -- email preview area ---- */}

      <Stack
        sx={{ overflow: "scroll", scrollbarWidth: "none", bgcolor: "#E9EDF2" }}
      >
        <div>scrollable email previeew area here</div>
        <div>scrollable email previeew area here</div>
        <div>scrollable email previeew area here</div>
        <div>scrollable email previeew area here</div>
        <div>scrollable email previeew area here</div>
        <div>scrollable email previeew area here</div>
        <div>scrollable email previeew area here</div>
        <div>scrollable email previeew area here</div>
        <div>scrollable email previeew area here</div>
        <div>scrollable email previeew area here</div>
        <div>scrollable email previeew area here</div>
        <div>scrollable email previeew area here</div>
        <div>scrollable email previeew area here</div>
        <div>scrollable email previeew area here</div>
        <div>scrollable email previeew area here</div>
        <div>scrollable email previeew area here</div>
        <div>scrollable email previeew area here</div>
        <div>scrollable email previeew area here</div>
        <div>scrollable email previeew area here</div>
        <div>scrollable email previeew area here</div>
        <div>scrollable email previeew area here</div>
        <div>scrollable email previeew area here</div>
        <div>scrollable email previeew area here</div>
        <div>scrollable email previeew area here</div>
        <div>scrollable email previeew area here</div>
        <div>scrollable email previeew area here</div>
        <div>scrollable email previeew area here</div>
        <div>scrollable email previeew area here</div>
        <div>scrollable email previeew area here</div>
      </Stack>
    </Stack>
  );
};

export default EmailPreviewArea;
