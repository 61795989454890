import { Box, Popper, Stack, Paper } from "@mui/material";
import React, { useState } from "react";
import {
  addReactionToMessage,
  removeReactionToMessage,
} from "../../../redux/Slice/message/messageActions";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../redux/store";
import { MessagesState } from "../../../redux/Slice/message/messageTypes";
import { AuthState } from "../../../redux";

interface ChatEmojiPreviewProps {
  emoji?: any;
  handleRemove?: any;
  messageId: any;
  userId: any;
}

const ChatEmojiPreview: React.FC<ChatEmojiPreviewProps> = ({
  emoji,
  userId,
  messageId,
}) => {
  const { currentChannelId } = useSelector(
    (state: RootState) => state.message as MessagesState
  );
  const { user } = useSelector((state: RootState) => state.auth as AuthState);

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const dispatch = useDispatch();
  const handleMouseEnter = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMouseLeave = () => {
    setAnchorEl(null);
  };

  function unifiedToEmoji(unified?: string) {
    if (unified) {
      const codePoints = unified.split("-").map((cp) => parseInt(cp, 16));
      return String.fromCodePoint(...codePoints);
    }
  }

  const handleSelectedEmojiToRemove = async () => {
    const isReaction = emoji.usersId.includes(user?.data?._id);
    if (isReaction) {
      const payload = {
        emoji: emoji?.emoji,
        userId: userId,
        messageId: messageId,
        channelId: currentChannelId,
      };
       await dispatch(removeReactionToMessage(payload));
    } else {
      const payload = {
        emoji: emoji?.emoji,
        userId: user?.data?._id,
        messageId: messageId,
        channelId: currentChannelId,
      };
      await dispatch(addReactionToMessage(payload));
    }
  };
  return (
    <Stack onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
      <Stack
        sx={{
          cursor: "pointer",
          fontSize: "16px",
          bgcolor: "#E9EDF2",
          borderRadius: "50px",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "center",
          textAlign: "center",
        }}
        onClick={handleSelectedEmojiToRemove}
      >
        {unifiedToEmoji(emoji?.emoji)}
      </Stack>
      <Popper
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        placement="top"
        onMouseLeave={handleMouseLeave}
        sx={{
          zIndex: 10,
          padding: "5px",
        }}
      >
        <Paper
          sx={{
            boxShadow: "0px 3px 3px 0px rgba(0, 0, 0, 0.15)",
            borderRadius: "5px",
            bgcolor: "white",
          }}
        >
          <Box sx={{ p: 2 }}>{emoji?.usersName?.join(", ")}</Box>
        </Paper>
      </Popper>
    </Stack>
  );
};

export default ChatEmojiPreview;
