import React, { FC, useState } from "react";
import { Stack, Typography } from "@mui/material";
import g from "./CRMListTemplate.module.css";
import { TemplateBlock } from "../TemplateBlock";
import { FILTER_TYPES, TypeRenderComponent } from "./TypeFilterComponent";

interface CRMListTemplateProps {
  height?: string; // New height prop
}

const SAMPLE_TEMPLATES = [
  {
    id: 1,
    name: "Template 1",
    emoji: "🚀",
    description:
      "Sales Driven Template -All the essential tools a Sales team requires to track their deals. Capture key information such as contract value, point of contact, and deal stage, supplemented by automatic relationship analytics.",
    categories: ["sales"],
  },
  {
    id: 2,
    name: "Template 2",
    emoji: "💡",
    description: "Startup Ideas",
    categories: ["startups", "ventureCapital"],
  },
  {
    id: 3,
    name: "Template 3",
    emoji: "💵",
    description: "Venture Capital Movement",
    categories: ["ventureCapital", "investing"],
  },
  {
    id: 4,
    name: "Template 4",
    emoji: "📈",
    description: "HR Development",
    categories: ["hr"],
  },
  {
    id: 5,
    name: "Template 5",
    emoji: "🛠️",
    description: "Operational Strategies",
    categories: ["operations"],
  },
];

const CRMListTemplate: FC<CRMListTemplateProps> = ({ height = "500px" }) => {
  const [activeFilters, setActiveFilters] = useState<string[]>([]);

  const toggleFilter = (filterId: string) => {
    setActiveFilters((prev) => {
      const index = prev?.indexOf(filterId);
      if (index > -1) {
        return prev.filter((f) => f !== filterId);
      } else {
        return [...prev, filterId];
      }
    });
  };

  return (
    <Stack sx={{ flexGrow: 1 }}>
      <Stack
        direction="row"
        alignItems="flex-start"
        justifyContent="flex-start"
        gap={0.3}
      >
        <Stack
          sx={{ maxHeight: height }}
          className={g["crm-list-template__filter-panel"]}
        >
          <Stack className={g["crm-list-template__filter-header"]}>
            <Typography className={g["crm-list-template__filter-heading"]}>
              Filter by type
            </Typography>
          </Stack>
          <Stack className={g["crm-list-template__filter-list"]}>
            {FILTER_TYPES.map((type) => (
              <TypeRenderComponent
                key={type._id}
                IconTypeFilter={type.icon}
                TypeFilter={type.FilterName}
                FilterId={type._id}
                onToggleFilter={toggleFilter}
                isChecked={activeFilters.includes(type._id)}
              />
            ))}
          </Stack>
        </Stack>

        <Stack
          sx={{ maxHeight: height }}
          className={g["crm-list-template__template-list"]}
        >
          {SAMPLE_TEMPLATES.filter(
            (template) =>
              activeFilters.length === 0 ||
              template.categories.some((cat) => activeFilters.includes(cat))
          ).map((template) => (
            <TemplateBlock
              key={template.id}
              templateName={template.name}
              templateEmoji={template.emoji}
              templateDescription={template.description}
              template_fall_under_category={template.categories}
            />
          ))}
        </Stack>
      </Stack>
    </Stack>
  );
};

export default CRMListTemplate;
