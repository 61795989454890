import React, { useState, useRef, useEffect } from "react";
import { InputBase, Typography, Box, Tooltip, SxProps } from "@mui/material";

interface EditableTimeTextFieldProps {
  /** Initial value to display in the input field */
  initialValue: string;
  /** Callback function to handle saving the new value */
  onSave: (newValue: string) => void;
  /** Type of time unit (hours or minutes) */
  variant: "hrs" | "mins";
  /** Maximum number of hours allowed (default is 99) */
  maxHours?: number;
  /** Custom styles for the container box */
  containerSx?: SxProps;
  /** Custom styles for the input field */
  inputSx?: SxProps;
  /** Custom styles for the label */
  labelSx?: SxProps;
  /** Error text to display when validation fails */
  errorText?: string;
  /** Custom label text to display instead of the variant */
  labelText?: string;
}

const EditableTimeTextField: React.FC<EditableTimeTextFieldProps> = ({
  initialValue,
  onSave,
  variant,
  maxHours = 99,
  containerSx,
  inputSx,
  labelSx,
  labelText,
  errorText,
}) => {
  const [isEditing, setIsEditing] = useState(false);
  const [value, setValue] = useState(initialValue);
  const [showError, setShowError] = useState(false);
  const inputRef = useRef<HTMLInputElement>(null);

  const formatValue = (val: string) => {
    const numericValue = parseInt(val, 10);
    return isNaN(numericValue)
      ? "00"
      : numericValue < 10
        ? `0${numericValue}`
        : `${numericValue}`;
  };

  const validateValue = (numericValue: number) => {
    if (variant === "mins") {
      return numericValue >= 0 && numericValue <= 59;
    }
    return numericValue >= 0 && numericValue <= maxHours;
  };

  const handleSave = (formattedValue: string) => {
    const numericValue = parseInt(formattedValue, 10);
    if (validateValue(numericValue)) {
      setShowError(false);
      setIsEditing(false);
      setValue(formattedValue);
      onSave(formattedValue);
      inputRef.current?.blur();
    } else {
      setShowError(true);
      inputRef.current?.focus();
    }
  };

  const handleBlur = () => handleSave(formatValue(value));

  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === "Enter") {
      handleSave(formatValue(value));
    }
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const inputValue = event.target.value;
    if (/^\d*$/.test(inputValue)) {
      setValue(inputValue);
      setShowError(false);
    }
  };

  useEffect(() => {
    if (isEditing && inputRef.current) {
      inputRef.current.focus();
    }
  }, [isEditing]);

  return (
    <Tooltip
      title={
        showError
          ? errorText ||
            (variant === "mins"
              ? "Minutes must be between 0 and 59"
              : `Hours must be between 0 and ${maxHours}`)
          : ""
      }
      open={showError}
      placement="top"
      arrow
    >
      <Box
        sx={{
          cursor: "pointer",
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          font: "normal normal 300 14px/20px Source Serif Pro",
          flexShrink: 0,
          ...containerSx,
        }}
        onClick={() => setIsEditing(true)}
      >
        <InputBase
          inputRef={inputRef}
          value={formatValue(value)}
          onChange={handleChange}
          onBlur={handleBlur}
          onKeyDown={handleKeyDown}
          inputProps={{
            inputMode: "numeric",
            pattern: "[0-9]*",
          }}
          sx={{
            height: "20px",
            padding: 0,
            width: "15px",
            // maxWidth: "15px",
            borderBottom: "1px solid transparent",
            font: "inherit",
            color: "black",
            flexShrink: 0,
            borderRadius: "5px",
            boxSizing: "border-box",
            display:'flex',
            alignItems: "center",
            justifyContent: "center",
            textAlign: "center",
            ...inputSx,
          }}
        />

        <Typography
          sx={{
            font: "inherit",
            marginLeft: "4px",
            borderBottom: "1px solid transparent",
            color: "black",
            ...labelSx,
          }}
        >
          {labelText || variant}
        </Typography>
      </Box>
    </Tooltip>
  );
};

export default EditableTimeTextField;
