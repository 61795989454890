import { Box, Stack, Typography } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import { SmallPinnedCard } from "../SmallPinnedCard";
import { adjustHexColor } from "../../../Utils";
import { useDispatch } from "react-redux";
import {
  addChannelIdAction,
  addChannelNameAction,
} from "../../../redux/Slice/message/messageSlice";
import { useNavigate } from "react-router-dom";
import {
  ChannelDetailsMenu,
  MessageDetailsMenus,
} from "../../pages/Message/components";
import {
  hideLeaveForGroup, hideLeaveForPrsonalChat,
  messageDetailsMenusChannelList,
  messageDetailsMenusChatList,
} from "../../pages/Message/components/MessageDetailsMenus/MessageDetailsMenusType";
import {
  getUserConnections,
  updateConnections,
} from "../../../redux/Slice/message/messageActions";
import { getHTMLContent } from "../../pages/Message/constant";

interface ChatDrawerCardProps {
  key?: any;
  userName?: any;
  userIconSrc?: string;
  isUserActive?: boolean;
  message?: string;
  timeStamp?: string;
  companyImgSrc?: string;
  showDetailsLine?: 1 | 2;
  channelId?: any;
  channelName?: string;
  isSelected?: boolean;
  onClick?: any;
  channelDetails?: any;
  userData?: any;
  lastMessage?: any;
}

const ChatDrawerCard: React.FC<ChatDrawerCardProps> = ({
  key,
  companyImgSrc,
  isUserActive,
  message,
  timeStamp,
  userName,
  userIconSrc,
  showDetailsLine = 1,
  channelId,
  channelName,
  isSelected = false,
  channelDetails,
  userData,
  lastMessage,
}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [menuOpen, setMenuOpen] = useState(false);
  const [channelData, setChannelData] = useState({
    chName: "",
    topicName: "",
    chDescription: "",
  });
  const [openChannelDetailSection, setOpenChannelDetailSection] =
    useState<boolean>(false);
  const menuRef = useRef<HTMLDivElement>(null);
  const detailRef = useRef<HTMLDivElement>(null);

  const handleClickOutside = (event: MouseEvent) => {
    if (menuRef.current && !menuRef.current.contains(event.target as Node)) {
      setMenuOpen(false);
    }
  };

  const handleClickOutsideForDetail = (event: MouseEvent) => {
    if(document.getElementById('openunderMenu')){
      return
    }
    if (
      detailRef.current &&
      !detailRef.current.contains(event.target as Node)
    ) {
      setOpenChannelDetailSection(false);
    }
  };

  useEffect(() => {
    if (
      channelData.chName ||
      channelData.topicName ||
      channelData.chDescription
    ) {
      const timer = setTimeout(() => {
        HandleUpdateChannelDetails(channelData);
      }, 3000);

      return () => clearTimeout(timer);
    }
  }, [channelData]);

  useEffect(() => {
    if (menuOpen) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [menuOpen]);

  useEffect(() => {
    if (openChannelDetailSection) {
      document.addEventListener("mousedown", handleClickOutsideForDetail);
    } else {
      document.removeEventListener("mousedown", handleClickOutsideForDetail);
    }
    return () => {
      document.removeEventListener("mousedown", handleClickOutsideForDetail);
    };
  }, [openChannelDetailSection]);

  const handleClicks = async () => {
    await dispatch(addChannelIdAction(channelId));
    await dispatch(addChannelNameAction(channelName));
    navigate(`/Message/${channelId}`);
  };

  const handleRightClick = (event: React.MouseEvent) => {
    event.preventDefault();
    setMenuOpen(true);
    setOpenChannelDetailSection(false);
  };

  const handleClick = async (data: any) => {
    if (data.value?.includes("Pin")) {
      const channelIsPinned = {
        isPinned: data?.isPinned ? false : true,
        userId: userData?.data._id,
      };
      await dispatch(updateConnections(channelId, channelIsPinned));
      await dispatch(getUserConnections(userData?.data._id));
    }
    if (data.value === "View Channel Details") {
      setMenuOpen(false);
      setOpenChannelDetailSection(true);
    }
    if (data.value?.includes("Leave")){
      const channelIsPinned = {
        channelId: channelId,
        userId: userData?.data._id,
        isLeave:true

      };
      await dispatch(updateConnections(channelId, channelIsPinned))
      await dispatch(getUserConnections(userData?.data._id));
    }
  };
  const handleCloseChannelDetailSection = () => {
    setOpenChannelDetailSection(false);
  };

  const HandleOnChangedData = (data: any) => {
    setChannelData({
      chName: data.chName,
      topicName: data.topicName,
      chDescription: data.chDescription,
    });
  };

  const HandleUpdateChannelDetails = async (channelDetailData: any) => {
    if (!channelDetailData.chName || !channelDetailData.chDescription) return;
    if (
      channelDetailData.chName ||
      channelDetailData.topicName ||
      channelDetailData.chDescription
    ) {
      const payload = {
        name: channelDetailData.chName,
        topic: channelDetailData.topicName,
        details: channelDetailData.chDescription,
      };
      try {
        await dispatch(updateConnections(channelId, payload));
        await dispatch(getUserConnections(userData?.data._id));
      } catch (error) {
        console.error("Failed to update channel details:", error);
      }
    }
  };
  
  return (
    <Stack
      key={key}
      sx={{
        flexShrink: 0,
        height: "45px",
        px: "5px",
        flexDirection: "row",
        alignItems: "center",
        gap: "5px",
        flexGrow: 1,
        boxSizing: "border-box",
        borderRadius: "5px",
        width: "100%",
        bgcolor: isSelected ? "#775ee240" : "white",
        ":hover": { bgcolor: adjustHexColor("#fff", 104) },
        cursor: "pointer",
      }}
      onClick={handleClicks}
      onContextMenu={handleRightClick}
    >
      <SmallPinnedCard
        size={30}
        userName={userName}
        isActive={isUserActive}
        userIconSrc={userIconSrc}
      />

      <Stack
        sx={{
          gap: "3px",
          flexDirection: "column",
          width: "calc(100% - (25px + 30px + 30px))",
        }}
      >
        <Typography
          sx={{
            font: "normal normal 600 11px/14px Source Serif Pro",
            overflow: "hidden",
            textOverflow: "ellipsis",
            whiteSpace: "nowrap",
            flexGrow: 1,
          }}
        >
          {channelName}
        </Typography>
        <Typography
          sx={{
            font: "normal normal 300 8px/10px Source Serif Pro",
            overflow: "hidden",
            textOverflow: "ellipsis",
            whiteSpace: "normal",
            display: "-webkit-box",
            WebkitBoxOrient: "vertical",
            WebkitLineClamp: showDetailsLine,
            lineClamp: showDetailsLine,
            flexGrow: 1,
          }}
        >
          <div
            dangerouslySetInnerHTML={{ __html: getHTMLContent(lastMessage) }}
          />
        </Typography>
      </Stack>

      <Stack
        sx={{
          gap: "5px",
          width: "40px",
          flexShrink: 0,
          alignItems: "center",
        }}
      >
        <Typography
          sx={{ font: "normal normal 300 8px/10px Source Serif Pro" }}
        >
          {timeStamp}
        </Typography>
        <Box
          component={"img"}
          sx={{ height: "15px", width: "15px" }}
          src={companyImgSrc}
          alt="logo"
        />
      </Stack>
      {menuOpen && (
        <div ref={menuRef}>
          <MessageDetailsMenus
            handleClick={handleClick}
            options={
                channelDetails?.isGroupChat
                  ? !channelDetails?.currentUserLeave ? messageDetailsMenusChannelList : hideLeaveForGroup
                  :  !channelDetails?.currentUserLeave ? messageDetailsMenusChatList   : hideLeaveForPrsonalChat
            }
          />
        </div>
      )}
      {openChannelDetailSection && (
        <div ref={detailRef}>
          <ChannelDetailsMenu
            onChangedData={HandleOnChangedData}
            channelDetails={channelDetails}
            closeChannelDetailSection={handleCloseChannelDetailSection}
          />
        </div>
      )}
    </Stack>
  );
};

export default ChatDrawerCard;
