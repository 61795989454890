import React from "react";
import { StarToolIcon } from "../../../../../../images";
import { IconButton } from "@mui/material";

interface StarToggleViewProps {
  isToogled: boolean;
  onStarClick?: () => void;
}

const StarToggleView: React.FC<StarToggleViewProps> = ({
  isToogled,
  onStarClick,
}) => {
  return (
    <span
      onClick={onStarClick}
      style={{ height: "15px", width: "15px", cursor: "pointer" }}
    >
      <StarToolIcon w={15} color={isToogled ? "#E0D700" : "#B9B9B9"} />
    </span>
  );
};

export default StarToggleView;
