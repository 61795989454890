import { Stack, Typography } from "@mui/material";
import React from "react";
import {
  DropdownWithImageBadge,
  DUMMY_USER_EMAIL_LIST,
} from "../../../../../../common";

interface FromViewProps {}

const FromView: React.FC<FromViewProps> = ({}) => {
  return (
    <Stack
      sx={{
        flexDirection: "row",
        gap: "10px",
        alignItems: "start",
        borderBottom: "0.5px solid #E9EDF2",
        marginTop: "10px",
      }}
    >
      <Typography
        sx={{
          font: "normal normal 300 14px/18px Source Serif Pro",
          paddingTop: "10px",
        }}
      >
        From
      </Typography>
      <DropdownWithImageBadge
        options={DUMMY_USER_EMAIL_LIST}
        onChangeSelection={() => null}
      />
    </Stack>
  );
};

export default FromView;
