import { createAction } from "@reduxjs/toolkit";
import { createApiAction } from "../constantApi_config";
import {
  addReactionToMessageFailureAction,
  addReactionToMessageRequestAction,
  addReactionToMessageSuccessAction,
  createNewConnectionFailureAction,
  createNewConnectionRequestAction,
  createNewConnectionSuccessAction,
  forwardMessageFailureAction,
  forwardMessageRequestAction,
  forwardMessageSuccessAction,
  getChannelMessageFailureAction,
  getChannelMessageRequestAction,
  getChannelMessageSuccessAction,
  getUserConnectionsFailureAction,
  getUserConnectionsRequestAction,
  getUserConnectionsSuccessAction,
  removeReactionToMessageFailureAction,
  removeReactionToMessageRequestAction,
  removeReactionToMessageSuccessAction,
  scheduleMessageFailureAction,
  scheduleMessageRequestAction,
  scheduleMessageSuccessAction,
  sendMessageFailureAction,
  sendMessageRequestAction,
  sendMessageSuccessAction,
  updateConnectionFailureAction,
  updateConnectionRequestAction,
  updateConnectionSuccessAction,
  updateMessageFailureAction,
  updateMessageRequestAction,
  updateMessageSuccessAction,
  markMessagesAsReadFailureAction,
  markMessagesAsReadRequestAction,
  markMessagesAsReadSuccessAction,
  changeParticipentsRoleRequestAction,
  changeParticipentsRoleFailureAction,
  changeParticipentsRoleSuccessAction,
  getChannelActivitiesSuccessAction,
  getChannelActivitiesRequestAction,
  getChannelActivitiesFailureAction,
  getMessageActivitiesRequestAction,
  getMessageActivitiesSuccessAction,
  getMessageActivitiesFailureAction,
  changeMessagesActivitiesStatusRequestAction,
  changeMessagesActivitiesStatusSuccessAction,
  changeMessagesActivitiesStatusFailureAction,
  setSnoozeMessagesActivitiesRequestAction,
  setSnoozeMessagesActivitiesSuccessAction,
  setSnoozeMessagesActivitiesFailureAction,
} from "./messageSlice";
import { ChannelId, ChatPayload, Message, UserId } from "./messageTypes";

/**
 * Action creator for get channel chats.
 * @param channelId - The channel id to get channel chats.
 * @returns Action object with type and ChannelId.
 */
export const getChannelMessages = (channelId: ChannelId) =>
  createApiAction({
    onStart: getChannelMessageRequestAction.type,
    onSuccess: getChannelMessageSuccessAction.type,
    onError: getChannelMessageFailureAction.type,
    method: "GET",
    url: `/chat/messages?channelId=${channelId.id}`,
    headers: { language: "en" },
    axiosRequired: true, // Explicitly setting axiosRequired
  });

/**
 * Action creator for send user message.
 * @param payload - The Message payload containing user message.
 * @returns Action object with type and payload.
 */
export const sendMessages = (payload: Message) =>
  createApiAction({
    onStart: sendMessageRequestAction.type,
    onSuccess: sendMessageSuccessAction.type,
    onError: sendMessageFailureAction.type,
    method: "POST",
    url: `/chat/send-message/user`,
    data: payload,
    headers: { language: "en" },
    axiosRequired: true,
  });

/**
 * Action creator for get user connections.
 * @param userId - The user id will get give us all user connections.
 * @returns Action object with type and userId.
 */
export const getUserConnections = (userId: UserId) =>
  createApiAction({
    onStart: getUserConnectionsRequestAction.type,
    onSuccess: getUserConnectionsSuccessAction.type,
    onError: getUserConnectionsFailureAction.type,
    method: "GET",
    url: `/chat/channels/${userId}`,
    headers: { language: "en" },
    axiosRequired: true,
  });

/**
 * Action creator for create new connection.
 * @param payload - The chat payload containing connecction detail.
 * @returns Action object with type and payload.
 */
export const createNewConnection = (payload: ChatPayload) =>
  createApiAction({
    onStart: createNewConnectionRequestAction.type,
    onSuccess: createNewConnectionSuccessAction.type,
    onError: createNewConnectionFailureAction.type,
    method: "POST",
    url: `/chat/channels`,
    data: payload,
    headers: { language: "en" },
    axiosRequired: true,
  });

/**
 * Action creator for update connections.
 * @param channelId - The channel id will update connections.
 * @returns Action object with type updated data.
 */
export const updateConnections = (channelId: UserId, payload: any) =>
  createApiAction({
    onStart: updateConnectionRequestAction.type,
    onSuccess: updateConnectionSuccessAction.type,
    onError: updateConnectionFailureAction.type,
    method: "PUT",
    data: payload,
    url: `/chat/channels/${channelId}`,
    headers: { language: "en" },
    axiosRequired: true,
  });

/**
 * Action creator for update message.
 * @param messageId - The message id will update message.
 * @returns Action object with type updated data.
 */
export const updateMessage = (messageId: UserId, payload: any) =>
  createApiAction({
    onStart: updateMessageRequestAction.type,
    onSuccess: updateMessageSuccessAction.type,
    onError: updateMessageFailureAction.type,
    method: "PUT",
    data: payload,
    url: `/chat/send-message/user/${messageId}`,
    headers: { language: "en" },
    axiosRequired: true,
  });

/**
 * Action creator for schedule message.
 * @param payload - The message payload will schedule message.
 * @returns Action object with type schedule data.
 */
export const scheduleMessage = (payload: any) =>
  createApiAction({
    onStart: scheduleMessageRequestAction.type,
    onSuccess: scheduleMessageSuccessAction.type,
    onError: scheduleMessageFailureAction.type,
    method: "POST",
    data: payload,
    url: `/chat/schedule-message`,
    headers: { language: "en" },
    axiosRequired: true,
  });

/**
 * Action creator for forward message.
 * @param payload - The message payload will forward message.
 * @returns Action object with type forward data.
 */
export const forwardMessage = (payload: any) =>
  createApiAction({
    onStart: forwardMessageRequestAction.type,
    onSuccess: forwardMessageSuccessAction.type,
    onError: forwardMessageFailureAction.type,
    method: "POST",
    data: payload,
    url: `/chat/messages/forward`,
    headers: { language: "en" },
    axiosRequired: true,
  });

/**
 * Action creator for add reaction to message.
 * @param payload - The message payload will add reaction message.
 * @returns Action object with type reaction data.
 */
export const addReactionToMessage = (payload: any) =>
  createApiAction({
    onStart: addReactionToMessageRequestAction.type,
    onSuccess: addReactionToMessageSuccessAction.type,
    onError: addReactionToMessageFailureAction.type,
    method: "POST",
    data: payload,
    url: `/chat/message/add/reactions`,
    headers: { language: "en" },
    axiosRequired: true,
  });

/**
 * Action creator for remove reaction to message.
 * @param payload - The message payload will remove reaction message.
 * @returns Action object with type reaction data.
 */
export const removeReactionToMessage = (payload: any) =>
  createApiAction({
    onStart: removeReactionToMessageRequestAction.type,
    onSuccess: removeReactionToMessageSuccessAction.type,
    onError: removeReactionToMessageFailureAction.type,
    method: "DELETE",
    data: payload,
    url: `/chat/message/remove/reactions`,
    headers: { language: "en" },
    axiosRequired: true,
  });

/**
 * Action creator for marking all messages in a channel as read for a user.
 * @param  payload - The payload containing the user ID and channel ID.
 * @returns Action object with type and data for marking messages as read.
 */
export const markMessagesAsRead = (payload: any, channelId: UserId) =>
  createApiAction({
    onStart: markMessagesAsReadRequestAction.type,
    onSuccess: markMessagesAsReadSuccessAction.type,
    onError: markMessagesAsReadFailureAction.type,
    method: "PUT",
    data: payload,
    url: `/chat/channels/${channelId}/messages/read`,
    headers: { language: "en" },
    axiosRequired: true,
  });

/**
 * Action creator for changing user role.
 * @param  payload - The payload containing the user ID and channel ID.
 * @returns Action object with type and data for changing user role.
 */
export const changeUserRole = (payload: any) =>
  createApiAction({
    onStart: changeParticipentsRoleRequestAction.type,
    onSuccess: changeParticipentsRoleSuccessAction.type,
    onError: changeParticipentsRoleFailureAction.type,
    method: "POST",
    data: payload,
    url: `/chat/channel/changeUserRole`,
    headers: { language: "en" },
    axiosRequired: true,
  });

export const getChannelActivities = (userId: any, channelId: any, date?: any) =>
  createApiAction({
    onStart: getChannelActivitiesRequestAction.type,
    onSuccess: getChannelActivitiesSuccessAction.type,
    onError: getChannelActivitiesFailureAction.type,
    method: "GET",
    url: `/chat/activity?userId=${userId}&channelId=${channelId}${date ? `&date=${date}` : ""}`,
    headers: { language: "en" },
    axiosRequired: true,
  });

export const getMessagesActivities = (userId: any,status:any) =>
  createApiAction({
    onStart: getMessageActivitiesRequestAction.type,
    onSuccess: getMessageActivitiesSuccessAction.type,
    onError: getMessageActivitiesFailureAction.type,
    method: "GET",
    url: `/chat/message-activity?userId=${userId}&status=${status}`,
    headers: { language: "en" },
    axiosRequired: true,
  });

export const changeMessagesActivitiesStatus = (payload: any) =>
  createApiAction({
    onStart: changeMessagesActivitiesStatusRequestAction.type,
    onSuccess: changeMessagesActivitiesStatusSuccessAction.type,
    onError: changeMessagesActivitiesStatusFailureAction.type,
    method: "PUT",
    data: payload,
    url: `/chat/activity/update`,
    headers: { language: "en" },
    axiosRequired: true,
  });

export const setSnoozeMessagesActivities = (payload: any) =>
  createApiAction({
    onStart: setSnoozeMessagesActivitiesRequestAction.type,
    onSuccess: setSnoozeMessagesActivitiesSuccessAction.type,
    onError: setSnoozeMessagesActivitiesFailureAction.type,
    method: "PUT",
    data: payload,
    url: `/chat/activity/update`,
    headers: { language: "en" },
    axiosRequired: true,
  });
