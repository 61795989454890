import React from "react";
import { Stack, Typography } from "@mui/material";
import dayjs from "dayjs";
import { getHTMLContent } from "../../pages/Message/constant";

interface MessageHistoryProps {
  editMessageHistory?: any;
  styles?: any;
}

const MessageHistory: React.FC<MessageHistoryProps> = ({editMessageHistory,styles}) => {

  
  return (
    <Stack
      sx={{
        width: "360px",
        maxHeight: "215px",
        overflowY: "scroll",
        scrollbarWidth: "none",
        padding: "10px",
        boxSizing: "border-box",
        bgcolor: "white",
        borderRadius: "5px",
        gap: "15px",
        boxShadow: "0px 3px 3px 0px rgba(0, 0, 0, 0.15)",
        left:"0",
        position: "relative",
        ...styles
      }}
    >
      <Typography sx={{ font: "normal normal 900 11px/14px Source Serif Pro" }}>
        Message edit history...
      </Typography>
      <Stack
        sx={{
          gap: "15px",
          overflow: "auto",
          scrollbarWidth: "none",
        }}
      >
        {/* use here acctual data for map */}
        {editMessageHistory.map((editHistory:any, index:any) => (
            <Stack
                key={index}
                sx={{ flexDirection: "row", gap: "10px", alignItems: "start" }}
            >
                <Typography
                    sx={{
                        font: "normal normal 400 11px/14px Source Serif Pro",
                        width: "62px",
                        flexShrink: 0,
                    }}
                >
                    At {dayjs(editHistory.editedAt).format("hh:mm A")}
                </Typography>
                <Typography
                    sx={{ font: "normal normal 300 13px/16px Source Serif Pro" }}
                >
                    <div dangerouslySetInnerHTML={{ __html: getHTMLContent(editHistory?.content || "") }} />
                </Typography>
            </Stack>
        ))}
      </Stack>
    </Stack>
  );
};

export default MessageHistory;
