import { Stack } from "@mui/material";
import React, { useState } from "react";
import ViewHeaderButton from "../ViewHeaderButton/ViewHeaderButton";
import DefaultQuickMsgView from "./screen/DefaultQuickMsgView";
import CreateQuickMsgView from "./screen/DefaultQuickMsgView";
import { useSelector } from "react-redux";
import { MessagesState } from "../../../../../redux/Slice/message/messageTypes";
import { RootState } from "../../../../../redux/store";

interface MessageViewProps {
  onBackButtonClick: any;
}

const MessageView: React.FC<MessageViewProps> = ({ onBackButtonClick }) => {
  const [messageScreen, setMessageScreen] = useState<"default" | "create">(
    "default"
  );

  const { channels } = useSelector(
    (state: RootState) => state.message as MessagesState
  );

  return (
    <Stack sx={{ flex: 1, height: "100%" }}>
      {messageScreen === "default" ? (
        <>
          <ViewHeaderButton />
          <DefaultQuickMsgView dataArr={channels ? channels : []} />
        </>
      ) : (
        ""
      )}
    </Stack>
  );
};

export default MessageView;
