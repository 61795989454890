import { Box, Fade, Stack } from "@mui/material";
import React, { useState, useEffect, useRef } from "react";
import g from "./insideFile.module.css";
import { RightSidePanel } from "./Components";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import {
  addTime__api,
  getLink__api,
  getOneFile__api,
  selectedFile,
} from "../../../../redux";
import { DocumentViewer, ImageViewer, PdfViewer, VideoPlayer } from "./view";
import { PublicPagePopup } from "../../../common";

const InsideFileCloudStorage = () => {
  const dispatch = useDispatch();
  const { fileId, fileType, SharedfileId } = useParams<{
    fileId?: string;
    fileType?: string;
    SharedfileId?: string;
  }>();

  const [rightPanelOpen, setRightPanelOpen] = useState<boolean>(false);
  const [rightPanelComponent, setRightPanelComponent] = useState<string | "">(
    ""
  );

  const handleGetRPC = (cName: string) => {
    if (cName === "" || !cName) {
      setRightPanelComponent("");
    } else {
      setRightPanelComponent(cName);
    }
  };

  const [fileData, setFileData] = useState<any | null>(null); // Use proper typing
  useEffect(() => {
    const fetchFileData = async () => {
      if (SharedfileId) {
        return;
      }

      if (!fileId || !fileType) return;

      try {
        const action = getOneFile__api(fileId);
        const fileResponse: any = await dispatch(action);

        if (fileResponse?.success && fileResponse?.data) {
          setFileData(fileResponse?.data);
        } else {
          setFileData(null); // Reset to null if not found
        }
      } catch (error) {
        console.error("Error fetching file data:", error);
        setFileData(null); // Reset to null on error
      }
    };

    const fetchSharedFileData = async () => {
      if (!SharedfileId) {
        return;
      }

      try {
        /* https://share.bearishos.com/file/34e4f79087 */
        const action = getLink__api(
          "https://share.bearishos.com/file/34e4f79087"
        );
        const fileResponse: any = await dispatch(action);

        if (fileResponse?.success && fileResponse?.data) {
          setFileData(fileResponse?.data);
          dispatch(selectedFile(fileResponse?.data));
        } else {
          setFileData(null); // Reset to null if not found
        }
      } catch (error) {
        console.error("Error fetching file data:", error);
        setFileData(null); // Reset to null on error
      }
    };

    fetchFileData();
    if (SharedfileId) {
      fetchSharedFileData();
    }
  }, [dispatch, fileId, fileType, SharedfileId]);

  const renderFileComponent = (rightPanelOpen: any) => {
    if (!fileData) return null;

    const extension = fileData?.name?.split(".").pop()?.toLowerCase();
    const path = fileData?.path;

    switch (extension) {
      case "jpg":
      case "jpeg":
      case "png":
      case "webp":
        return <ImageViewer path={path} />;
      case "mp4":
      case "mov":
      case "webm":
        return <VideoPlayer path={path} />;
      case "pdf":
        return <PdfViewer rightPanelOpen={rightPanelOpen} path={path} />;
      case "doc":
      case "docx":
      case "xls":
      case "xlsx":
        return <DocumentViewer path={path} />;
      default:
        return <div>Unsupported file type</div>;
    }
  };
  const intervalIdRef = useRef<NodeJS.Timeout | null>(null); // Allow Timeout type from NodeJS

  const addTimeData = async () => {
    try {
      if (!SharedfileId) return;

      const guestBasic = localStorage.getItem("shareIdGuest_basic");

      // If guest basic details are not present, don't call the API
      if (!guestBasic) return;

      const action = addTime__api("6703f6492ee209c524c8c68c"); // link ID
      const addTimeRes: any = await dispatch(action);

      if (!addTimeRes?.success) {
        console.log(addTimeRes?.message || "An error occurred");

        if (intervalIdRef.current) {
          clearInterval(intervalIdRef.current);
          intervalIdRef.current = null;
        }
      }
    } catch (e) {
      console.log(e);

      if (intervalIdRef.current) {
        clearInterval(intervalIdRef.current);
        intervalIdRef.current = null;
      }
    }
  };

  useEffect(() => {
    if (SharedfileId) {
      // Call the function immediately and then every second
      addTimeData();

      if (!intervalIdRef.current) {
        // Prevents re-creating the interval
        intervalIdRef.current = setInterval(() => {
          addTimeData();
        }, 1000);
      }
    }

    // Cleanup on unmount or when SharedfileId changes
    return () => {
      if (intervalIdRef.current) {
        clearInterval(intervalIdRef.current);
        intervalIdRef.current = null;
      }
    };
  }, [SharedfileId, dispatch]);

  const [guestPermission, setGuestPermission] = useState(false);

  useEffect(() => {
    // Check if SharedfileId exists and localStorage value is missing
    const isLocalStorageAvailable = () => {
      const shareIdGuestBasic = localStorage.getItem("shareIdGuest_basic");
      return !!shareIdGuestBasic;
    };

    if (SharedfileId && !isLocalStorageAvailable()) {
      setGuestPermission(true);
    } else {
      setGuestPermission(false);
    }
  }, [SharedfileId]);

  return (
    <Box
      sx={{
        justifyContent: rightPanelOpen ? "flex-start" : "center",
      }}
      className={g.insideFile__mainContainer}
    >
      <Fade in={guestPermission}>
        <Box sx={style}>
          <div>
            <PublicPagePopup
              onClose={() => setGuestPermission(false)} // Fade out on close
            />
          </div>
        </Box>
      </Fade>

      <Stack
        width={rightPanelOpen ? "65%" : "85%"}
        ml={rightPanelOpen ? "15px" : "0px"}
        height={"96vh"}
        bgcolor={"white"}
      >
        {" "}
        {renderFileComponent(rightPanelOpen)}
      </Stack>

      <Box
        sx={{ position: "fixed", right: "5px", height: "100%", top: "53px" }}
      >
        <RightSidePanel
          fileData={fileData}
          rightPanelOpen={rightPanelOpen}
          rightPanelComponent={rightPanelComponent}
          handleGetRPC={handleGetRPC}
          setRightPanelOpen={setRightPanelOpen}
        />
      </Box>
    </Box>
  );
};
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "fit-content",
  bgcolor: "background.paper",
  border: "none",
  boxShadow: 12,
  p: 0,
  borderRadius: "5px",
};
export default InsideFileCloudStorage;
