import {IconButton, Stack, Typography} from "@mui/material";
import React from "react";
import ItemInsightsAccessCardForChat from "../../../ItemInsightsAccessCard/ItemInsightsAccessCardForChat";

interface ContactsTabProps {
    channelDetails?: any
}

const ContactsTab: React.FC<ContactsTabProps> = ({channelDetails}) => {
    return (
        <Stack sx={{gap: "20px", height: "100%"}}>
            <Stack sx={{flexDirection: "row", justifyContent: "space-between"}}>
                <Typography
                    sx={{font: "normal normal 300 14px/18px Source Serif Pro"}}
                >
                    All contacts
                </Typography>
                <IconButton sx={{p: 0, borderRadius: 0}} disableRipple>
                    <Typography
                        sx={{
                            font: "normal normal 400 14px/18px Source Serif Pro",
                            color: "#000AFF",
                        }}
                    >
                        Share
                    </Typography>
                </IconButton>
            </Stack>

            <Stack
                sx={{
                    flexGrow: 1,
                    overflowY: "scroll",
                    scrollbarWidth: "none",
                    gap: "20px",
                }}
            >
                {channelDetails?.participants
                    ?.filter((userDetail: any) => !userDetail.isLeave)
                    .map((userDetail: any, index: any) => {
                        return (
                            <ItemInsightsAccessCardForChat
                                key={index}
                                userId={userDetail.userId}
                                currentChannelId={channelDetails._id}
                                userNameOrEmail={userDetail?.firstName || userDetail?.email || ""}
                                datestamp={`March 2021`}
                                timestamp={`12:30 AM`}
                                userIconSrc={userDetail.avatar}
                                userRole={userDetail.role}
                                channelDetails={channelDetails}
                            />
                        );
                    })}
            </Stack>
        </Stack>
    );
};

export default ContactsTab;
