import React, { useEffect, useState } from "react";
import {
  Box,
  FormControl,
  IconButton,
  InputBase,
  InputLabel,
  MenuItem,
  Popover,
  Select,
  SelectChangeEvent,
  Stack,
  Tooltip,
  Typography,
} from "@mui/material";
import { InfoIconBlack, PlusIcon } from "../../../../../images";
import S from "./AddToWorkspace.module.css";
import {
  ButtonSmall,
  ButtonType,
  CheckboxMedium,
  StyledTooltip,
} from "../../../../common";
import { OptionDropdown } from "./OptionDropdown";
import {
  AddToWorkspaceOptionsEnum,
  AddToWorkspaceOptionsList,
} from "./AddToWorkspaceType";
import { useDispatch } from "react-redux";
import {
  addComponent__api,
  AddComponentPayload,
  AddDocPayload,
  addDocs__api,
  addWiki__api,
  addWhiteboard__api,
  AddWhiteboardRequest,
  getAllComponents__api,
  GetAllComponentsParams,
  getAllDocs__api,
  getAllWhiteboards__api,
  AddWiki,
  getAllWikis__api,
} from "../../../../../redux";
import { useParams } from "react-router-dom";
interface AddToWorkspaceProps {
  label?: boolean | string;
  workspaceIds?: string;
  documentTypes?: any;
  handleGetWorkspaceItems?: (dontreflect?: boolean) => void;
}
interface defaultValueInterface {
  inputValue: string;
  type: AddToWorkspaceOptionsEnum;
  checkboxSelected: boolean;
}

type Params = Record<string, string | undefined>;
const AddToWorkspace: React.FC<AddToWorkspaceProps> = ({
  label = false,
  workspaceIds = "",
  documentTypes,
  handleGetWorkspaceItems,
}) => {
  const dispatch = useDispatch();

  const [documentList, setDocumentList] = useState(documentTypes || []);

  useEffect(() => {
    if (documentTypes) {
      setDocumentList(documentTypes);
    }
  }, [documentTypes]);

  const defaultValue: defaultValueInterface = {
    inputValue: "",
    type: AddToWorkspaceOptionsEnum.Board,
    checkboxSelected: false,
  };

  const { workspaceId } = useParams<Params>();

  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const [newData, setNewData] = useState<defaultValueInterface>(defaultValue);
  const [doc, setDoc] = React.useState("");

  const handleChange = (event: SelectChangeEvent) => {
    event.preventDefault();
    event.stopPropagation();
    setDoc(event.target.value as string);
  };
  const handleAddComponent = async () => {
    const effectiveWorkspaceId = workspaceId || workspaceIds;
    if (!effectiveWorkspaceId) {
      console.log("No workspaceId found");
      return;
    }

    try {
      let action;
      let postAction;

      switch (newData.type) {
        case "Whiteboard":
          const whiteboardPayload: AddWhiteboardRequest = {
            title: newData.inputValue,
            description: "g",
          };
          action = addWhiteboard__api(effectiveWorkspaceId, whiteboardPayload);
          postAction = async () => {
            const wh = {
              workspaceId: effectiveWorkspaceId,
              page: 1,
              limit: 10,
              sort: "asc",
            };
            await dispatch(getAllWhiteboards__api(wh));
          };
          break;

        case "Document":
          const addMonths = (months: number) => {
            const date = new Date();
            date.setMonth(date.getMonth() + months);
            return date.toISOString();
          };

          const documentPayload: AddDocPayload = {
            title: newData.inputValue,
            content: "Somethings",
            isPublic: true,
            password: "",
            expirationDate: addMonths(6),
          };
          action = addDocs__api(effectiveWorkspaceId, documentPayload);
          postAction = async () => {
            const doc = {
              workspaceId: effectiveWorkspaceId,
              page: 1,
              limit: 10,
              sort: "asc",
              filter: "proposal",
              isStarred: false,
            };
            await dispatch(getAllDocs__api(doc));
            if (handleGetWorkspaceItems) {
              await handleGetWorkspaceItems(true);
            }
          };
          break;

        case "Wiki":
          if (documentList?.length > 0) {
            const wikiPayload: AddWiki = {
              name: newData.inputValue,
              description: "Somethings",
              homePageId: doc || documentList?.[0]?._id,
            };
            action = addWiki__api(wikiPayload, effectiveWorkspaceId);
            postAction = async () => {
              await dispatch(getAllWikis__api(effectiveWorkspaceId));
            };
          } else {
            alert("please create document first to connect to wiki");
          }

          break;

        default:
          const genericPayload: AddComponentPayload = {
            name: newData.inputValue,
            componentType: `${newData.type}`,
            description: "no description",
            isFreeform: newData?.checkboxSelected || false,
          };
          action = addComponent__api(genericPayload, effectiveWorkspaceId);
          postAction = async () => {
            const payload: GetAllComponentsParams = {
              workspaceId: effectiveWorkspaceId,
              componentType: "Board", // Adjust this according to the type
              page: 1,
              limit: 10,
              sort: "asc",
            };
            await dispatch(getAllComponents__api(payload));
          };
          break;
      }

      if (action) {
        await dispatch(action);

        // Execute the respective post-action to fetch data
        if (postAction) {
          await postAction();
        }
      }
    } catch (error) {
      console.error("Failed to add component:", error);
    }
  };

  const handleCreateButtonClick = (e: any) => {
    e.preventDefault();
    e.stopPropagation();
    if (newData.inputValue.trim() === "") {
      return;
    }

    handleAddComponent();

    handleClose(e);
  };

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (e: any) => {
    e.preventDefault();
    e.stopPropagation();
    setAnchorEl(null);
    // Reset the data
    setNewData(defaultValue);
  };

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setNewData((prevData) => ({
      ...prevData,
      inputValue: event.target.value,
    }));
  };

  const handleOptionChange = (selectedOption: AddToWorkspaceOptionsEnum) => {
    setNewData((prevData) => ({
      ...prevData,
      type: selectedOption,
    }));
  };

  const open = Boolean(anchorEl);
  const id = open ? "creat-button-popover" : undefined;
  const NoIcon = () => null;
  return (
    <>
      <IconButton
        aria-describedby={id}
        onClick={handleClick}
        className={S["add-to-workspace__button"]}
        disableRipple
        disableFocusRipple
      >
        <Stack direction={"row"} alignItems={"center"} gap={"5px"}>
          <StyledTooltip title="Add item">
            <PlusIcon w={12} />
          </StyledTooltip>
          {label && (
            <Typography className={S["add-to-workspace__text"]}>
              Add item
            </Typography>
          )}
        </Stack>
      </IconButton>

      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={(e: any) => {
          handleClose(e);
        }}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        PaperProps={{
          className: S["add-to-workspace__popover"],
        }}
      >
        <Stack
          flex={1}
          direction={"column"}
          justifyContent={"space-between"}
          gap={"12px"}
        >
          <Stack className={S["add-to-workspace__content"]}>
            <Typography className={S["add-to-workspace__title"]}>
              Create New
            </Typography>

            {/* --------------Dropdown for options----------------- */}
            <OptionDropdown
              onChange={handleOptionChange}
              options={AddToWorkspaceOptionsList}
              initialValue={defaultValue.type}
            />

            {newData.type === "Wiki" && (
              <>
                <Box sx={{ minWidth: 120 }}>
                  <FormControl fullWidth sx={{ padding: 0 }}>
                    <InputLabel
                      id="demo-simple-select-label"
                      sx={{
                        fontFamily: "Source Serif Pro", // Change to your desired font
                        fontSize: "16px", // Adjust the size as needed
                        marginBottom: "8px", // Optional: adjust margin as needed
                      }}
                    >
                      Select Document
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={doc}
                      sx={{
                        border: "none", // Remove border
                        padding: 0, // Remove padding
                        "& .MuiSelect-select": {
                          padding: "10px 14px", // Adjust padding for the select item
                          fontFamily: "Source Serif Pro", // Change selected option font
                          fontSize: "16px", // Adjust font size as needed
                        },
                        "& .MuiOutlinedInput-notchedOutline": {
                          border: "none", // Ensure no border is shown
                        },
                      }}
                      IconComponent={NoIcon} // Remove the dropdown arrow
                      label="Age"
                      onChange={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                        handleChange(e);
                      }}
                    >
                      {documentList && documentList.length > 0
                        ? documentList.map((document: any) => (
                            <MenuItem
                              sx={{
                                font: "normal normal 14px/15px Source Serif Pro",
                              }}
                              key={document?._id}
                              value={document?._id}
                            >
                              {document?.name || document?.title}
                            </MenuItem>
                          ))
                        : null}
                    </Select>
                  </FormControl>
                </Box>
              </>
            )}

            {/* --------------Input are here----------------- */}
            <InputBase
              value={newData.inputValue}
              onChange={handleInputChange}
              placeholder="name item"
              className={S["add-to-workspace__input"]}
              onClick={(e) => {
                e.preventDefault(); // Prevents the default action
                e.stopPropagation(); // Stops the event from bubbling up
                // Additional logic for click event
              }}
              onFocus={(e) => {
                e.preventDefault(); // Prevents the default action
                e.stopPropagation(); // Stops the event from bubbling up
                // Additional logic for focus event
              }}
            />

            {/* --------------  Checkbox and buttons here  ----------------- */}
            <Stack
              sx={{
                flexDirection: "row",
                justifyContent: "space-between",
                alignItems: "center",
                cursor: "pointer",
              }}
            >
              {(newData.type === "Board" ||
                newData.type === "List" ||
                newData.type === "Checklist") && (
                <>
                  <Stack
                    direction={"row"}
                    gap={"5px"}
                    alignItems={"center"}
                    onClick={(e) => {
                      e.preventDefault(); // Prevents the default action, if any
                      e.stopPropagation(); // Stops the event from bubbling up
                      setNewData((prev) => ({
                        ...prev,
                        checkboxSelected: !prev.checkboxSelected,
                      }));
                    }}
                  >
                    <CheckboxMedium checked={newData.checkboxSelected} />
                    <Typography
                      sx={{
                        font: "normal normal 300 13px/17px Source Serif Pro",
                      }}
                    >
                      Free Form
                    </Typography>
                  </Stack>
                  <Tooltip
                    placement="top-end"
                    title={
                      <Typography
                        sx={{
                          font: "normal normal 300 11px/15px Source Serif Pro",
                          maxWidth: "125px",
                        }}
                      >
                        Free form gives you total control of your items, but can
                        only be viewed in the view selected now.
                      </Typography>
                    }
                    PopperProps={{
                      sx: {
                        "& .MuiTooltip-tooltip": {
                          bgcolor: "#616161",
                          color: "white",
                          fontSize: "12px",
                        },
                        "& .MuiTooltip-arrow": {
                          color: "#616161",
                        },
                      },
                    }}
                  >
                    <span>
                      <InfoIconBlack w={15} />
                    </span>
                  </Tooltip>
                </>
              )}
            </Stack>
          </Stack>
          <Stack direction={"row"} justifyContent={"end"}>
            <ButtonSmall
              label="Create"
              types={ButtonType.Button}
              onClick={(e: any) => handleCreateButtonClick(e)}
            />
          </Stack>
        </Stack>
      </Popover>
    </>
  );
};

export default AddToWorkspace;
