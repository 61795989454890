import * as React from "react";
import {
  FormControl,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Select,
  SelectChangeEvent,
} from "@mui/material";
import S from "./SmallUnfilledDropdown.module.css";
import { GreenDropdownIcon } from "../../../images";

interface SmallUnfilledDropdownProps {
  options: string[];
  componentHeader: string;
  multiple?: boolean;
  width?: string; // Add width prop
  onChange?: (selectedOption: string) => void;
}

const SmallUnfilledDropdown: React.FC<SmallUnfilledDropdownProps> = React.memo(
  ({
    options,
    componentHeader,
    multiple = false,
    width = "90px", // Default to full width
    onChange,
    ...props
  }) => {
    const [personName, setPersonName] = React.useState<string[]>([]);
    const [open, setOpen] = React.useState(false); // Manage open state

    const handleChange = React.useCallback(
      (event: SelectChangeEvent<typeof personName>) => {
        const {
          target: { value },
        } = event;
        setPersonName(typeof value === "string" ? value.split(",") : value);

        if (onChange) {
          const selectedValue =
            typeof value === "string" ? value : value.join(", ");
          onChange(selectedValue);
        }
      },
      [onChange]
    );

    const MenuProps = {
      PaperProps: {
        className: S["small-unfilled-dropdown__menu"],
        style: { width: width },
      },
    };

    return (
      <div>
        <FormControl
          size="small"
          style={{ width }} // Apply the width prop dynamically
          {...props}
        >
          <Select
            open={open}
            onClick={!open ? () => setOpen(true) : () => null}
            multiple={multiple}
            displayEmpty
            IconComponent={() => (
              <GreenDropdownIcon open={!open} sx={{ marginRight: "5px", height:"11px" }} />
            )} // Pass open state as prop
            value={personName}
            onChange={handleChange} // Use handleChange function
            input={
              <OutlinedInput
                // sx={{ border: "0.5px solid #e9edf2" }}
                className={S["small-unfilled-dropdown__inputbox"]}
                classes={{
                  notchedOutline: S["small-unfilled-dropdown__notchedOutline"],
                  focused: S["small-unfilled-dropdown__focused"],
                }}
              />
            }
            onOpen={() => setOpen(true)} // Handle open state
            onClose={() => setOpen(false)} // Handle close state
            renderValue={(selected) => {
              if (selected.length === 0) {
                return (
                  <em
                    className={S["small-unfilled-dropdown__selectPlaceholder"]}
                  >
                    {componentHeader}
                  </em>
                );
              }

              return (
                <span className={S["small-unfilled-dropdown__selected"]}>
                  {selected?.join(", ")}
                </span>
              );
            }}
            MenuProps={MenuProps}
          >
            {options.map((name) => (
              <MenuItem
                className={S["small-unfilled-dropdown__menu-item"]}
                key={name}
                value={name}
              >
                <ListItemText
                  primary={name}
                  primaryTypographyProps={{
                    className: S["small-unfilled-dropdown__list-item-text"],
                  }}
                />
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </div>
    );
  }
);

export default SmallUnfilledDropdown;
