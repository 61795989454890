import { Stack, Typography } from "@mui/material";
import React from "react";

interface ChatPreviewViewProps {
  messageType: "send" | "recived";
  messageSender: string;
  messageText: string;
  timeStamp: string;
}

const ChatPreviewView: React.FC<ChatPreviewViewProps> = ({
  messageType,
  messageSender,
  messageText,
  timeStamp,
}) => {
  return (
    <Stack
      sx={{
        gap: "4px",
        alignItems: messageType === "recived" ? "end" : "start",
      }}
    >
      <Typography sx={{ font: "normal normal 900 11px/14px Source Serif Pro" }}>
        {messageType === "send" ? "You" : messageSender}, {timeStamp}
      </Typography>
      <Stack
        sx={{
          width: "250px",
          border: `1px solid  ${messageType === "recived" ? "#0C501C" : "#775EE2"}`,
          padding: "5px 14px 5px 5px",
          boxSizing: "border-box",
          borderRadius: "5px",
        }}
      >
        <Typography
          sx={{ font: "normal normal 300 13px/16px Source Serif Pro" }}
        >
          {messageText}
        </Typography>
      </Stack>
    </Stack>
  );
};

export default ChatPreviewView;
