import React from "react";
import { SmallPinnedCard } from "../../../../common";
import {
  GmailLogoPng,
  MicrosoftTeamsLogoPng,
  OutlookLogoPng,
} from "../../../../../images";

interface AccountsPinnedCardProps {
  userNameOrEmail: string;
  userIconSrc?: string;
  topIcon: "gmail" | "outlookMail" | "teams";
}

const AccountsPinnedCard: React.FC<AccountsPinnedCardProps> = ({
  topIcon,
  userNameOrEmail,
  userIconSrc,
}) => {
  const getCompanyIcon = () => {
    switch (topIcon) {
      case "gmail":
        return GmailLogoPng;

      case "outlookMail":
        return OutlookLogoPng;

      case "teams":
        return MicrosoftTeamsLogoPng;

      default:
        return GmailLogoPng;
    }
  };

  return (
    <SmallPinnedCard
      size={40}
      userName={userNameOrEmail}
      userIconSrc={userIconSrc}
      companyLogoSrc={getCompanyIcon()}
      sx={{ bgcolor: "transparent", color: "black" }}
    />
  );
};

export default AccountsPinnedCard;
