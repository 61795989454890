import { Stack, Typography } from "@mui/material";
import React, { useState } from "react";
import { CheckboxMedium } from "../../../../../../common";

interface ListProps {
  name: string;
  isSelected: boolean;
}

interface BasicFilterViewProps {
  onChange: (list: ListProps[]) => void;
}

const initialFilterList: ListProps[] = [
  {
    name: "Has attachment",
    isSelected: false,
  },
  {
    name: "Has link",
    isSelected: false,
  },
  {
    name: "Status: Unread",
    isSelected: false,
  },
  {
    name: "Status: Read",
    isSelected: false,
  },
];

const BasicFilterView: React.FC<BasicFilterViewProps> = ({ onChange }) => {
  const [filterList, setFilterList] = useState(initialFilterList);

  const handleClick = (index: number) => {
    const updatedList = filterList.map((item, i) =>
      i === index ? { ...item, isSelected: !item.isSelected } : item
    );
    setFilterList(updatedList);
    onChange(updatedList); // Call the onChange prop with the updated list
  };

  return (
    <Stack gap={"15px"} mt={"10px"}>
      <Typography
        sx={{ font: "normal normal 300 14px/18px Source Serif Pro " }}
      >
        Basic filters
      </Typography>
      {filterList.map((item, index) => (
        <Stack
          key={index}
          sx={{
            flexDirection: "row",
            alignItems: "center",
            gap: "5px",
            cursor: "pointer",
          }}
          onClick={() => handleClick(index)}
        >
          <CheckboxMedium boxSize={17} checked={item.isSelected} />
          <Typography
            sx={{ font: "normal normal 300 13px/16px Source Serif Pro " }}
          >
            {item.name}
          </Typography>
        </Stack>
      ))}
    </Stack>
  );
};

export default BasicFilterView;
