import { Stack, Typography } from "@mui/material";
import React, { useState } from "react";

import styles from "./ForwardMessage.module.css";
import { DropdownWithImageBadge, DropdownWithImageBadgeForChannels } from "../DropdownWithImageBadge";
import { ButtonSmall } from "../Buttons";
import { DUMMY_USER_EMAIL_LIST } from "../DropdownWithImageBadge/DropdownWithImageBadgeTypes";
import { ButtonType, SmallButtonColor } from "../Buttons/AllButtonProps";

interface ForwardMessageProps {
  setOpenForwardMessagePopup?:any
  onClickSendButton?: any;
  onClickCancelButton?: any;
  usersList?:any
  setSelectUsersForForwardMessage?:any;
}

const ForwardMessage: React.FC<ForwardMessageProps> = ({setOpenForwardMessagePopup,onClickCancelButton,onClickSendButton,usersList,setSelectUsersForForwardMessage}) => {
  return (
    <Stack
      className={styles["forward-message"]}
    >
      <Typography className={styles["forward-message__title"]}>
        Forward message
      </Typography>
      <Stack className={styles["forward-message__content"]}>
        <Stack className={styles["forward-message__recipient-row"]}>
          <Typography className={styles["forward-message__recipient-label"]}>
            To
          </Typography>
          <DropdownWithImageBadgeForChannels
            options={usersList}
            onChangeSelection={(userList:any) => setSelectUsersForForwardMessage(userList)}
          />
        </Stack>
      </Stack>
      <Stack className={styles["forward-message__actions"]}>
        <ButtonSmall
        onClick={onClickCancelButton}
          label="Cancel"
          types={ButtonType.Button}
          colorVarient={SmallButtonColor.Transparent}
        />
        <ButtonSmall
          label="Send"
          onClick={onClickSendButton}
          types={ButtonType.Button}
          colorVarient={SmallButtonColor.MessagePurple}
        />
      </Stack>
    </Stack>
  );
};

export default ForwardMessage;
