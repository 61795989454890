import * as React from "react";
import {
  Box,
  Popover,
  ClickAwayListener,
  InputBase,
  InputAdornment,
} from "@mui/material";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DateCalendar } from "@mui/x-date-pickers/DateCalendar";
import { Dayjs } from "dayjs";
import { CalendarIcon } from "../../../images";

interface CustomDateRangePickerProps {
  onDateChange: (startDate: Dayjs | null, endDate: Dayjs | null) => void;
}

const CustomDateRangePicker: React.FC<CustomDateRangePickerProps> = ({
  onDateChange,
}) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [startDate, setStartDate] = React.useState<Dayjs | null>(null);
  const [endDate, setEndDate] = React.useState<Dayjs | null>(null);
  const [isEndDateSet, setIsEndDateSet] = React.useState<boolean>(false);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(anchorEl ? null : event.currentTarget);
  };

  const handleClickAway = () => {
    setAnchorEl(null);
    if (!isEndDateSet) {
      setStartDate(null);
      setEndDate(null);
    }
  };

  const open = Boolean(anchorEl);
  const id = open ? "date-range-Popover" : undefined;

  const formatStartDate = startDate ? startDate.format("MMM D") : "";
  const formatEndDate = endDate ? endDate.format("MMM D, YYYY") : "";

  const handleStartDateChange = (newValue: Dayjs | null) => {
    if (startDate && newValue && startDate.isSame(newValue, "day")) {
      setStartDate(null);
      setEndDate(null);
      setIsEndDateSet(false);
    } else {
      setStartDate(newValue);
      if (!isEndDateSet) {
        setEndDate(newValue);
      }
    }
  };

  const handleEndDateChange = (newValue: Dayjs | null) => {
    if (endDate && newValue && endDate.isSame(newValue, "day")) {
      setEndDate(null);
      setIsEndDateSet(false);
    } else {
      setEndDate(newValue);
      setIsEndDateSet(true);
      if (newValue) {
        setAnchorEl(null);
        onDateChange(startDate, newValue); // Send selected dates to parent
      }
    }
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <ClickAwayListener onClickAway={handleClickAway}>
        <Box>
          <InputBase
            sx={{
              borderRadius: "5px",
              height: "25px",
              width: "125px",
              border: "0.5px solid #E9EDF2",
              boxSizing: "border-box",
              font: "normal normal normal 10px/13px Source Serif Pro",
              paddingLeft: "0px", // Adjust padding to remove extra space
            }}
            startAdornment={
              <InputAdornment
                position="start"
                sx={{ marginRight: "5px", marginLeft: "5px", padding: 0 }}
              >
                <CalendarIcon w={15} />
              </InputAdornment>
            }
            onClick={handleClick}
            value={
              startDate && endDate
                ? `${formatStartDate} - ${formatEndDate}`
                : ""
            }
            placeholder="Select timeline"
            readOnly
          />

          <Popover
            container={
              document && document?.getElementById("objectivedatepicker")
            }
            id={id}
            open={open}
            anchorEl={anchorEl} anchorOrigin={{
              vertical: 'top',
              horizontal: 'left',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
          >
            <Box
              sx={{
                bgcolor: "background.paper",
                display: "flex",
                boxShadow: 1,
              }}
            >
              <DateCalendar
                value={startDate}
                onChange={handleStartDateChange}
                shouldDisableDate={(date) =>
                  endDate ? date.isAfter(endDate, "day") : false
                }
              />
              <Box
                sx={{
                  width: "1px",
                  backgroundColor: "#E9EDF2",
                }}
              />
              <DateCalendar
                value={endDate}
                onChange={handleEndDateChange}
                shouldDisableDate={(date) =>
                  startDate ? date.isBefore(startDate, "day") : false
                }
              />
            </Box>
          </Popover>
        </Box>
      </ClickAwayListener>
    </LocalizationProvider>
  );
};

export default CustomDateRangePicker;
