import React from "react";
import { Box, Stack, Modal } from "@mui/material";
import { HeaderOverViewName, ReactangleHeaderOverView } from "../components";
import { MultiIconButton } from "../../common";
import {
  BarButtonOptions,
  CreateGroupPopUp,
  CrmRightSideTabs,
  TimelineTabs,
} from "../../components";
import { AddtoGroupIcon } from "../../../../../images";

type Props = {};
const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "fit-content",
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 1,
  height: "fit-content",
  borderRadius: "6px",
};

const DealID = (props: Props) => {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  return (
    <Box>
      <Stack
        direction={"row"}
        alignItems={"center"}
        justifyContent={"space-between"}
        gap={0.5}
        sx={{ padding: "4px 10px" }}
      >
        <HeaderOverViewName
          imageUrl="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcS7_rMKggz5BJuWvcEKPpmUevDEXHaGCovzJQ&s"
          headerType="deal"
          name="Skype Deal"
          roundedOrSquare="rd"
        />
        <Stack
          direction={"row"}
          onClick={handleOpen}
          alignItems={"center"}
          gap={1}
        >
          <BarButtonOptions
            label={{ id: 1, name: "Add to Group" }}
            icon={<AddtoGroupIcon />}
          />
          <MultiIconButton />
        </Stack>
      </Stack>

      <Stack>
        <ReactangleHeaderOverView />
      </Stack>

      <Stack direction={"row"} height={"80vh"}>
        <TimelineTabs />
        <CrmRightSideTabs />
      </Stack>

      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <CreateGroupPopUp variant="group" />
        </Box>
      </Modal>
    </Box>
  );
};

export default DealID;
