import {
  Box,
  IconButton,
  Modal,
  Stack,
  TextareaAutosize,
  Tooltip,
  Typography,
} from "@mui/material";
import React from "react";
import { XCircleIcon } from "../../../images";
import { EmailRichTextMenu } from "../EmailRichTextMenu";
import { SendMessageButton } from "../Buttons";
import { SendMessageButtonColor } from "../Buttons/AllButtonProps";
import styles from "./EmailSmallerPopout.module.css";
import { DropdownWithImageBadge } from "../DropdownWithImageBadge";
import { DUMMY_USER_EMAIL_LIST } from "../DropdownWithImageBadge/DropdownWithImageBadgeTypes";
import { AttachmentWithIconSmall } from "../AttachmentWithIconSmall";
import { AttechmentViewContainer } from "../AttechmentViewContainer";
import { SnoozeEmail } from "../../pages/Email/components";

interface EmailSmallerPopoutProps {
  onCloseClick?: () => void;
}

const EmailSmallerPopout: React.FC<EmailSmallerPopoutProps> = ({
  onCloseClick,
}) => {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  return (
    <Stack className={styles["EmailSmallerPopout"]}>
      {/* ------------ Header Section ------------ */}
      <Stack className={styles["EmailSmallerPopout__header"]}>
        <Typography className={styles["EmailSmallerPopout__title"]}>
          New Message
        </Typography>
        <IconButton
          onClick={() => onCloseClick && onCloseClick()}
          className={styles["EmailSmallerPopout__close-btn"]}
          disableRipple
        >
          <XCircleIcon w={15} />
        </IconButton>
      </Stack>

      {/* ------------ Email Body Section ------------ */}
      <Stack
        className={styles["EmailSmallerPopout__content"]}
        sx={{ gap: "20px" }}
      >
        {/* --------- Sender Details Here --------- */}
        <Stack
          sx={{
            flexDirection: "row",
            alignItems: "start",
            width: "100%",
            gap: "15px",
          }}
        >
          <Typography
            sx={{ font: "normal normal 900 14px/18px Source Serif Pro" }}
          >
            From
          </Typography>
          <Stack sx={{ width: "calc(100% - 50px)" }}>
            <Stack
              sx={{
                width: "100%",
                flexDirection: "row",
                flexWrap: "wrap",
                gap: "5px 10px",
                font: "normal normal 300 14px/18px Source Serif Pro",
              }}
            >
              <Typography
                sx={{
                  font: "inherit",
                  maxWidth: "100%",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  whiteSpace: "nowrap",
                }}
              >
                {"Jackie Po"}
              </Typography>
              <Typography
                sx={{
                  font: "inherit",
                  maxWidth: "100%",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  whiteSpace: "nowrap",
                }}
              >
                {"<jackiepo@bearishfh.com>"}
              </Typography>
            </Stack>
          </Stack>
        </Stack>
        {/* --------- Sender Details End --------- */}

        {/* --------- Receiver Details Here --------- */}
        <Stack
          sx={{
            flexDirection: "row",
            gap: "15px",
            justifyContent: "space-between",
            alignItems: "start",
            borderBottom: "1px solid #E9EDF2",
          }}
        >
          <Typography
            sx={{ font: "normal normal 900 14px/18px Source Serif Pro" }}
          >
            To
          </Typography>
          <Stack sx={{ flexGrow: 1 }}>
            <DropdownWithImageBadge
              options={DUMMY_USER_EMAIL_LIST}
              onChangeSelection={(value) => console.log(value)}
            />
          </Stack>
          <Stack
            sx={{
              flexDirection: "row",
              gap: "5px",
            }}
          >
            {/* Add CC Button */}
            <Tooltip title="Add CC" placement="top" arrow>
              <IconButton
                sx={{ p: 0, borderRadius: 0, color: "black" }}
                disableRipple
              >
                <Typography
                  sx={{ font: "normal normal 300 11px/14px Source Serif Pro" }}
                >
                  CC
                </Typography>
              </IconButton>
            </Tooltip>
            {/* Add BCC Button */}
            <Tooltip title="Add BCC" placement="top" arrow>
              <IconButton
                sx={{ p: 0, borderRadius: 0, color: "black" }}
                disableRipple
              >
                <Typography
                  sx={{ font: "normal normal 300 11px/14px Source Serif Pro" }}
                >
                  BCC
                </Typography>
              </IconButton>
            </Tooltip>
          </Stack>
        </Stack>
        {/* --------- Receiver Details End --------- */}

        {/* --------- Subject Input Here --------- */}
        <Stack
          sx={{
            flexDirection: "row",
            gap: "10px",
            borderBottom: "0.5px solid #E9EDF2",
            paddingBottom: "3px",
          }}
        >
          <Typography
            sx={{ font: "normal normal 900 14px/18px Source Serif Pro" }}
          >
            Subject
          </Typography>
          <TextareaAutosize
            placeholder="Subject of email"
            maxRows={2}
            style={{
              flexGrow: 1,
              resize: "none",
              border: "0px",
              outline: "none",
              padding: 0,
              scrollbarWidth: "none",
              font: "normal normal 300 14px/18px Source Serif Pro",
            }}
          />
        </Stack>
        {/* --------- Subject Input End --------- */}

        {/* --------- Email Content Area Here --------- */}
        <Stack sx={{ flex: 1 }}>
          <TextareaAutosize
            placeholder="Type your text messages here"
            minRows={8}
            style={{
              color: "black",
              scrollbarWidth: "none", // Hide scrollbar in Firefox
              flexGrow: 1, // Allow textarea to grow
              resize: "none", // Disable resizing
              border: "0px", // Remove border
              padding: "0px 10px 0px 10px", // Remove padding
              outline: "none", // Remove outline
              font: "normal normal 300 13px/16px Source Serif Pro", // Font style
              overflow: "hidden", // Hide any overflow in other directions
            }}
          />
        </Stack>
        {/* --------- Email Content Area End --------- */}

        {/* --------- Attachment Container Here --------- */}
        <Stack sx={{ gap: "10px" }}>
          <Typography
            sx={{ font: "normal normal 300 14px/18px Source Serif Pro" }}
          >
            Attachments
          </Typography>
          <AttechmentViewContainer>
            {Array.from({ length: 1 }, (_, index) => (
              <AttachmentWithIconSmall
                showDeleteButton={false}
                key={index}
                fileName={`file${index + 1}.mp4`}
              />
            ))}
            {Array.from({ length: 3 }, (_, index) => (
              <AttachmentWithIconSmall
                key={index}
                fileName={`file${index + 1}.mp4`}
                showDeleteButton
                onDeleteButtonClick={() => null}
              />
            ))}
          </AttechmentViewContainer>
        </Stack>
        {/* --------- Attachment Container End --------- */}
      </Stack>
      {/* ------------ Email Body End ------------ */}

      {/* ------------ Footer Section ------------ */}
      <Stack className={styles["EmailSmallerPopout__footer"]}>
        <EmailRichTextMenu />
        <SendMessageButton
          buttonSize={120}
          onClick={() => handleOpen()}
          text={"Schedule"}
          colorVarient={SendMessageButtonColor.Blue}
        />
        <SendMessageButton colorVarient={SendMessageButtonColor.Blue} />
      </Stack>

      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <SnoozeEmail variant="ScheduleEmail" />
        </Box>
      </Modal>
      {/* ------------ Footer Section End ------------ */}
    </Stack>
  );
};

export default EmailSmallerPopout;
const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "fit-content",
  bgcolor: "background.paper",
  boxShadow: 24,
};
