import { Drawer, Stack } from "@mui/material";
import React, { useCallback, useContext, useEffect } from "react";
import { styled } from "@mui/system";
import CompanyIconAndName from "../../CompanyIconAndName";
import { MainActionButton } from "../../../Buttons";
import styles from "../../DrawerNavbar.module.css";
import { ButtonType, MainActionColor } from "../../../Buttons/AllButtonProps";
import { cs } from "../../../../../images";
import { useNavigate } from "react-router-dom";
import { MeetingContext } from "../../../../Povider";
import { CreateCallButton } from "../../../Buttons/CreateCallButton";
import { UpcomingCallCardContainer } from "../../../../pages/Calls/components/UpcomingCallCardContainer";
import { UpcomingCallCard } from "../../../../pages/Calls/components/UpcomingCallCard";
import { useDispatch, useSelector } from "react-redux";
import { AuthState, getUserMeetings } from "../../../../../redux";
import { RootState } from "../../../../../redux/store";

type CallsDrawerProps = {
  drawerOpen: boolean;
  drawerCloser: () => void;
};

const DrawerContainer = styled("div")({
  position: "fixed",
  top: 0,
  height: "100vh",
  zIndex: 1100, // Ensure the drawer is behind the navbar
});
const CallsDrawer: React.FC<CallsDrawerProps> = ({
  drawerOpen,
  drawerCloser,
}) => {
  const meetingContext = useContext(MeetingContext);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { user } = useSelector((state: RootState) => state.auth as AuthState);
  useEffect(() => {
    dispatch(getUserMeetings(user?.token))
  },[])
  const onCallButtonClickHandler = useCallback(async () => {
    if (meetingContext?.createMeeting) {
      const meeting = await meetingContext.createMeeting();
      if (meeting?.externalMeetingId)
        navigate(`/Calls/${meeting?.externalMeetingId}`);
    }
  }, []);

  const handleButtonClick = (value: string) => {
    if(value.trim() === "Start"){
        onCallButtonClickHandler()
    }
  };

  return (
    <>
      <DrawerContainer>
        <Drawer
          sx={{
            position: "absolute",
            top: 0,
            height: "100vh",
            width: "215px",
            // left: '-240px', // Start hidden behind the Navbar
            transition: drawerOpen
              ? "left 225ms ease-out"
              : "left 195ms ease-in",
            left: drawerOpen ? "30px" : "-215px",
            zIndex: 1100, // Ensure the drawer is behind the navbar
            "& .MuiPaper-root": {
              position: "absolute",
              top: 0,
              height: "100vh",
              width: "215px",
              left: 0,
              boxSizing: "border-box",
              zIndex: 1100, // Ensure the paper is also behind the navbar
            },
          }}
          variant="persistent"
          anchor="left"
          open={drawerOpen}
        >
          <Stack
            bgcolor={"white"}
            direction="column"
            flex={1}
            className={`${styles.drawerNavbar__drawer}`}
          >
            {/* Company logo and name here */}
            <CompanyIconAndName arrowButtonClickHandler={drawerCloser} />
            <Stack className={styles.drawerNavbar__content}>
              {/* Buttons and other lists here */}
              <CreateCallButton onClick={handleButtonClick} />

              <UpcomingCallCardContainer labelText={"Upcoming calls"}  />
            </Stack>
          </Stack>
        </Drawer>
      </DrawerContainer>
    </>
  );
};

export default CallsDrawer;
