import { Box, Stack, Typography } from "@mui/material";
import React from "react";
import { adjustHexColor } from "../../../Utils";
import { DummyImage } from "../../../images";

interface ConnectedItensListCardProps {}

const ConnectedItensListCard: React.FC<ConnectedItensListCardProps> = ({}) => {
  return (
    <Stack
      sx={{
        height: "100px",
        flexGrow: 1,
        border: `1px solid ${adjustHexColor("#008D4C", 25)} `,
        borderRadius: "5px",
        bgcolor: adjustHexColor("#E9EDF2", 25),
        padding: "10px",
        boxSizing: "border-box",
        flexDirection: "row",
        alignItems: "start",
        gap: "10px",
      }}
    >
      <Box
        component={"img"}
        alt="img"
        src={DummyImage}
        sx={{ height: "50px", width: "50px" }}
      />
      <Stack sx={{ gap: "10px", width: "calc(100% - 60px)" }}>
        <Typography
          sx={{
            font: "normal normal 400 14px/18px Source Serif Pro",
            maxWidth: "100%",
            overflow: "hidden",
            textOverflow: "ellipsis",
            whiteSpace: "nowrap",
          }}
        >
          Lorem ipsum dolor sit amet consectetur adipisicing elit. Doloribus,
          beatae.
        </Typography>
        <Typography
          sx={{
            font: "normal normal 300 14px/18px Source Serif Pro",
            maxWidth: "100%",
            overflow: "hidden",
            textOverflow: "ellipsis",
            whiteSpace: "nowrap",
          }}
        >
          Added by Jenny
        </Typography>
        <Typography
          sx={{
            font: "normal normal 300 14px/18px Source Serif Pro",
            maxWidth: "100%",
            overflow: "hidden",
            textOverflow: "ellipsis",
            whiteSpace: "nowrap",
          }}
        >
          Added on {"00-00-2020"} at {"00:00 PM"}
        </Typography>
      </Stack>
    </Stack>
  );
};

export default ConnectedItensListCard;
