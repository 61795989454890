import { IconButton, Stack, Tooltip } from "@mui/material";
import React, { useEffect, useState } from "react";
import {
  ArchiveIcon,
  ClockIcon,
  DeleteIcon,
  EyeIcon,
} from "../../../../../../images";
import { DeleteEmail__api } from "../../../../../../redux/Slice/email/emailActions";
import axios from "axios";
import { baseURL } from "../../../../../../redux/middleware/apiMiddleware";

interface Email {
  id: string;
}

interface IconsGroupViewProps {
  email: Email;
}

const IconsGroupView: React.FC<IconsGroupViewProps> = ({ email }) => {
  const [accessToken, setAccessToken] = useState<string>("");

  const messageId = email?.id;

  useEffect(() => {
    const token = localStorage.getItem("accessToken") || "";
    setAccessToken(token);
  }, []);

  const handleDelete = async (accessToken: string, messageId: string | null) => {
    if (!accessToken || !messageId) {  // Fix: check if either accessToken or messageId is missing
      console.error("Access token or message ID not found");
      return;
    }

    try {
      // const res = await DeleteEmail__api(accessToken, messageId);
      const res  = await axios.delete(`${baseURL}api/v1/email/move-email`, {
          data: {
            accessToken,
            messageId
          } 
      })
      return res;
    } catch (err) {

      
    }
  };

  return (
    <Stack
      sx={{
        flexDirection: "row",
        alignItems: "center",
        gap: "12px",
      }}
    >
      <Tooltip title="Snooze" placement="top" arrow>
        <IconButton sx={{ p: 0 }} disableRipple>
          <ClockIcon w={15} />
        </IconButton>
      </Tooltip>

      <Tooltip title="Archive" placement="top" arrow>
        <IconButton sx={{ p: 0 }} disableRipple>
          <ArchiveIcon w={15} />
        </IconButton>
      </Tooltip>

      <Tooltip title="Mark as Read" placement="top" arrow>
        <IconButton sx={{ p: 0 }} disableRipple>
          <EyeIcon w={15} />
        </IconButton>
      </Tooltip>

      <Tooltip title="Delete" placement="top" arrow>
        <IconButton
          sx={{ p: 0 }}
          onClick={() => {
            handleDelete(accessToken, messageId);
            console.log("Delete clicked");
          }}
          disableRipple
        >
          <DeleteIcon w={15} />
        </IconButton>
      </Tooltip>
    </Stack>
  );
};

export default IconsGroupView;
