import { RadioGroup, Stack, Typography } from "@mui/material";
import React from "react";
import {
  ChipInputField,
  CustomRadioFormControlLabel,
} from "../../../../../../common";

interface SubjectKeywordViewProps {}

const SubjectKeywordView: React.FC<SubjectKeywordViewProps> = ({}) => {
  const [subjectKeyword, setSubjectKeyword] = React.useState("Exact match");

  const handleSubjectRadioChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setSubjectKeyword((event.target as HTMLInputElement).value);
  };
  return (
    <Stack sx={{ gap: "15px", marginTop: "10px" }}>
      <Stack
        sx={{
          flexDirection: "row",
          gap: "10px",
          alignItems: "start",
          borderBottom: "0.5px solid #E9EDF2",
        }}
      >
        <Typography
          sx={{
            font: "normal normal 300 14px/18px Source Serif Pro",
            whiteSpace: "nowrap",
            flexShrink: 0,
            paddingTop: "10px",
          }}
        >
          Subject keywords
        </Typography>
        <ChipInputField onChange={(list) => console.log(list)} />
      </Stack>

      <RadioGroup
        aria-labelledby="subjectKeyword-radio-buttons-group"
        name="subjectKeyword-radio-buttons-group"
        value={subjectKeyword}
        onChange={handleSubjectRadioChange}
        sx={{ p: 0, marginTop: "5px", gap: "13px" }}
      >
        <CustomRadioFormControlLabel value="Exact match" label="Exact match" />
        <CustomRadioFormControlLabel value="Contains" label="Contains" />
        <CustomRadioFormControlLabel
          value="Does not contain"
          label="Does not contain"
        />
      </RadioGroup>
    </Stack>
  );
};

export default SubjectKeywordView;
