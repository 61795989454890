import { PayloadAction } from "@reduxjs/toolkit";
import { EmailState } from "../emailTypes";



export const getAllEmails__Request = (state: EmailState) => {
  state.emails = {
    data: [], // Reset the email data
    api_call_timeStamp: new Date().toISOString(), // Record the API call timestamp
    is_api_called: true, // Set the flag to true
    error: null, // Reset the error field
  };
};

export const deleteEmail__Request = (state: EmailState) => {
  // state.emails = null;
  console.log("deleteEmail__Request")
};

