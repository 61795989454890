import { useDispatch, useSelector } from "react-redux";
import { UpdateCardLong } from "../components/UpdateCardLong";
import { AuthState } from "../../../../redux";
import { RootState } from "../../../../redux/store";
import { MessagesState } from "../../../../redux/Slice/message/messageTypes";
import { useEffect } from "react";
import { getMessagesActivities } from "../../../../redux/Slice/message/messageActions";
import { Typography } from "@mui/material";

const DoneActivityTab = () => {
  const dispatch = useDispatch();
  const { user } = useSelector((state: RootState) => state.auth as AuthState);
  const { messageActivity } = useSelector(
    (state: RootState) => state.message as MessagesState
  );

  useEffect(() => {
    if (user) {
      dispatch(getMessagesActivities(user?.data?._id, "DONE"));
    }
  }, [user]);

  return (
    <div>
      {messageActivity.length ? (
        messageActivity?.map((notification: any) => (
          <UpdateCardLong
            tag={
              notification?.messageType === "MENTION"
                ? notification.messageType
                : "CHAT"
            }
            userOrProjectName={
              notification?.channelType === "CHANNEL"
                ? notification.channelName
                : notification.otherParticipantName
            }
            description={notification?.messageContent || ""}
            channelId={notification.channelId}
            activeAction={false}
          />
        ))
      ) : (
        <Typography style={{ textAlign: "center" }}>no data found</Typography>
      )}
    </div>
  );
};

export default DoneActivityTab;
