import React, { useEffect, useRef, useState } from "react";
import "froala-editor/js/plugins.pkgd.min.js";
import "froala-editor/css/froala_editor.pkgd.min.css";
import "./style.css";
import FroalaEditorComponent from "react-froala-wysiwyg";
import "froala-editor/css/froala_style.min.css";
import "froala-editor/css/froala_editor.pkgd.min.css";
import { Box, IconButton, Typography } from "@mui/material";
import Tribute from "tributejs";
import { StyleIcon } from "../../../images";

interface User {
  _id: string;
  firstName: string;
  lastName: string;
  email: string;
}

interface CustomComponentProps {
  handleSearchChanges: (event: React.ChangeEvent<HTMLInputElement>) => void;
  value?: string;
  currentChannel?: any;
}

const CustomFroalaEditor: React.FC<CustomComponentProps> = ({
  handleSearchChanges,
  value = "",
  currentChannel,
}: CustomComponentProps) => {
  const [editorData, setEditorData] = useState<string>(value);
  const editorRef = useRef<FroalaEditorComponent>(null);

  useEffect(() => {
    setEditorData(value);
  }, [value]);

  useEffect(() => {
    setEditorData("");
  }, [currentChannel]);

  const getTributeValues = (users: User[]) => {
    return users?.map((user) => ({
      key: `${user.firstName} ${user.lastName}`,
      value: `${user.firstName} ${user.lastName}`,
    }));
  };

  const toggleToolbar = () => {
    const toolbar = document.getElementsByClassName("fr-toolbar")?.[0];
    if (toolbar) {
      if (toolbar.classList.contains("fr-toolbar-visible")) {
        toolbar.classList.remove("fr-toolbar-visible");
        toolbar.classList.add("fr-toolbar-hide");
      } else {
        toolbar.classList.remove("fr-toolbar-hide");
        toolbar.classList.add("fr-toolbar-visible");
      }
    } else {
      //@ts-ignore
      editorRef.current?.element?.focus?.();
    }
  };

  const onFocusTextarea = () => {
    const toolbar = document.getElementsByClassName("fr-toolbar")?.[0];
    if (toolbar) {
      toolbar.classList.remove("fr-toolbar-visible");
      toolbar.classList.add("fr-toolbar-hide");
    }
  };

  const onBlurTextarea = () => {
    console.log("BLUR");
    const toolbar = document.getElementsByClassName("fr-toolbar")?.[0];

    if (toolbar) {
      toolbar.classList.add("fr-toolbar-hide");
      toolbar.classList.remove("fr-toolbar-visible");
    }
  };

  useEffect(() => {
    if (currentChannel?.participants) {
      const tribute = new Tribute({
        collection: [
          {
            trigger: "@",
            values: getTributeValues(currentChannel?.participants),

          },
          {
            trigger: "/flag",
            values: [
              { key: "Low", value: "/Low" },
              { key: "Medium", value: "/Medium" },
              { key: "High", value: "/High" },
            ],
            selectTemplate: (item) => `${item.original.value}`,
          },
        ],
      });

      if (editorRef.current && editorRef.current.getEditor()) {
        tribute.attach(editorRef.current.getEditor().$el[0]);
      }

      return () => {
        if (editorRef.current && editorRef.current.getEditor()) {
          tribute.detach(editorRef.current.getEditor().$el[0]);
        }
      };
    }
  }, [editorRef, currentChannel?.participants]);

  return (
    <>
      <Box
        display="flex"
        flexDirection="column"
        alignItems="center"
        marginRight="8px"
      >
        <IconButton onClick={toggleToolbar} size="small">
          <StyleIcon w={20} />
        </IconButton>
        <Typography variant="caption">Style</Typography>
      </Box>
      <Box width="100%">
        <FroalaEditorComponent
          ref={editorRef}
          model={editorData}
          onModelChange={(e: any) => {
            setEditorData(e);
            handleSearchChanges(e);
          }}
          config={{
            events: {
              focus: onFocusTextarea,
              blur: onBlurTextarea,
            },
            toolbarInline: true,
            showtoolbarInline: true,
            toolbarVisibleWithoutSelection: true,
            charCounterCount: false,
            quickInsertButtons: [],
            quickInsertTags: [],
            pluginsEnabled: ["link", "image", "file"],
            toolbarButtons: [
              "bold",
              "italic",
              "underline",
              "insertLink",
              "insertImage",
              "insertFile",
            ],
          }}
          tag="textarea"
        />
      </Box>
    </>
  );
};

export default CustomFroalaEditor;
