import React from "react";
import {
  SmallThreeDotMenu,
  SmallThreeDotMenuListInterface,
  useCustomSnackbar,
} from "../../../../common";
import {
  AllDirArrowsIcon,
  ConnectedIcon,
  DeleteIcon,
  EyeIcon,
  RenameIcon,
} from "../../../../../images";
import { useNavigate } from "react-router-dom";
import { getBaseUrl } from "../../../../../Utils";
import {
  deleteWorkspace__api,
  getAllWorkspace__api,
} from "../../../../../redux";
import { useDispatch } from "react-redux";

interface ThreeIconDropDownWorkspaceProps {
  displayOption?: "left" | "right";
  workspaceID: string;
}

const ThreeIconDropDownWorkspaceOptList: SmallThreeDotMenuListInterface[] = [
  {
    id: 1,
    icon: ConnectedIcon,
    text: "Copy link",
  },
  {
    id: 2,
    icon: AllDirArrowsIcon,
    direction: "right",
    text: "Move to",
  },
  {
    id: 3,
    icon: RenameIcon,
    text: "Rename",
  },
  {
    id: 4,
    icon: EyeIcon,
    text: "View",
  },
  {
    id: 5,
    icon: DeleteIcon,
    text: "Delete Workspace",
    color: "red",
  },
];

const ThreeIconDropDownWorkspace: React.FC<ThreeIconDropDownWorkspaceProps> = ({
  displayOption = "left",
  workspaceID,
}) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const enqueueSnackbar_v1 = useCustomSnackbar();
  const handleNavigate = (forceBlackOut?: boolean) => {
    if (workspaceID && !forceBlackOut) {
      // Ensure workspaceID is not empty or undefined
      navigate(`/workspace/${workspaceID}`);
    } else {
      if (forceBlackOut) {
        navigate(`/workspace`);
      }
    }
  };

  const handleClickAction = async (action: string) => {
    switch (action) {
      case "Copy link":
        const baseUrl = getBaseUrl();
        if (!baseUrl) {
          enqueueSnackbar_v1(
            "error",
            "Base URL not found for the current environment",
            {
              position: { vertical: "bottom", horizontal: "left" },
              autoHideDuration: 5000, // Optional custom auto-hide duration in milliseconds
            }
          );
          return;
        }

        const link = `${baseUrl}/workspace/${workspaceID}`;

        // Copy the link to the clipboard
        navigator.clipboard
          .writeText(link)
          .then(() => {
            enqueueSnackbar_v1("success", `Link copied to clipboard`, {
              position: { vertical: "bottom", horizontal: "left" },
              autoHideDuration: 5000, // Optional custom auto-hide duration in milliseconds
            });
          })
          .catch((err) => {
            console.error("Failed to copy link:", err);
            enqueueSnackbar_v1("error", "Failed to copy link", {
              position: { vertical: "bottom", horizontal: "left" },
              autoHideDuration: 5000, // Optional custom auto-hide duration in milliseconds
            });
          });
        break;

      case "Move to":
        // Move to another location logic
        console.log("Move action triggered");
        break;

      case "Rename":
        // Trigger rename action
        console.log("Rename action triggered");
        break;

      case "View":
        // Trigger view action
        handleNavigate(false);
        break;

      case "Delete Workspace":
        // Confirm and delete workspace
        const deleteRes: any = await deleteWorkspace(workspaceID);

        if (deleteRes) {
          getAllWorkspace();
        }

        break;

      default:
        console.warn("Unknown action:", action);
        break;
    }
  };
  const deleteWorkspace = async (_id: string) => {
    try {
      if (!_id) {
        console.error("Workspace ID is not defined");
        return;
      }

      const deleteRes: any = await dispatch(deleteWorkspace__api({ _id: _id }));

      if (deleteRes?.success) {
        return true;
      } else {
        return false;
      }
    } catch (err) {
      console.error("Failed to delete workspace:", err);

      return false;
    }
  };

  const getAllWorkspace = async () => {
    try {
      await dispatch(getAllWorkspace__api());
      handleNavigate(true);
    } catch (err) {
      console.log("🚀 ~ getAllWorkspace ~ err:", err);
    }
  };

  return (
    <SmallThreeDotMenu
      menuItems={ThreeIconDropDownWorkspaceOptList}
      onClickAction={(value) => {
        handleClickAction(value);
      }}
      displayOption={displayOption}
    />
  );
};

export default ThreeIconDropDownWorkspace;
