import { PayloadAction } from "@reduxjs/toolkit";
import {
  ChangeUserIdPayload,
  ConnectionPayload,
  CreateNewConnectionPayload,
  MessagePayload,
  MessagesState,
} from "../messageTypes";

export const getChannelMessageSuccess = (
  state: MessagesState,
  action: PayloadAction<MessagePayload>
) => {
  state.error = null;
  state.messages = action?.payload?.data || [];
};

export const sendMessageSuccess = (state: MessagesState) => {
  state.error = null;
  state.replyMessageContent = null;
};

export const getUserConnectionsSuccess = (
  state: MessagesState,
  action: PayloadAction<ConnectionPayload>
) => {
  state.error = null;
  state.channels = action?.payload?.data || [];
};

export const createNewConnectionSuccess = (
  state: MessagesState,
  action: PayloadAction<CreateNewConnectionPayload>
) => {
  state.error = null;
  state.currentChannelId = action?.payload?.data?._id;
  if (action?.payload?.data?.type === "CHANNEL") {
    state.currentChannelName = action?.payload?.data?.name;
  }
};

export const updateConnectionSuccess = (
  state: MessagesState,
  action: PayloadAction<CreateNewConnectionPayload>
) => {
  state.error = null;
  state.currentChannel.isPinned = action?.payload?.data?.isPinned;
};

export const updateMessageSuccess = (
  state: MessagesState,
  action: PayloadAction<CreateNewConnectionPayload>
) => {
  state.error = null;
  state.editMessageContent = {};
};

export const scheduleMessageSuccess = (
  state: MessagesState,
  action: PayloadAction<CreateNewConnectionPayload>
) => {
  state.error = null;
};

export const forwardMessageSuccess = (
  state: MessagesState,
  action: PayloadAction<CreateNewConnectionPayload>
) => {
  state.error = null;
};

export const addReactionToMessageSuccess = (
  state: MessagesState,
  action: PayloadAction<CreateNewConnectionPayload>
) => {
  state.error = null;
};

export const removeReactionToMessageSuccess = (
  state: MessagesState,
  action: PayloadAction<CreateNewConnectionPayload>
) => {
  state.error = null;
};
export const markMessagesAsReadSuccess = (
  state: MessagesState,
  action: PayloadAction<CreateNewConnectionPayload>
) => {
  state.error = null;
};

export const changeParticipentsRoleSuccess = (
  state: MessagesState,
  action: PayloadAction<ChangeUserIdPayload>
) => {
  state.error = null;
  const { userId, role } = action.payload.updatedParticipant;
  state.currentChannel.participants = state.currentChannel.participants.map(
    (participant: any) => {
      if (participant.userId === userId) {
        return {
          ...participant,
          role: role,
        };
      }
      return participant;
    }
  );
};

export const getChannelActivitiesSuccess = (
  state: MessagesState,
  action: PayloadAction<ChangeUserIdPayload>
) => {
  
  state.error = null;
  state.channelActivity = action.payload.data;
};

export const getMessageActivitiesSuccess = (
  state: MessagesState,
  action: PayloadAction<ChangeUserIdPayload>
) => {
  state.error = null;
  state.messageActivity = action.payload.data;
};
export const changeMessagesActivitiesStatusSuccess = (
  state: MessagesState,
  action: PayloadAction<ChangeUserIdPayload>
) => {
  state.error = null;
};
export const setSnoozeMessagesActivitiesSuccess = (
  state: MessagesState,
  action: PayloadAction<ChangeUserIdPayload>
) => {
  state.error = null;
};
