import { CalendarState } from "../CalendarType";

// Requests
export const createCalendar__Request = (state: CalendarState) => {
    state.error = null;
    state.data = null;
};

export const getAllCalendars__Request = (state: CalendarState) => {
    state.error = null;
    state.data = null;
};

export const getOneCalendar__Request = (state: CalendarState) => {
    state.error = null;
    state.data = null;
};

export const updateCalendar__Request = (state: CalendarState) => {
    state.error = null;
    state.data = null;
};

export const deleteCalendar__Request = (state: CalendarState) => {
    state.error = null;
    state.data = null;
};

export const createCalendarSettings__Request = (state: CalendarState) => {
    state.error = null;
    state.data = null;
};

export const getCalendarSettings__Request = (state: CalendarState) => {
    state.error = null;
    state.data = null;
};

export const updateCalendarSettings__Request = (state: CalendarState) => {
    state.error = null;
    state.data = null;
};

export const addConnectedCalendar__Request = (state: CalendarState) => {
    state.error = null;
    state.data = null;
};

export const removeConnectedCalendar__Request = (state: CalendarState) => {
    state.error = null;
    state.data = null;
};

export const createEvent__Request = (state: CalendarState) => {
    state.error = null;
    state.data = null;
};

export const getAllEvents__Request = (state: CalendarState) => {
    state.error = null;
    state.data = null;
};

export const createTask__Request = (state: CalendarState) => {
    state.error = null;
    state.data = null;
};

export const getAllTasks__Request = (state: CalendarState) => {
    state.error = null;
    state.data = null;
};

export const createBookingLink__Request = (state: CalendarState) => {
    state.error = null;
    state.data = null;
};

export const getBookingLinks__Request = (state: CalendarState) => {
    state.error = null;
    state.data = null;
};

export const eventResponse__Request = (state: CalendarState) => {
    state.error = null;
    state.data = null;
};
