import { createApiAction } from "../constantApi_config";
import {
    addShare__FailureAction,
    addShare__RequestAction,
    addShare__SuccessAction,

    /* Get Share */
    getShare__RequestAction,
    getShare__FailureAction,
    getShare__SuccessAction,
} from "./ShareSlice";
import { AddShare } from "./ShareType";

/* Add Share */
export const addShare__api = (payload: AddShare) =>
    createApiAction({
        axiosRequired: true,
        method: "POST",
        data: payload,
        url: `/share/add-share`,
        onStart: addShare__RequestAction.type,
        onSuccess: addShare__SuccessAction.type,
        onError: addShare__FailureAction.type,
    });

/* Get Share */
export const getShare__api = (entityId: string) =>
    createApiAction({
        axiosRequired: true,
        method: "GET",
        url: `/share/share/${entityId}`,
        onStart: getShare__RequestAction.type,
        onSuccess: getShare__SuccessAction.type,
        onError: getShare__FailureAction.type,
    });
