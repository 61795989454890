import { Drawer, IconButton, Popover, Stack } from "@mui/material";
import React from "react";
import { styled } from "@mui/system";
import CompanyIconAndName from "../../CompanyIconAndName";
import { MainActionButton } from "../../../Buttons";
import styles from "../../DrawerNavbar.module.css";
import { ButtonType, MainActionColor } from "../../../Buttons/AllButtonProps";
import { GearIcon } from "../../../../../images";
import DrawerSectionText from "../../DrawerSectionText";
import { SidebarPinnedChatContainer } from "../../../../pages/Message/components";
import { MenuOptions, SmartFilter } from "./Components";
import { AccountsPinnedCard } from "../../../../pages/Email/components/AccountsChip";
import {

  MailSettings,
  SmartFiltersDropdown,
} from "../../../../pages/Email/components";

type EmailDrawerProps = {
  drawerOpen: boolean;
  drawerCloser: () => void;
};

const DrawerContainer = styled("div")({
  position: "fixed",
  top: 0,
  height: "100vh",
  zIndex: 1100, // Ensure the drawer is behind the navbar
});
const EmailDrawer: React.FC<EmailDrawerProps> = ({
  drawerOpen,
  drawerCloser,
}) => {
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
    null
  );
  const [popoverType, setPopoverType] = React.useState<
    "smartFilter" | "mailSettings" | null
  >(null);

  const handleClick = (
    event: React.MouseEvent<HTMLButtonElement>,
    type: "smartFilter" | "mailSettings"
  ) => {
    setAnchorEl(event.currentTarget);
    setPopoverType(type);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setPopoverType(null); // Reset popover type when closed
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  return (
    <>
      <DrawerContainer>
        <Drawer
          sx={{
            position: "absolute",
            top: 0,
            height: "100vh",
            width: "215px",
            // left: '-240px', // Start hidden behind the Navbar
            transition: drawerOpen
              ? "left 225ms ease-out"
              : "left 195ms ease-in",
            left: drawerOpen ? "30px" : "-215px",
            zIndex: 1100, // Ensure the drawer is behind the navbar
            "& .MuiPaper-root": {
              position: "absolute",
              top: 0,
              height: "100vh",
              width: "215px",
              left: 0,
              boxSizing: "border-box",
              zIndex: 1100, // Ensure the paper is also behind the navbar
            },
          }}
          variant="persistent"
          anchor="left"
          open={drawerOpen}
        >
          <Stack
            bgcolor={"white"}
            direction="column"
            flex={1}
            className={`${styles.drawerNavbar__drawer}`}
          >
            {/* Company logo and name here */}
            <CompanyIconAndName arrowButtonClickHandler={drawerCloser} />
            <Stack className={styles.drawerNavbar__content}>
              {/* Buttons and other lists here */}
              <Stack
                direction={"row"}
                alignItems={"center"}
                justifyContent={"space-between"}
                width={"100%"}
              >
                <MainActionButton
                  sx={{ marginY: "25px" }}
                  onClick={() => console.log("Home button clicked")}
                  colorVarient={MainActionColor.Blue}
                  label="Compose"
                  types={ButtonType.Button}
                />

                <Stack>
                  <IconButton
                    onClick={(event) => handleClick(event, "mailSettings")}
                  >
                    <GearIcon w={17} />
                  </IconButton>
                </Stack>
              </Stack>

              <Stack
                height="100%"
                overflow="hidden"
                maxHeight="84%"
                sx={{
                  "&::-webkit-scrollbar": {
                    width: "0 !important", // hide scrollbar on Chrome, Safari, etc.
                  },
                  scrollbarWidth: "none", // hide scrollbar on Firefox
                  overflowX: "hidden",
                  overflowY: "scroll",
                }}
              >
                {" "}
                <DrawerSectionText title="Accounts" />
                <Stack mt={"15px"} mb={"15px"} sx={{ marginBottom: "30px" }}>
                  <SidebarPinnedChatContainer needName={false}>
                    {DUMMY_CONNECTED_ACCOUNTS.map(
                      (chatItem: any, index: number) => (
                        <AccountsPinnedCard {...chatItem} key={index} />
                      )
                    )}
                  </SidebarPinnedChatContainer>
                </Stack>
                <Stack
                  direction={"row"}
                  alignItems={"center"}
                  justifyContent={"space-between"}
                >
                  <DrawerSectionText title="Smart filters" />
                  <IconButton
                    onClick={(event) => handleClick(event, "smartFilter")}
                  >
                    <GearIcon w={17} />
                  </IconButton>
                </Stack>
                <Stack mb={"15px"} width={"100%"} mt={"10px"}>
                  <SmartFilter />
                </Stack>
                <DrawerSectionText title="Menu" />
                <Stack mb={"15px"} width={"100%"} mt={"10px"}>
                  <MenuOptions />
                </Stack>
                <DrawerSectionText title="Folders" />
              </Stack>
            </Stack>
          </Stack>
          {/* Popover */}
          <Popover
            id={id}
            open={open}
            anchorEl={anchorEl}
            onClose={handleClose}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "left",
            }}
          >
            {popoverType === "smartFilter" ? <SmartFiltersDropdown /> : null}
            {popoverType === "mailSettings" ? <MailSettings handleClose={handleClose} /> : null}
          </Popover>
        </Drawer>
      </DrawerContainer>
    </>
  );
};

export default EmailDrawer;
const DUMMY_CONNECTED_ACCOUNTS = [
  {
    userNameOrEmail: "G Gmail",
    userIconSrc: "path/to/gmail-icon.png",
    topIcon: "gmail",
  },
  {
    userNameOrEmail: "G Microsoft",
    userIconSrc: "path/to/microsoft-icon.png",
    topIcon: "outlookMail",
  },
  {
    userNameOrEmail: "G Slack",
    userIconSrc: "path/to/slack-icon.png",
    topIcon: "teams",
  },
  // Add more accounts as needed
];
