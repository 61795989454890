import { Stack } from "@mui/material";
import React, { useState } from "react";

import {
  CrunchbaseBlackPng,
  SlackPng,
  StreamIconBearishOSIconPng,
  XBlackPng,
} from "../../../../../../images";
import { MessageNameCard } from "../../../../MessageNameCard";
import { useSelector } from "react-redux";
import { MessagesState } from "../../../../../../redux/Slice/message/messageTypes";
import { RootState } from "../../../../../../redux/store";
import Loader from "../../../../Loader/Loader";
import { AuthState } from "../../../../../../redux";

interface DefaultQuickMsgViewProps {
  dataArr?: any[];
}

const DefaultQuickMsgView: React.FC<DefaultQuickMsgViewProps> = ({
  dataArr,
}) => {
  const { currentChannelId } = useSelector(
    (state: RootState) => state.message as MessagesState
  );
  const { user } = useSelector((state: RootState) => state.auth as AuthState);
  if (!dataArr) return <Loader />;

  return (
    <Stack sx={{ width: "100%", gap: "5px" }}>
      {dataArr?.map((data, index) => {
        if (data.isPinned === true) return;

        return (
          <MessageNameCard
            key={index}
            lastMessage={data?.lastMessage || ""}
            channelDetails={data}
            userData={user}
            companyImgSrc={StreamIconBearishOSIconPng}
            userName={data.displayName}
            channelId={data?._id}
            channelName={data.displayName}
            isSelected={data._id === currentChannelId}
          />
        );
      })}
    </Stack>
  );
};

export default DefaultQuickMsgView;
