import {
  Box,
  Divider,
  IconButton,
  Modal,
  Popover,
  Stack,
  Tooltip,
} from "@mui/material";
import React, { useState } from "react";
import {
  AllDirArrowsIcon,
  ClockIcon,
  CreatedIcon,
  DeleteIcon,
  FlagIcon,
  ForwardIcon,
  ReplyIcon,
} from "../../../../../images";
import { EmailClickedMenu } from "../EmailClickedMenu";
import { EmailPageController } from "../EmailPageController";
import { EmailPreviewArea } from "../EmailPreviewArea";
import { SnoozeEmail } from "../SnoozeEmail";
import { useNavigate } from "react-router-dom";
import { EmailSmallerPopout } from "../../../../common";

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "fit-content",
  bgcolor: "background.paper",
  boxShadow: 24,
  borderRadius:"5px",
};

// Reusable hook for popover logic
const usePopover = () => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const handleOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const open = Boolean(anchorEl);
  return { anchorEl, open, handleOpen, handleClose };
};

interface InsideEmailTopBarProps {
  infoButton?: any;
}

const InsideEmailTopBar: React.FC<InsideEmailTopBarProps> = ({
  infoButton,
}) => {
  const snoozePopover = usePopover();

  const navigate = useNavigate();
  const handleNavigate = () => {
    navigate(`/Email/`);
  };
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  return (
    <Stack sx={{ width: "100%", height: "100%", bgcolor: "white" }}>
      {/* --- upper side buttons here */}
      <Stack
        sx={{
          height: "45px",
          borderTop: "1px solid #E9EDF2",
          borderBottom: "1px solid #E9EDF2",
          flexDirection: "row",
          alignItems: "center",
          boxSizing: "border-box",
          bgcolor: "white",
          paddingLeft: "5px",
          justifyContent: "space-between",
          flexShrink: 0,
        }}
      >
        <Stack sx={{ flexDirection: "row", alignItems: "center", gap: "10px" }}>
          <Tooltip title="Back" arrow placement="top">
            <IconButton
              onClick={() => handleNavigate()}
              sx={{ p: "2.5px" }}
              disableRipple
            >
              <AllDirArrowsIcon direction="left" w={15} />
            </IconButton>
          </Tooltip>

          <Tooltip title="Compose a new email" arrow placement="top">
            <IconButton onClick={handleOpen} sx={{ p: "2.5px" }} disableRipple>
              <CreatedIcon w={15} />
            </IconButton>
          </Tooltip>

          <Tooltip title="Priority" arrow placement="top">
            <IconButton sx={{ p: "2.5px" }} disableRipple>
              <FlagIcon w={15} color="#FFD700" />
            </IconButton>
          </Tooltip>

          <Tooltip title="Delete" arrow placement="top">
            <IconButton sx={{ p: "2.5px" }} disableRipple>
              <DeleteIcon w={15} color="black" />
            </IconButton>
          </Tooltip>

          <Divider
            orientation="vertical"
            sx={{ borderColor: "#E9EDF2", padding: "10px 0px" }}
          />

          <Tooltip title="Reply" arrow placement="top">
            <IconButton onClick={handleOpen}  sx={{ p: "2.5px" }} disableRipple>
              <ForwardIcon w={15} />
            </IconButton>
          </Tooltip>

          <>
            <Tooltip title="Snooze" arrow placement="top">
              <IconButton
                sx={{ p: "2.5px" }}
                disableRipple
                onClick={snoozePopover.handleOpen}
              >
                <ClockIcon w={15} />
              </IconButton>
            </Tooltip>
            <Popover
              id={snoozePopover.open ? "snooze-popover" : undefined}
              open={snoozePopover.open}
              anchorEl={snoozePopover.anchorEl}
              onClose={snoozePopover.handleClose}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "center",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
              PaperProps={{
                style: {
                  marginTop: "12px",
                  boxShadow: "0px 3px 3px 0px rgba(0, 0, 0, 0.15)",
                },
              }}
            >
              <SnoozeEmail variant="SnoozeEmail" />
            </Popover>
          </>

          <Tooltip title="Forward" arrow placement="top">
            <IconButton onClick={handleOpen}  sx={{ p: "2.5px" }} disableRipple>
              <ReplyIcon w={15} />
            </IconButton>
          </Tooltip>
        </Stack>

        <Stack sx={{ flexDirection: "row", alignItems: "center", gap: "15px" }}>
          <EmailPageController
            totalPage={125}
            currentPage={7}
            onChange={(pageNum) => null}
          />
          <EmailClickedMenu tooltipText="Menu" displayOption="left" />
        </Stack>
      </Stack>

      {/* --------- Email Preview here --------- */}
      <div style={{ marginTop: "30px" }} />
      <EmailPreviewArea infoButton={infoButton} />

      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <EmailSmallerPopout onCloseClick={() => handleClose()} />
        </Box>
      </Modal>
    </Stack>
  );
};

export default InsideEmailTopBar;
