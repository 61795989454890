import { IconButton, Popover, Stack, Typography } from "@mui/material";
import React from "react";
import { TeamMemberIcon } from "../../../../images";
import S from "./ShareButton.module.css";
import { PopOverModel } from "./PopOverModel";
import { useShareButton } from "./useShareButton";
import { useParams } from "react-router-dom";
import {
  AddShare,
  addShare__api,
  AddShareEntitiesNameEnums,
  AddShareEntitiesRoleEnums,
  getOneFile__api,
  shareFileWithEmail__api,
  shareFileWithUsers__api,
} from "../../../../redux";
import { useDispatch } from "react-redux";

interface ShareButtonProps {
  wId?: string;
}
type Params = Record<string, string | undefined>;

const ShareButton: React.FC<ShareButtonProps> = () => {
  const dispatch = useDispatch();
  const { fileId, workspaceId, componentType, componentId, subcomponentId } =
    useParams<Params>();
  const {
    anchorEl,
    tooltipTitle,
    selectedTab,
    selectedOption,
    open,
    id,
    handleClick,
    handleClose,
    handleCopyLinkClick,
    resetSelection,
    setSelectedTab,
  } = useShareButton();

  const getRole = (data: { userData: any; action: any; customEmail?: any }) => {
    const roleMapping: Record<string, AddShareEntitiesRoleEnums> = {
      Guest: "viewer",
      Commenter: "commenter",
      Editor: "editor",
    };

    return roleMapping[data?.action] ?? undefined;
  };

  const getEntitiesName = () => {
    if (workspaceId && !componentType) return "workspace";
    if (fileId) return "file";
    if (componentType) {
      if (["Board", "List", "Checklist"]?.includes(componentType))
        return "task";
      return subcomponentId ? "wiki" : componentType;
    }
    return "";
  };

  const getEntitiesId = () => {
    if (workspaceId && !componentType) return workspaceId;
    if (fileId) return fileId;
    if (componentType) {
      if (["Board", "List", "Checklist"]?.includes(componentType))
        return componentId;
      return subcomponentId || componentId;
    }
    return "";
  };

  const handleSelectedData = async (data: {
    userData: any;
    action: any;
    customEmail?: any;
  }) => {
    if (fileId) {
      // For sharing with user
      if (
        data?.userData?._id &&
        data?.userData?.email &&
        !data?.userData?.recipientEmail
      ) {
        const payload = {
          memberId: data?.userData?._id,
          memberRole: getRole(data) as string,
        };

        // Dispatch for sharing with user
        try {
          await dispatch(shareFileWithUsers__api(fileId, payload));
        } catch (error) {
          console.error("Error adding member:", error);
        } finally {
          getAllMembers();
        }
      }

      // For sharing via email
      if (data?.userData?.recipientEmail && !data?.userData?._id) {
        const recipientEmail =
          data?.userData?.recipientEmail || data.customEmail;

        if (recipientEmail) {
          const emailPayload = {
            fileId: fileId, // Ensure fileId is passed
            recipientEmail: recipientEmail,
          };

          // Dispatch for sharing via email
          try {
            await dispatch(shareFileWithEmail__api(emailPayload));
          } catch (error) {
            console.error("Error adding member:", error);
          } finally {
            getAllMembers();
          }
        }
      }
    } else {
      const payload: AddShare = {
        mainLink: window.location.href as string,
        entityId: getEntitiesId() as string,
        entityName: getEntitiesName() as AddShareEntitiesNameEnums,
        memberId: data?.userData?._id as string,
        role: getRole(data) as AddShareEntitiesRoleEnums,
        expirationDate: null,
      };

      await dispatch(addShare__api(payload));
    }
  };

  const getAllMembers = async () => {
    if (fileId) {
      try {
        await dispatch(getOneFile__api(fileId));
      } catch (e) {
        console.error(e);
      }
    }
  };

  const updateMembers = async (item: any, permissions: string) => {
    /* Update share api */
  };

  return (
    <>
      <IconButton
        aria-describedby={id}
        onClick={handleClick}
        className={S["share-button"]}
        disableRipple
        disableFocusRipple
      >
        <Stack direction={"row"} alignItems={"center"} gap={"5px"}>
          <TeamMemberIcon w={20} />
          <Typography
            sx={{ font: "normal normal 300 14px/20px Source Serif Pro" }}
          >
            Share
          </Typography>
        </Stack>
      </IconButton>

      {/* Popover that opens on Share button click */}
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        PaperProps={{
          className: S["share-button__popover"],
        }}
      >
        <PopOverModel
          tooltipTitle={tooltipTitle}
          resetSelection={resetSelection}
          handleSelectedData={handleSelectedData}
          selectedOption={selectedOption}
          handleCopyLinkClick={handleCopyLinkClick}
          setSelectedTab={setSelectedTab}
          selectedTab={selectedTab}
          getAllMembers={getAllMembers}
          updateMembers={updateMembers}
        />
      </Popover>
    </>
  );
};

export default ShareButton;
