import {createContext, useCallback, useMemo, useRef, useState} from "react";
import {useDispatch} from "react-redux";
import {
    ConsoleLogger,
    DefaultDeviceController,
    DefaultMeetingSession,
    LogLevel,
    MeetingSessionConfiguration,
    VideoTileState,
} from "amazon-chime-sdk-js";
import {
    createAttendee as createAttendeeAction,
    createMeeting as createMeetingAction,
    getMeeting as getMeetingAction,
    // getAttendee as getAttendeeAction
} from "../../../redux";
import {
    Attendee,
    ChimeAttendee,
    ChimeMeeting,
    Meeting,
    MeetingContextRef, MeetingContextState,
    MeetingContextTypes,
} from "./MeetingContextTypes";

export const initialRef = {
    logger: null,
    deviceController: null,
    configuration: null,
    meetingSession: null,
    meetings: {},
    chimeMeetings: {},
    attendees: {},
    chimeAttendees: {},
    elements: {
        audioOutput: null,
    },
};

export const initialState = {
    realtimeAttendees: {},
}

export const MeetingContext = createContext<MeetingContextTypes | undefined>(undefined);

export const MeetingProvider = ({children}: { children: any }) => {

    const dispatch = useDispatch();
    const ref: { current: MeetingContextRef } = useRef(initialRef);

    const [state, setState] = useState<MeetingContextState>(initialState);

    const createLogger = useCallback(() => {
        ref.current.logger = new ConsoleLogger("AWS-CHIME :", LogLevel.INFO);
    }, []);

    const createDeviceController = useCallback(() => {
        createLogger();
        ref.current.deviceController = new DefaultDeviceController(
            ref.current.logger
        );
    }, []);

    const createConfiguration = useCallback(({meetingId, attendeeId}: { meetingId: string, attendeeId: string }) => {
        ref.current.configuration = new MeetingSessionConfiguration(
            ref.current.chimeMeetings[meetingId],
            ref.current.chimeAttendees[attendeeId]
        )
    }, []);

    const createMeeting = useCallback(async () => {
        const [data]: any = await Promise.all([dispatch(createMeetingAction())]);
        return data?.response?.meeting as Meeting;
    }, []);

    const createAttendee = useCallback(async ({meetingId}: { meetingId: string }) => {
        const [data]: any = await Promise.all([dispatch(createAttendeeAction({meetingId}))]);

        const attendee = data?.response?.attendee as Attendee;
        const chimeAttendee = data?.response?.chimeAttendee as ChimeAttendee;

        if (attendee?._id) {
            ref.current.attendees[attendee._id] = attendee;
            ref.current.attendees[attendee.chimeAttendeeId] = attendee;
        }

        if (chimeAttendee?.AttendeeId) {
            ref.current.chimeAttendees[chimeAttendee.AttendeeId] = chimeAttendee;
            ref.current.chimeAttendees[chimeAttendee.ExternalUserId] = chimeAttendee;
        }

        return attendee;
    }, []);

    const getMeeting = useCallback(async ({meetingId}: { meetingId: string }) => {
        const [data]: any = await Promise.all([dispatch(getMeetingAction(meetingId))]);

        const meeting = data?.response?.meeting as Meeting;
        const chimeMeeting = data?.response?.chimeMeeting as ChimeMeeting;

        if (meeting?._id) {
            const {_id, externalMeetingId, chimeMeetingId} = meeting;
            ref.current.meetings[_id] = meeting;
            ref.current.meetings[externalMeetingId] = meeting;
            ref.current.meetings[chimeMeetingId] = meeting;
        }

        if (chimeMeeting?.MeetingId) {
            ref.current.chimeMeetings[chimeMeeting.MeetingId] = chimeMeeting;
            ref.current.chimeMeetings[chimeMeeting.ExternalMeetingId] = chimeMeeting
        }

        return meeting;

    }, []);

    const joinMeeting = useCallback(({meetingId, attendeeId}: {
        meetingId: string,
        attendeeId: string
    }) => {
        createDeviceController();
        createConfiguration({meetingId, attendeeId});
        ref.current.meetingSession = new DefaultMeetingSession(ref.current.configuration, ref.current.logger, ref.current.deviceController);

        const observer = {
            audioVideoDidStart: () => {
                ref.current.meetingSession.audioVideo.startLocalVideoTile();
            },
            audioVideoDidStop: (sessionStatus: any) => {
                console.log('Stopped with a session status code: ', sessionStatus.statusCode());
            },
            audioVideoDidStartConnecting: (reconnecting: any) => {
                if (reconnecting) {
                    console.log('Attempting to reconnect', reconnecting);
                }
            },
            videoTileDidUpdate: (tileState: VideoTileState) => {
                console.log({tileState}, "HELLO");
                setState((prevState) => {
                    if (prevState.realtimeAttendees[tileState.boundAttendeeId as string]) {
                        return {
                            ...prevState,
                            realtimeAttendees: {
                                ...prevState.realtimeAttendees,
                                [tileState.boundAttendeeId as string]: {
                                    ...prevState.realtimeAttendees[tileState.boundAttendeeId as string],
                                    videoTileState: tileState
                                }
                            }
                        }
                    } else {
                        return prevState;
                    }
                });
            }
        };

        ref.current.meetingSession.audioVideo.realtimeSubscribeToAttendeeIdPresence((chimeAttendeeId: string, isPresent: boolean, userId: string,) => {
            console.log({chimeAttendeeId, isPresent, userId}, "HELLO");
            setState((prevState) => {
                if (isPresent) {
                    return {
                        ...prevState,
                        realtimeAttendees: {
                            ...prevState.realtimeAttendees,
                            [chimeAttendeeId]: {
                                chimeAttendeeId,
                                userId
                            }
                        }
                    }
                } else {
                    const {[chimeAttendeeId]: _, ...rest} = prevState.realtimeAttendees;
                    return {
                        ...prevState,
                        realtimeAttendees: rest
                    }
                }
            });
        })

        // @ts-ignore
        window.audioVideo = ref.current.meetingSession.audioVideo;

        ref.current.meetingSession.audioVideo.addObserver(observer);
    }, []);

    const configureAudioOutput = useCallback(async () => {
        const audioOutputDevices = await ref.current.meetingSession.audioVideo.listAudioOutputDevices();
        if (audioOutputDevices[0]?.deviceId)
            await ref.current.meetingSession.audioVideo.chooseAudioOutput(audioOutputDevices[0]?.deviceId);

        if (!ref.current.elements.audioOutput) {
            const audioOutputElement = document.createElement("audio");
            audioOutputElement.style.display = "none";
            document.body.appendChild(audioOutputElement);
            ref.current.elements.audioOutput = audioOutputElement;
        }

        ref.current.meetingSession.audioVideo.bindAudioElement(ref.current.elements.audioOutput);
    }, []);

    const startAudioInput = useCallback(async () => {
        const audioInputDevices = await ref.current.meetingSession.audioVideo.listAudioInputDevices();
        if (audioInputDevices[0]?.deviceId)
            await ref.current.meetingSession.audioVideo.startAudioInput(audioInputDevices[0].deviceId);
    }, []);

    const stopAudioInput = useCallback(async () => {
    }, []);

    const startVideoInput = useCallback(async () => {
        const videoInputDevices = await ref.current.meetingSession.audioVideo.listVideoInputDevices();
        if (videoInputDevices[0]?.deviceId)
            await ref.current.meetingSession.audioVideo.startVideoInput(videoInputDevices[0].deviceId);
    }, []);

    const stopVideoInput = useCallback(async () => {
        await ref.current.meetingSession.audioVideo.stopVideoInput();
    }, []);

    const startPreviewLocalVideo = useCallback(async (videoElement: HTMLVideoElement) => {
        await ref.current.meetingSession.audioVideo.startVideoPreviewForVideoInput(videoElement);
    }, []);

    const startSession = useCallback(async () => {
        await ref.current.meetingSession.audioVideo.start();
    }, []);

    const stopSession = useCallback(async () => {
        await ref.current.meetingSession.audioVideo.stop();
    }, []);

    const bindVideoTile = useCallback(({tileId, videoElement} : {tileId: number, videoElement: HTMLVideoElement}) => {
        ref.current.meetingSession.audioVideo.bindVideoElement(tileId, videoElement);
    }, []);

    const startScreenShare = useCallback(async () => {
        try {
            await ref.current.meetingSession.audioVideo.startContentShareFromScreenCapture();
        } catch (error) {
            console.error("Failed to start screen share",error);
        }
    }, []);

    const pauseScreenShare = useCallback(async () => {
        try {
            await ref.current.meetingSession.audioVideo.pauseContentShare();
        } catch (error) {
            console.error("Failed to pause screen share",error);
        }
    }, []);

    const unpauseScreenShare = useCallback(async () => {
        try {
            await ref.current.meetingSession.audioVideo.unpauseContentShare();
        } catch (error) {
            console.error("Failed to unpause screen share",error);
        }
    }, []);

    const stopScreenShare = useCallback(async () => {
        try {
            await ref.current.meetingSession.audioVideo.stopContentShare();
        } catch (error) {
            console.error("Failed to stop screen share",error);
        }
    }, []);

    const value = useMemo(() => ({
        ...ref.current,
        ...state,
        getMeeting,
        createMeeting,
        createAttendee,
        joinMeeting,
        configureAudioOutput,
        startAudioInput,
        stopAudioInput,
        startVideoInput,
        stopVideoInput,
        startPreviewLocalVideo,
        startSession,
        stopSession,
        bindVideoTile,
        startScreenShare,
        pauseScreenShare,
        unpauseScreenShare,
        stopScreenShare
    }), [state])

    return (
        <MeetingContext.Provider value={value}>
            {
                children
            }
        </MeetingContext.Provider>
    )
};