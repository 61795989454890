import React from "react";
import { SmallThreeDotMenuListInterface } from "../SmallThreeDotMenu/SmallThreeDotMenuTypes";
import {
  DeleteIcon,
  DownloadIcon,
  ShareIcon,
  TemplateIcon,
} from "../../../images";
import { SmallThreeDotMenu } from "../SmallThreeDotMenu";

interface ThreeDotMenuNotesProps {
  displayOption?: "left" | "right";
}

const ThreeDotMenuNotesOptionsList: SmallThreeDotMenuListInterface[] = [
  {
    id: 1,
    icon: TemplateIcon,
    text: "Create template",
  },
  {
    id: 2,
    icon: TemplateIcon,
    text: "Apply template",
  },
  {
    id: 3,
    icon: DownloadIcon,
    text: "Download",
  },
  {
    id: 4,
    icon: ShareIcon,
    text: "Share",
  },
  {
    id: 5,
    icon: DeleteIcon,
    text: "Delete",
    color: "red",
  },
];

const ThreeDotMenuNotes: React.FC<ThreeDotMenuNotesProps> = ({
  displayOption = "left",
}) => {
  return (
    <SmallThreeDotMenu
      menuItems={ThreeDotMenuNotesOptionsList}
      onClickAction={(value) => console.log(value)}
      displayOption={displayOption}
    />
  );
};

export default ThreeDotMenuNotes;
