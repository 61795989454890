import { Drawer, Stack, Typography, Container } from "@mui/material";
import React, { useState } from "react";
import { Box, styled } from "@mui/system";
import CompanyIconAndName from "../../CompanyIconAndName";
import { MainActionButton } from "../../../Buttons";
import styles from "../../DrawerNavbar.module.css";
import { ButtonType, MainActionColor } from "../../../Buttons/AllButtonProps";
import {
  SidebarPinnedChatContainer,
  SidebarMsgContainer,
  SettingsMessagesMain,
  SidebarPinnedForChannelContainer,
} from "../../../../pages/Message/components";
import { MessageView } from "../../../QuickActions/View";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getAllUsers } from "../../../../../redux/Slice/auth/authActions";
import { MessagesState } from "../../../../../redux/Slice/message/messageTypes";
import { RootState } from "../../../../../redux/store";
type MessageDrawerProps = {
  drawerOpen: boolean;
  drawerCloser: () => void;
};

const DrawerContainer = styled("div")({
  position: "fixed",
  top: 0,
  height: "100vh",
  zIndex: 1100, // Ensure the drawer is behind the navbar
});
const MessageDrawer: React.FC<MessageDrawerProps> = ({
  drawerOpen,
  drawerCloser,
}) => {
  const [filterOptions, setFilterOptions] = useState<any>([
    { id: 1, value: "Show Internal Chats", isSelected: true },
    { id: 2, value: "Show External Chats", isSelected: true },
    { id: 3, value: "Show Pinned Chats", isSelected: true },
    { id: 4, value: "Show Channels", isSelected: true },
    { id: 5, value: "Show Apps", isSelected: true },
  ]);

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const handleClick = () => {};
  const { channels } = useSelector(
    (state: RootState) => state.message as MessagesState
  );

  const handleMessage = async () => {
    await dispatch(getAllUsers());
    navigate(`/Message/createconnection`);
  };
  const handleOnChange = async (data: any) => {
    setFilterOptions(data);
  };

  return (
    <>
      <DrawerContainer>
        <Drawer
          sx={{
            position: "absolute",
            top: 0,
            height: "100vh",
            width: "215px",
            // left: '-240px', // Start hidden behind the Navbar
            transition: drawerOpen
              ? "left 225ms ease-out"
              : "left 195ms ease-in",
            left: drawerOpen ? "30px" : "-215px",
            zIndex: 1100, // Ensure the drawer is behind the navbar
            "& .MuiPaper-root": {
              position: "absolute",
              top: 0,
              height: "100vh",
              width: "215px",
              left: 0,
              boxSizing: "border-box",
              zIndex: 1100, // Ensure the paper is also behind the navbar
            },
          }}
          variant="persistent"
          anchor="left"
          open={drawerOpen}
        >
          <Stack
            bgcolor={"white"}
            direction="column"
            flex={1}
            className={`${styles.drawerNavbar__drawer}`}
          >
            {/* Company logo and name here */}
            <CompanyIconAndName arrowButtonClickHandler={drawerCloser} />
            <Stack className={styles.drawerNavbar__content}>
              {/* Buttons and other lists here */}
              <Box style={{ display: "flex" }}>
                <MainActionButton
                  sx={{ marginY: "25px" }}
                  onClick={handleMessage}
                  colorVarient={MainActionColor.Orange}
                  label="Message"
                  types={ButtonType.Button}
                />
                <div style={{ marginLeft: "55px", display: "flex" }}>
                  <SettingsMessagesMain
                    onChange={handleOnChange}
                    displayOption="right"
                  />
                </div>
              </Box>

              {filterOptions[2].isSelected && <SidebarPinnedForChannelContainer />}
              {filterOptions[0].isSelected && (
                <SidebarMsgContainer
                  children={<MessageView onBackButtonClick={handleClick} />}
                  label="Internal Chats"
                  isOpen={channels}
                />
              )}
              {filterOptions[1].isSelected && (
                <SidebarMsgContainer
                  children={<MessageView onBackButtonClick={handleClick} />}
                  label="External Chats"
                />
              )}
            </Stack>
          </Stack>
        </Drawer>
      </DrawerContainer>
    </>
  );
};

export default MessageDrawer;
