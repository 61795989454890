import React from "react";
import { Box, Stack } from "@mui/material";
import styles from "./SwitchSmall.module.css";
import { CardViewIcon, ListViewIcon } from "../../../images";
import { SwitchSmallProps } from "../../pages";

enum DisplayMode {
  List = "square",
  Module = "stacked",
}

const SwitchSmall: React.FC<SwitchSmallProps> = ({
  viewMode,
  setViewMode,
  disabled,
}) => {
  const viewModes = (displayType: DisplayMode): void => {
    if (!disabled && viewMode !== displayType) {
      // Prevent switching if disabled is true
      setViewMode(displayType);
    }
  };

  return (
    <Stack direction="row" className={styles.SwitchSmallContainer}>
      <Box
        className={`${styles.SwitchSmall} ${
          viewMode === DisplayMode.List ? styles["SwitchSmall--selected"] : ""
        }`}
        component="span"
        onClick={() => viewModes(DisplayMode.List)}
        style={{ pointerEvents: disabled ? "none" : "auto" }} // Disable click when disabled
      >
        <ListViewIcon />
      </Box>
      <Box
        className={`${styles.SwitchSmall} ${
          viewMode === DisplayMode.Module ? styles["SwitchSmall--selected"] : ""
        }`}
        component="span"
        onClick={() => viewModes(DisplayMode.Module)}
        style={{ pointerEvents: disabled ? "none" : "auto" }} // Disable click when disabled
      >
        <CardViewIcon />
      </Box>
    </Stack>
  );
};

export default SwitchSmall;
