import { Typography } from "@mui/material";
import React from "react";

interface TimeViewProps {
  time: string;
}

const TimeView: React.FC<TimeViewProps> = ({ time }) => {
  const formatDate = (isoDateString: string): string => {
    const date = new Date(isoDateString);
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are 0-indexed
    const year = date.getFullYear();
    return `${day}-${month}-${year}`;
  };
  return (
    <Typography sx={{ font: "normal normal 300 11px/14px Source Serif Pro" }}>
      {formatDate(time)}
    </Typography>
  );
};

export default TimeView;
