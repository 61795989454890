import { Box } from "@mui/material";
import  {  useEffect } from "react";
import QuickMessagePreviewView from "../../../common/QuickActions/View/MessageView/MessagePreviewView/QuickMessagePreviewView";
import { AuthState } from "../../../../redux";
import { RootState } from "../../../../redux/store";
import {  useSelector } from "react-redux";

const MessagesScreen = (messages: any) => {
  const { user } = useSelector((state: RootState) => state.auth as AuthState);

  useEffect(() => {
    let scrollableDiv: any = document?.getElementById("scrollableDiv");

    function scrollToBottom() {
      scrollableDiv.scrollTop = scrollableDiv.scrollHeight;
    }
    scrollToBottom();
  }, [messages]);

  return (
    <Box id="scrollableDiv">
      {messages.messages &&
        messages.messages.map((message: any,index:any) => {
          let messageType;

          if (message?.userId === user?.data?._id) {
            messageType = "send";
          } else {
            messageType = "received";
          }
          
          return (
            <QuickMessagePreviewView
              editMessageHistory={message?.editHistory}
              key={index}
              isEdited={message?.isEdited}
              replyMessage={message?.repliedContent || ""}
              messageId={message?._id}
              messageType={messageType}
              messageSender={message?.messageSender}
              messageText={message?.content}
              timeStamp={message?.createdAt}
              displayName={message?.displayName}
              reaction={message?.reaction}
            />
          );
        })}
    </Box>
  );
};

export default MessagesScreen;
