export interface SettingsMessagesMainListInterface {
  id: number;
  value: string;
  isSelected: boolean;
}

// create this list with as need
export const SettingsMessagesMainList: SettingsMessagesMainListInterface[] = [
  { id: 1, value: "Show Internal Chats", isSelected: true },
  { id: 2, value: "Show External Chats", isSelected: true },
  { id: 3, value: "Show Pinned Chats", isSelected: true },
  { id: 4, value: "Show Channels", isSelected: true },
  { id: 5, value: "Show Apps", isSelected: false }, 
];
