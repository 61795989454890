import { PayloadAction } from "@reduxjs/toolkit";
import { VideoCenterState } from "../VideoCenterTypes";

export const createMeetingSuccess = (state: VideoCenterState, action: PayloadAction<string>) => {
    state.errors = action.payload;
};
export const getUserMeetingsSuccess = (state: VideoCenterState, action: PayloadAction<string>) => {
    state.errors = action.payload;
    debugger
    state.upcomingMeetings = action.payload;
};