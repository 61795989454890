import {useCallback, useContext, useEffect, useRef} from "react";
import {MeetingContext} from "../../Povider";

const VideoTile = ({chimeAttendeeId, userId, videoTileState}:any) => {

    const videoElementRef:any = useRef(null);
    const meetingContext:any = useContext(MeetingContext);

    const updateVideoTile = useCallback(() => {
        console.log({videoTileState}, "videoTileState create", videoElementRef?.current?.parentElement);
        if (videoTileState?.tileId && videoElementRef?.current) {
            meetingContext.bindVideoTile({
                tileId: videoTileState.tileId,
                videoElement: videoElementRef.current,
            })
        } else {
            // videoT
        }
    }, [videoTileState?.tileId]);

    useEffect(() => {
        updateVideoTile();
    }, [updateVideoTile]);

    return <>
        <div style={{width: "300px", height: "180px", border: "1px solid #000A", borderRadius: "6px"}}>
            <video ref={videoElementRef} autoPlay={true} playsInline={true} muted={false} style={{
                width: "100%",
                height: "100%",
                objectFit: "cover",
                borderRadius: "inherit",
                display: videoTileState?.tileId ? "block" : "none"
            }}
            />
            {
                videoTileState?.tileId ? <></> : <>
                {
                    userId
                }
                </>
            }
        </div>
    </>
};

export default VideoTile;