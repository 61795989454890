import React, { useEffect, useRef, useState } from "react";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction";
import listPlugin from "@fullcalendar/list";
import { EventContentArg } from "@fullcalendar/core"; // Correct import for EventApi
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Button,
  Box,
  Stack,
  IconButton,
  Typography,
} from "@mui/material";
import "./customStyles.css";
import { AllDirArrowsIcon } from "../../../../../../images";
import { TodoMenuDropdown } from "../../../../../common";
import { formatDate } from "../../../../../../Utils";

// Define types for event data and task data
interface Event {
  id: string;
  title: string;
  start: string;
  end?: string;
  extendedProps?: any;
}
interface EventBoxProps {
  event: EventContentArg; // Use EventContentArg that includes both the event and its rendering properties
  borderColor: string;
}

interface Task {
  _id: string;
  name: string;
  dueDate: string;
  status: {
    customColorHex: string;
  };
  [key: string]: any;
}

const EventBox: React.FC<EventBoxProps> = ({ event, borderColor }) => (
  <Box
    sx={{
      backgroundColor: "#b5005b85",
      "&:hover": {
        backgroundColor: "#b5005bd5",
        cursor: "pointer",
      },
      padding: "4px",
      borderRadius: "4px",
      color: "#fff",
      border: `1px solid ${borderColor}`,
      width: "100%",
    }}
  >
    <span>{event.event.title}</span>
    {/* {event.timeText && <span> {event?.timeText}</span>} */}
  </Box>
);
const EventRender: React.FC<{ eventInfo: EventContentArg }> = ({
  eventInfo,
}) => {
  const borderColor = eventInfo.view.type.includes("Month") ? "red" : "blue"; // Customize based on view type
  return <EventBox event={eventInfo} borderColor={borderColor} />;
};

const TodayButton: React.FC<{ calendarRef: React.RefObject<FullCalendar> }> = ({
  calendarRef,
}) => (
  <Button
    className="today-cus"
    onClick={() => calendarRef.current?.getApi().today()}
  >
    Today
  </Button>
);

const MonthNavigationButtons: React.FC<{
  calendarRef: React.RefObject<FullCalendar>;
}> = ({ calendarRef }) => (
  <div>
    <IconButton onClick={() => calendarRef.current?.getApi().prev()}>
      <AllDirArrowsIcon direction="left" />
    </IconButton>
    <IconButton onClick={() => calendarRef.current?.getApi().next()}>
      <AllDirArrowsIcon direction="right" />
    </IconButton>
  </div>
);

const CurrentDateComponent: React.FC = () => {
  const currentDate = new Date().toISOString().split("T")[0];
  const formattedDate = formatDate(currentDate);

  return (
    <Typography
      sx={{
        fontFamily: "Source Serif Pro",
        fontWeight: "300",
        fontSize: "15px",
        lineHeight: "18.8px",
      }}
    >
      {formattedDate}
    </Typography>
  );
};

const CustomDayMonthWeekList: React.FC<{
  calendarRef: React.RefObject<FullCalendar>;
}> = ({ calendarRef }) => {
  const [currentView, setCurrentView] = useState<string>("dayGridMonth");

  const handleViewChange = (view: string) => {
    calendarRef.current?.getApi().changeView(view);
    setCurrentView(view);
  };

  return (
    <div>
      {["timeGridDay", "timeGridWeek", "dayGridMonth", "listMonth"].map(
        (view) => (
          <Button
            key={view}
            onClick={() => handleViewChange(view)}
            style={{
              fontWeight: currentView === view ? "bold" : "normal",
              fontFamily: "Source Serif Pro",
              fontSize: "15px",
              textTransform: "none",
              color: "black",
            }}
          >
            {view === "timeGridDay"
              ? "Day"
              : view === "timeGridWeek"
              ? "Week"
              : view === "dayGridMonth"
              ? "Month"
              : "List"}
          </Button>
        )
      )}
    </div>
  );
};

const CalendarOverview: React.FC<{
  taskWorkspaceItemData: Task[];
  workspaceItemData?: any;
}> = ({ taskWorkspaceItemData, workspaceItemData }) => {
  const [events, setEvents] = useState<Event[]>([]);

  useEffect(() => {
    // Flatten the nested task arrays and map to calendar event objects
    const newEvents = taskWorkspaceItemData.flatMap((group) =>
      group.tasks.map((task: any) => ({
        id: task?._id,
        title: task?.name,
        start: task?.dueDate,
        end: task?.dueDate, // Assuming end date is the same as start date; adjust if needed
        color: task?.status?.customColorHex, // Optional: Use custom color based on task status
        extendedProps: {
          status: task?.status?.value,
          priority: task?.priority?.value,
          assignedUsers: task?.assignedUsers,
          comments: task?.comments,
          details: task?.details,
        },
      }))
    );

    setEvents(newEvents);
    // console.log("Updated events:", newEvents);
  }, [taskWorkspaceItemData]); // Re-run when task data changes

  const [openDialog, setOpenDialog] = useState(false);
  const [selectedDate, setSelectedDate] = useState("");
  const [eventTitle, setEventTitle] = useState("");
  const [selectedEvent, setSelectedEvent] = useState<Event | null>(null);
  const calendarRef = useRef<FullCalendar>(null);

  const handleDateClick = (arg: any) => {
    setSelectedDate(arg.dateStr);
    setSelectedEvent(null);
    // setOpenDialog(true);
  };

  const handleEventClick = (arg: any) => {
    setSelectedEvent(arg.event);
    setEventTitle(arg.event.title);
    // setOpenDialog(true);
  };

  const handleEventSubmit = () => {
    if (eventTitle?.trim()) {
      const newEvents = selectedEvent
        ? events?.map((event) =>
            event?.id === selectedEvent?.id
              ? { ...event, title: eventTitle }
              : event
          )
        : [
            ...events,
            {
              id: `event-${events?.length + 1}`,
              title: eventTitle,
              start: selectedDate,
              end: selectedDate,
              color: "#91f8a3",
            },
          ];
      setEvents(newEvents);
      setOpenDialog(false);
      setEventTitle("");
    }
  };

  return (
    <div>
      <Stack direction="row" alignItems="center" gap={1}>
        <TodayButton calendarRef={calendarRef} />
        <MonthNavigationButtons calendarRef={calendarRef} />
        <CurrentDateComponent />
        <CustomDayMonthWeekList calendarRef={calendarRef} />
        <TodoMenuDropdown />
      </Stack>

      <FullCalendar
        ref={calendarRef}
        plugins={[dayGridPlugin, timeGridPlugin, listPlugin, interactionPlugin]}
        initialView="dayGridMonth"
        headerToolbar={false}
        events={events}
        dateClick={handleDateClick}
        eventClick={handleEventClick}
        eventContent={(eventInfo) => <EventRender eventInfo={eventInfo} />}
        editable
        selectable
        eventDrop={(info) =>
          setEvents(
            events.map((event) =>
              event.id === info.event.id
                ? {
                    ...event,
                    start: info.event.startStr,
                    end: info.event.endStr,
                  }
                : event
            )
          )
        }
        eventResize={(info) =>
          setEvents(
            events.map((event) =>
              event.id === info.event.id
                ? { ...event, end: info.event.endStr }
                : event
            )
          )
        }
      />

      <Dialog open={openDialog} onClose={() => setOpenDialog(false)}>
        <DialogTitle sx={{ font: "normal normal 18px/21px Source Serif Pro" }}>
          {selectedEvent ? "Edit Event" : "Create Event"}
        </DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            label="Event Title"
            type="text"
            fullWidth
            sx={{ font: "normal normal 18px/21px Source Serif Pro" }}
            variant="outlined"
            value={eventTitle}
            onChange={(e) => setEventTitle(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button
            sx={{
              font: "normal normal 14px/17px Source Serif Pro",
              color: "red",
              textTransform: "capitalize",
            }}
            onClick={() => setOpenDialog(false)}
            color="primary"
          >
            Cancel
          </Button>
          <Button
            sx={{
              font: "normal normal 14px/17px Source Serif Pro",
              color: "green",
              textTransform: "capitalize",
            }}
            onClick={handleEventSubmit}
            color="primary"
          >
            Save
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default CalendarOverview;
