import { Box, Button, Stack } from "@mui/material";
import React, { useState } from "react";
import EventIcon from "@mui/icons-material/Event"; // Events
import ShareIcon from "@mui/icons-material/Share"; // Social Media
import {
  ClosureIcon,
  DateIcon,
  InboxIcon,
  InvoiceBillIcon,
  MarketingIcon,
  ReplyIcon,
} from "../../../../../../../images";

type InboxCategory = {
  name: string;
  count: number;
  icon: React.ReactNode; // Add icon type
};

const SmartFilter = () => {
  // Dummy state for inbox categories
  const [inboxCategories] = useState<InboxCategory[]>([
    { name: "Main Inbox", count: 1100, icon: <InboxIcon w={18} /> },
    { name: "No replies", count: 0, icon: <ReplyIcon w={18} /> },
    { name: "Bills", count: 2, icon: <InvoiceBillIcon w={18} /> },
    { name: "Newsletters", count: 2, icon: <ClosureIcon w={18} /> },
    { name: "Events", count: 2, icon: <DateIcon w={18} /> },
    { name: "Social Media", count: 2, icon: <MarketingIcon w={18} /> },
  ]);

  return (
    <Box>
      <Stack gap={'5px'}>
        {inboxCategories?.map((category, index) => (
          <Stack direction="row" alignItems="center" key={index}>
            <Button
              sx={{
                backgroundColor: "none",
                textTransform: "capitalize",
                font: "normal normal 300 13px/17px Source Serif Pro",
                display: "flex",
                alignItems: "center",
                width: "100%",
                justifyContent: "space-between",
                color: "black",
              }}
            >
              <Stack direction="row" alignItems="center">
                {category.icon}
                <span style={{ marginLeft: "8px" }}>{category.name}</span>
              </Stack>
              <span style={{color:"blue"}}>({category.count})</span>
            </Button>
          </Stack>
        ))}
      </Stack>
    </Box>
  );
};

export default SmartFilter;
