import React, { useEffect } from "react";
import { UpdateCardLong } from "../components/UpdateCardLong";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../redux/store";
import { AuthState } from "../../../../redux";
import { MessagesState } from "../../../../redux/Slice/message/messageTypes";
import { getMessagesActivities } from "../../../../redux/Slice/message/messageActions";
import { Typography } from "@mui/material";

const SnoozeActivityTab= () => {

  const dispatch = useDispatch();
  const { user } = useSelector((state: RootState) => state.auth as AuthState);
  const { messageActivity } = useSelector(
    (state: RootState) => state.message as MessagesState
  );

  useEffect(() => {
    if (user) {
      dispatch(getMessagesActivities(user?.data?._id, "SNOOZED"));
    }
  }, [user]);

  return (
    <div>
      {messageActivity.length ? messageActivity?.map((notification: any) => (
        <UpdateCardLong
        tag={
          notification?.messageType === "MENTION"
            ? notification.messageType
            : "CHAT"
        }
        userOrProjectName={
          notification?.channelType === "CHANNEL"
            ? notification.channelName
            : notification.otherParticipantName
        }
        description={notification?.messageContent || ""}
        activeAction={false}
        channelId={notification.channelId}
        />
      )) : <Typography style={{textAlign:"center"}}>no data found</Typography>}
    </div>
  );
};

export default SnoozeActivityTab;
