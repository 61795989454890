/**
 * This file contains action creators for workspace related actions.
 */



import { createApiAction } from "../constantApi_config";

import { getAllEmails__RequestAction, getAllEmails__FailureAction, getAllEmails__SuccessAction, deleteEmail__RequestAction, deleteEmail__SuccessAction, deleteEmail__FailureAction } from "./emailSlice";

/* getAllEmails__api */
/* Done */
export const getAllEmails__api = (accessToken: string) =>
    createApiAction({
        axiosRequired: true,
        method: "POST",
        url: "/email/emails",
        data: { accessToken },
        onStart: getAllEmails__RequestAction.type,
        onSuccess: getAllEmails__SuccessAction.type,
        onError: getAllEmails__FailureAction.type,
    });

export const DeleteEmail__api = (accessToken: string, messageId: string) =>
    createApiAction({
        axiosRequired: true,
        method: "DELETE",
        url: "/email/move-email",
        data: { accessToken, messageId },
        onStart: deleteEmail__RequestAction.type,
        onSuccess: deleteEmail__SuccessAction.type,
        onError: deleteEmail__FailureAction.type,
    });

