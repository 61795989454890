import { Stack } from "@mui/material";
import React, { lazy, Suspense, useState } from "react";
import {
  a11yProps,
  StyledTab,
  TabPanel,
  TabsWithState,
} from "../../../../common/CardTabsWithoutIcon";
import { InfoView } from "./view";

interface ActivityEmailSidebarProps {}

const LazyDetailsTab = lazy(() => import("./tabs/DetailsTab/DetailsTab"));
const LazyActivityTab = lazy(() => import("./tabs/ActivityTab/ActivityTab"));
const LazyConnectedTab = lazy(() => import("./tabs/ConnectedTab/ConnectedTab"));

const tabs: {
  label: string;
  content: React.ReactNode;
}[] = [
  {
    label: "Details",
    content: <LazyDetailsTab />,
  },
  {
    label: "Activity",
    content: <LazyActivityTab />,
  },
  {
    label: "Connected",
    content: <LazyConnectedTab />,
  },
];
const ActivityEmailSidebar: React.FC<ActivityEmailSidebarProps> = ({}) => {
  const [tabValue, setTabValue] = useState(0);
  return (
    <Stack
      sx={{
        bgcolor: "white",
        width: "245px",
        height: "100%",
        gap: "10px",
        padding: "0px 0px 0px 13px",
        borderLeft: "1px solid #E9EDF2",
      }}
    >
      <InfoView
        userName="User Name"
        userEmail="abc@mail.com"
        avatarColor="green"
      />
      <TabsWithState
        ariaLabel="Email details tabs"
        value={tabValue}
        onChange={(event, newValue) => setTabValue(newValue)}
        tabIndicatorColor="#008D4C"
      >
        {tabs.map((tab, index) => (
          <StyledTab
            style={{
              font: "normal normal 600 13px/16px Source Serif Pro",
            }}
            key={index}
            disableRipple
            label={tab.label}
            {...a11yProps(index)}
          />
        ))}
      </TabsWithState>

      {tabs.map((tab, index) => (
        <TabPanel
          style={{ display: "contents" }}
          key={index}
          value={tabValue}
          index={index}
        >
          <Suspense fallback={<div>Loading...</div>}>{tab.content}</Suspense>
        </TabPanel>
      ))}
    </Stack>
  );
};

export default ActivityEmailSidebar;
