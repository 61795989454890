import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { CloudStorageState, DetailsSliderProps } from "./CloudStorageType";
import {
    /* Upload File to Cloud */
    uploadCloudFile__Failure,
    uploadCloudFile__Request,
    uploadCloudFile__Success,

    /* Get All Files */
    getAllFiles__Request,
    getAllFiles__Success,
    getAllFiles__Failure,

    /* Get One File */
    getOneFile__Request,
    getOneFile__Success,
    getOneFile__Failure,

    /* Download File */
    downloadFile__Request,
    downloadFile__Success,
    downloadFile__Failure,

    /* Share File With Users */
    shareFileWithUsers__Request,
    shareFileWithUsers__Success,
    shareFileWithUsers__Failure,

    /* Share File With Email */
    shareFileWithEmail__Request,
    shareFileWithEmail__Success,
    shareFileWithEmail__Failure,

    /* Delete File */
    deleteFile__Request,
    deleteFile__Success,
    deleteFile__Failure,

    /* Update File */
    updateFile__Request,
    updateFile__Success,
    updateFile__Failure,

    /* Upload New Version */
    uploadNewVersion__Request,
    uploadNewVersion__Success,
    uploadNewVersion__Failure,

    /* Create Folder */
    createFolder__Request,
    createFolder__Success,
    createFolder__Failure,

    /* Rename Folder */
    renameFolder__Request,
    renameFolder__Success,
    renameFolder__Failure,

    /* Get All Folders */
    getAllFolders__Request,
    getAllFolders__Success,
    getAllFolders__Failure,

    /* Get all file Member */
    getAllMemberCS__Request,
    getAllMemberCS__Success,
    getAllMemberCS__Failure,

    /* Create Link */
    createLink__Request,
    createLink__Success,
    createLink__Failure,

    /* Get Link */
    getLink__Request,
    getLink__Success,
    getLink__Failure,

    /* Delete Link */
    deleteLink__Request,
    deleteLink__Success,
    deleteLink__Failure,

    /* Get All Link */
    getAllLink__Request,
    getAllLink__Success,
    getAllLink__Failure,

    /* Add View */
    addView__Request,
    addView__Success,
    addView__Failure,

    /* Add Time */
    addTime__Request,
    addTime__Success,
    addTime__Failure,

    /* Toggle Download */
    toggleDownload__Request,
    toggleDownload__Success,
    toggleDownload__Failure,

    /* Toggle Ask Details */
    toggleAskDetails__Request,
    toggleAskDetails__Success,
    toggleAskDetails__Failure,

    /* Update Expiration */
    updateExpiration__Request,
    updateExpiration__Success,
    updateExpiration__Failure,

    /* Update Link Password */
    updateLinkPassword__Request,
    updateLinkPassword__Success,
    updateLinkPassword__Failure,

    /* Toggle Watermark */
    toggleWatermark__Request,
    toggleWatermark__Success,
    toggleWatermark__Failure,
    /* And so on... */
} from "./CloudStorageSliceAction";

const initialState: CloudStorageState = {
    error: null,
    data: null,
    refreshFiles: true,
    selectedDrive: localStorage.getItem("selectedDrive") || "BearishOS", // Default to 'BearishOS' if no saved selection
    bearishOSCloudStorage: [],
    bearishOSCloudStorageFolders: [],
    availableDrives: [
        {
            id: "bearishos-001",
            name: "Bearish OS",
            type: "BearishOS",
            connected: true,
        },
        // {
        //     id: "gdrive-001",
        //     name: "Google Drive",
        //     type: "GoogleDrive",
        //     connected: true,
        // },
        // { id: "dropbox-001", name: "Dropbox", type: "Dropbox", connected: false },
        // {
        //     id: "OneDrive-001",
        //     name: "One Drive",
        //     type: "OneDrive",
        //     connected: false,
        // },
        // { id: "Box-001", name: "Box", type: "Box", connected: false },
    ],
};

const cloudStorage = createSlice({
    name: "cloudStorage",
    initialState,
    reducers: {
        /* Upload file to Cloud */
        uploadCloudFile__Request,
        uploadCloudFile__Failure,
        uploadCloudFile__Success,

        /* Get All Files */
        getAllFiles__Request,
        getAllFiles__Success,
        getAllFiles__Failure,

        /* Get One File */
        getOneFile__Request,
        getOneFile__Success,
        getOneFile__Failure,

        /* Download File */
        downloadFile__Request,
        downloadFile__Success,
        downloadFile__Failure,

        /* Share File With Users */
        shareFileWithUsers__Request,
        shareFileWithUsers__Success,
        shareFileWithUsers__Failure,

        /* Share File With Email */
        shareFileWithEmail__Request,
        shareFileWithEmail__Success,
        shareFileWithEmail__Failure,

        /* Delete File */
        deleteFile__Request,
        deleteFile__Success,
        deleteFile__Failure,

        /* Update File */
        updateFile__Request,
        updateFile__Success,
        updateFile__Failure,

        /* Upload New Version */
        uploadNewVersion__Request,
        uploadNewVersion__Success,
        uploadNewVersion__Failure,

        /* Create Folder */
        createFolder__Request,
        createFolder__Success,
        createFolder__Failure,

        /* Rename Folder */
        renameFolder__Request,
        renameFolder__Success,
        renameFolder__Failure,

        /* Get All Folders */
        getAllFolders__Request,
        getAllFolders__Success,
        getAllFolders__Failure,

        /* Get All Members */
        getAllMemberCS__Request,
        getAllMemberCS__Success,
        getAllMemberCS__Failure,

        /* Create Link */
        createLink__Request,
        createLink__Success,
        createLink__Failure,

        /* Get Link */
        getLink__Request,
        getLink__Success,
        getLink__Failure,

        /* Delete Link */
        deleteLink__Request,
        deleteLink__Success,
        deleteLink__Failure,

        /* Get All Link */
        getAllLink__Request,
        getAllLink__Success,
        getAllLink__Failure,

        /* Add View */
        addView__Request,
        addView__Success,
        addView__Failure,

        /* Add Time */
        addTime__Request,
        addTime__Success,
        addTime__Failure,

        /* Toggle Download */
        toggleDownload__Request,
        toggleDownload__Success,
        toggleDownload__Failure,

        /* Toggle Ask Details */
        toggleAskDetails__Request,
        toggleAskDetails__Success,
        toggleAskDetails__Failure,

        /* Update Expiration */
        updateExpiration__Request,
        updateExpiration__Success,
        updateExpiration__Failure,

        /* Update Link Password */
        updateLinkPassword__Request,
        updateLinkPassword__Success,
        updateLinkPassword__Failure,

        /* Toggle Watermark */
        toggleWatermark__Request,
        toggleWatermark__Success,
        toggleWatermark__Failure,

        setSelectedDrive: (state, { payload }: PayloadAction<string>) => {
            if (state.availableDrives.some((drive) => drive.name === payload)) {
                state.selectedDrive = payload;
                localStorage.setItem("selectedDrive", payload); // Save to localStorage
            } else {
                state.error = `Drive "${payload}" is not connected.`;
            }
        },

        toggleRefreshFiles: (state, { payload }: PayloadAction<boolean>) => {
            state.refreshFiles = payload; // Update the state with the payload value
        },

        getDetailsSliderState: (
            state,
            { payload }: PayloadAction<DetailsSliderProps>
        ) => {
            if (payload) {
                state.detailsSlider = payload; // Update the state with the payload value
            } else {
                state.detailsSlider = null;
            }
        },

        getDetailsSliderLinkDataState: (
            state,
            { payload }: PayloadAction<DetailsSliderProps>
        ) => {
            if (payload) {
                state.detailsSidebarLinkData = payload; // Update the state with the payload value
            } else {
                state.detailsSidebarLinkData = null;
            }
        },

        selectedFile: (state, { payload }: PayloadAction<any>) => {
            if (payload) {
                state.selectedFileData = payload; // Update the state with the payload value
            } else {
                state.selectedFileData = null; // Update the state with the payload value
            }
        },
    },
});

export const {
    setSelectedDrive,
    toggleRefreshFiles,
    selectedFile,
    getDetailsSliderState,
    getDetailsSliderLinkDataState,

    /* Upload file to Cloud */
    uploadCloudFile__Request: uploadCloudFile__RequestAction,
    uploadCloudFile__Failure: uploadCloudFile__FailureAction,
    uploadCloudFile__Success: uploadCloudFile__SuccessAction,

    /* Get All Files */
    getAllFiles__Request: getAllFiles__RequestAction,
    getAllFiles__Failure: getAllFiles__FailureAction,
    getAllFiles__Success: getAllFiles__SuccessAction,

    /* Get One File */
    getOneFile__Request: getOneFile__RequestAction,
    getOneFile__Failure: getOneFile__FailureAction,
    getOneFile__Success: getOneFile__SuccessAction,

    /* Download File */
    downloadFile__Request: downloadFile__RequestAction,
    downloadFile__Failure: downloadFile__FailureAction,
    downloadFile__Success: downloadFile__SuccessAction,

    /* Share File With Users */
    shareFileWithUsers__Request: shareFileWithUsers__RequestAction,
    shareFileWithUsers__Failure: shareFileWithUsers__FailureAction,
    shareFileWithUsers__Success: shareFileWithUsers__SuccessAction,

    /* Share File With Email */
    shareFileWithEmail__Request: shareFileWithEmail__RequestAction,
    shareFileWithEmail__Failure: shareFileWithEmail__FailureAction,
    shareFileWithEmail__Success: shareFileWithEmail__SuccessAction,

    /* Delete File */
    deleteFile__Request: deleteFile__RequestAction,
    deleteFile__Failure: deleteFile__FailureAction,
    deleteFile__Success: deleteFile__SuccessAction,

    /* Update File */
    updateFile__Request: updateFile__RequestAction,
    updateFile__Failure: updateFile__FailureAction,
    updateFile__Success: updateFile__SuccessAction,

    /* Upload New Version */
    uploadNewVersion__Request: uploadNewVersion__RequestAction,
    uploadNewVersion__Failure: uploadNewVersion__FailureAction,
    uploadNewVersion__Success: uploadNewVersion__SuccessAction,

    /* Create Folder */
    createFolder__Request: createFolder__RequestAction,
    createFolder__Failure: createFolder__FailureAction,
    createFolder__Success: createFolder__SuccessAction,

    /* Rename Folder */
    renameFolder__Request: renameFolder__RequestAction,
    renameFolder__Failure: renameFolder__FailureAction,
    renameFolder__Success: renameFolder__SuccessAction,

    /* Get All Folders */
    getAllFolders__Request: getAllFolders__RequestAction,
    getAllFolders__Failure: getAllFolders__FailureAction,
    getAllFolders__Success: getAllFolders__SuccessAction,

    /* Get All Members */
    getAllMemberCS__Request: getAllMemberCS__RequestAction,
    getAllMemberCS__Success: getAllMemberCS__SuccessAction,
    getAllMemberCS__Failure: getAllMemberCS__FailureAction,

    /* Create Link */
    createLink__Request: createLink__RequestAction,
    createLink__Success: createLink__SuccessAction,
    createLink__Failure: createLink__FailureAction,

    /* Get Link */
    getLink__Request: getLink__RequestAction,
    getLink__Success: getLink__SuccessAction,
    getLink__Failure: getLink__FailureAction,

    /* Delete Link */
    deleteLink__Request: deleteLink__RequestAction,
    deleteLink__Success: deleteLink__SuccessAction,
    deleteLink__Failure: deleteLink__FailureAction,

    /* Get All Link */
    getAllLink__Request: getAllLink__RequestAction,
    getAllLink__Success: getAllLink__SuccessAction,
    getAllLink__Failure: getAllLink__FailureAction,

    /* Add View */
    addView__Request: addView__RequestAction,
    addView__Success: addView__SuccessAction,
    addView__Failure: addView__FailureAction,

    /* Add Time */
    addTime__Request: addTime__RequestAction,
    addTime__Success: addTime__SuccessAction,
    addTime__Failure: addTime__FailureAction,

    /* Toggle Download */
    toggleDownload__Request: toggleDownload__RequestAction,
    toggleDownload__Success: toggleDownload__SuccessAction,
    toggleDownload__Failure: toggleDownload__FailureAction,

    /* Toggle Ask Details */
    toggleAskDetails__Request: toggleAskDetails__RequestAction,
    toggleAskDetails__Success: toggleAskDetails__SuccessAction,
    toggleAskDetails__Failure: toggleAskDetails__FailureAction,

    /* Update Expiration */
    updateExpiration__Request: updateExpiration__RequestAction,
    updateExpiration__Success: updateExpiration__SuccessAction,
    updateExpiration__Failure: updateExpiration__FailureAction,

    /* Update Link Password */
    updateLinkPassword__Request: updateLinkPassword__RequestAction,
    updateLinkPassword__Success: updateLinkPassword__SuccessAction,
    updateLinkPassword__Failure: updateLinkPassword__FailureAction,

    /* Toggle Watermark */
    toggleWatermark__Request: toggleWatermark__RequestAction,
    toggleWatermark__Success: toggleWatermark__SuccessAction,
    toggleWatermark__Failure: toggleWatermark__FailureAction,
    /* And So On... */
} = cloudStorage.actions;

export default cloudStorage.reducer;
