import { Stack, Typography } from "@mui/material";
import React from "react";
import { ArchiveIcon } from "../../../../../images";
import { ButtonSmall, ButtonType, SmallButtonColor } from "../../../../common";

interface ArchiveSuccessProps {}

const ArchiveSuccess: React.FC<ArchiveSuccessProps> = ({}) => {
  return (
    <Stack
      sx={{
        width: "250px",
        bgcolor: "white",
        borderRadius: "5px",
        boxShadow: "0px 3px 3px 0px rgba(0, 0, 0, 0.15);",
        padding: "15px",
        boxSizing: "border-box",
        gap: "10px",
      }}
    >
      <Stack sx={{ flexDirection: "row", gap: "5px" }}>
        <ArchiveIcon w={15} />
        <Typography
          sx={{ font: "normal normal 400 14px/18px Source Serif Pro" }}
        >
          Success
        </Typography>
      </Stack>

      <Typography
        sx={{
          font: "normal italic 300 14px/18px Source Serif Pro",
          letterSpacing: "-0.05em",
        }}
      >
        Your email has been archived. You can view it in the archive section
        below.
      </Typography>
      <Stack sx={{ flexDirection: "row", justifyContent: "center" }}>
        <ButtonSmall
          label="Done"
          types={ButtonType.Button}
          colorVarient={SmallButtonColor.Blue}
        />
      </Stack>
    </Stack>
  );
};

export default ArchiveSuccess;
