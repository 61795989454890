// AnonymousComponent.tsx
import React from "react";
import { useParams } from "react-router-dom";
import { CloudStorageRoute, WorkspaceRoute } from "./components";

const AnonymousComponent: React.FC = () => {
  const params = useParams<{
    workspaceId?: string;
    componentId?: string;
    fileType?: string;
    fileId?: string;
    sharedType?: string;
    SharedfileId?: string;
  }>();

  if (params?.workspaceId) {
    return (
      <WorkspaceRoute
        workspaceId={params?.workspaceId}
        itemId={params?.componentId}
        sharedType={params?.sharedType}
      />
    );
  } else if (params?.fileType) {
    return (
      <CloudStorageRoute
        folderId={params?.fileType}
        SharedfileId={params?.SharedfileId}
        fileId={params?.fileId}
      />
    );
  }

  return null;
};

export default AnonymousComponent;
