import React, { useEffect } from "react";
import { UpdateCardLong } from "../components/UpdateCardLong";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../redux/store";
import { AuthState } from "../../../../redux";
import {
  changeMessagesActivitiesStatus,
  getMessagesActivities,
  setSnoozeMessagesActivities,
} from "../../../../redux/Slice/message/messageActions";
import { MessagesState } from "../../../../redux/Slice/message/messageTypes";
import { removeMessageActivityAction } from "../../../../redux/Slice/message/messageSlice";
import { Typography } from "@mui/material";

const NewUpdateActivityTab = () => {
  const dispatch = useDispatch();
  const { user } = useSelector((state: RootState) => state.auth as AuthState);
  const { messageActivity } = useSelector(
    (state: RootState) => state.message as MessagesState
  );

  useEffect(() => {
    if (user) {
      dispatch(getMessagesActivities(user?.data?._id, "NEW"));
    }
  }, [user]);

  const handleDoneMessage = async (notificationId: any) => {
    if (notificationId) {
      const payload = {
        activityId: notificationId,
        userId: user?.data?._id,
        status: "DONE",
      };
      await dispatch(changeMessagesActivitiesStatus(payload));
      await dispatch(removeMessageActivityAction(notificationId));
    }
  };

  const convertToMinutes = (duration:string) => {
    const regex = /(\d+)\s*(mins?|hours?)/i; 
    const match = duration.match(regex);
  
    if (match) {
      const value = parseInt(match[1], 10); 
      const unit = match[2].toLowerCase(); 
  
      if (unit.startsWith('h')) { 
        return value * 60; 
      } else if (unit.startsWith('m')) { 
        return value; 
      }
    }
  }
  const handleSnoozeMessage = async (value: any, notificationId: any) => {

    if (value || notificationId) {

      const minutes = convertToMinutes(value);
      const payload = {
        activityId: notificationId,
        userId: user?.data?._id,
        status: "SNOOZED",
        snoozeDuration: minutes,
      };
      await dispatch(setSnoozeMessagesActivities(payload));
      await dispatch(removeMessageActivityAction(notificationId));
    }
  };

  return (
    <div>
      { messageActivity.length > 0 ? messageActivity?.map((notification: any, index: any) => (
        <UpdateCardLong
          key={index}
          onDoneButtonClick={(value: any) =>
            handleDoneMessage(notification._id)
          }
          onSnoozeButtonClick={(value: any) =>
            handleSnoozeMessage(value, notification._id)
          }
          tag={notification?.messageType === "MENTION" ? notification.messageType   :"CHAT" }
          userOrProjectName={
            notification?.channelType === "CHANNEL"
              ? notification.channelName
              : notification.otherParticipantName
          }
          description={notification?.messageContent || ""}
          activeAction={true}
          channelId={notification.channelId}
        />
      )) : <Typography style={{textAlign:"center"}}>no data found</Typography>}
    </div>
  );
};

export default NewUpdateActivityTab;
