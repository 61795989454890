import { useState } from "react";
import {
  IconButton,
  InputBase,
  Stack,
  Tooltip,
  Typography,
} from "@mui/material";
import { BearishOSIcon } from "../../../images";
import { ButtonSmall } from "../Buttons";
import { ButtonType, SmallButtonColor } from "../Buttons/AllButtonProps";
import { adjustHexColor } from "../../../Utils";
import styles from "./PublicPagePopup.module.css";

interface PublicPagePopupProps {
  onClose: () => void; // To handle fade-out and closing the popup
}

const PublicPagePopup: React.FC<PublicPagePopupProps> = ({ onClose }) => {
  const [guestPermission, setGuestPermission] = useState(false);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");

  const handleConfirm = () => {
    // Validate the input fields
    if (!firstName || !lastName || !email) {
      alert("Please fill out all fields.");
      return;
    }

    // Store guest details in local storage
    const guestDetails = {
      firstName,
      lastName,
      email,
    };

    localStorage.setItem("shareIdGuest_basic", JSON.stringify(guestDetails));

    // Hide the popup by setting guestPermission to false
    setGuestPermission(false);
    onClose(); // Trigger fade-out or close effect
  };

  return (
    <Stack className={styles["public-page-popup"]}>
      <Stack className={styles["public-page-popup__header"]}>
        <BearishOSIcon w={30} />
        <Typography className={styles["public-page-popup__title"]}>
          Bearish OS
        </Typography>
      </Stack>

      <Typography className={styles["public-page-popup__description"]}>
        Welcome! To view this item please fill out the form below.
      </Typography>

      <Stack className={styles["public-page-popup__form-row"]}>
        <Stack className={styles["public-page-popup__input-wrapper"]}>
          <Typography className={styles["public-page-popup__input-label"]}>
            First name
          </Typography>
          <InputBase
            className={styles["public-page-popup__input-field"]}
            value={firstName}
            onChange={(e) => setFirstName(e.target.value)}
          />
        </Stack>
        <Stack className={styles["public-page-popup__input-wrapper"]}>
          <Typography className={styles["public-page-popup__input-label"]}>
            Last name
          </Typography>
          <InputBase
            className={styles["public-page-popup__input-field"]}
            value={lastName}
            onChange={(e) => setLastName(e.target.value)}
          />
        </Stack>
      </Stack>
      <Stack className={styles["public-page-popup__input-wrapper"]}>
        <Typography className={styles["public-page-popup__input-label"]}>
          Email address
        </Typography>
        <InputBase
          className={styles["public-page-popup__input-field"]}
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />
      </Stack>
      <Stack className={styles["public-page-popup__button-container"]}>
        <ButtonSmall
          label="Confirm"
          types={ButtonType.Button}
          colorVarient={SmallButtonColor.Orange}
          onClick={() => handleConfirm()} // Handle form submission
        />
      </Stack>

      <Tooltip
        placement="top"
        arrow
        disableHoverListener={guestPermission}
        title={
          <Typography className={styles["public-page-popup__tooltip-text"]}>
            The owner of this item doesn't allow anonymous viewing.
          </Typography>
        }
      >
        <IconButton
          disableRipple
          className={styles["public-page-popup__icon-button"]}
        >
          <Typography
            className={styles["public-page-popup__guest-text"]}
            sx={{
              color: guestPermission
                ? "#A53A01"
                : adjustHexColor("#A53A01", 500),
            }}
          >
            Continue as anonymous guest
          </Typography>
        </IconButton>
      </Tooltip>
    </Stack>
  );
};

export default PublicPagePopup;
