import * as React from "react";
import {
  FormControl,
  MenuItem,
  OutlinedInput,
  Select,
  SelectChangeEvent,
  Stack,
  Typography,
} from "@mui/material";
import S from "./GrantDropDownButton.module.css"; // Updated CSS file
import { GreenDropdownIcon, HalfLockIcon } from "../../../images";
import { CheckboxMedium } from "../CheckboxMedium";

type AccessType = "granted" | "notGranted";

interface GrantDropDownButtonProps {
  width?: string;
  onChange?: (selectedOption: { access: AccessType }) => void;
  initialValue?: AccessType;
}

interface Option {
  id: number;
  title: string;
  subtitle: string;
  value: AccessType;
}

const OPTIONS: Option[] = [
  {
    id: 1,
    title: "Granted",
    subtitle:
      "This team member has full access to all communications with this record",
    value: "granted",
  },
  {
    id: 2,
    title: "Not granted",
    subtitle:
      "This team member does not have access to any communications with this record",
    value: "notGranted",
  },
];

const GrantDropDownButton: React.FC<GrantDropDownButtonProps> = React.memo(
  ({ width = "125px", onChange, initialValue, ...props }) => {
    const [selectedOption, setSelectedOption] = React.useState<
      AccessType | undefined
    >(initialValue);
    const [isOpen, setIsOpen] = React.useState(false);

    const handleChange = React.useCallback(
      (event: SelectChangeEvent<AccessType>) => {
        const {
          target: { value },
        } = event;
        setSelectedOption(value as AccessType);

        if (onChange) {
          onChange({ access: value as AccessType });
        }
      },
      [onChange]
    );

    const selectedOptionData = OPTIONS.find(
      (option) => option.value === selectedOption
    );

    const menuProps = {
      PaperProps: {
        className: S["grant-dropdown-button__menu"],
        style: { width: "250px" },
      },
      anchorOrigin: {
        vertical: "bottom" as const,
        horizontal: "right" as const,
      },
      transformOrigin: {
        vertical: "top" as const,
        horizontal: "right" as const,
      },
    };

    return (
      <div>
        <FormControl size="small" style={{ width }} {...props}>
          <Select
            open={isOpen}
            onClick={!isOpen ? () => setIsOpen(true) : () => null}
            displayEmpty
            IconComponent={() => (
              <GreenDropdownIcon
                open={!isOpen}
                sx={{ marginRight: "2px", height: "10px" }}
              />
            )}
            value={selectedOption}
            onChange={handleChange}
            input={
              <OutlinedInput
                className={S["grant-dropdown-button__inputbox"]}
                classes={{
                  notchedOutline: S["grant-dropdown-button__notchedOutline"],
                  focused: S["grant-dropdown-button__focused"],
                }}
              />
            }
            onClose={() => setIsOpen(false)}
            renderValue={(selected) => {
              if (!selected) {
                return (
                  <em
                    className={S["grant-dropdown-button__select-placeholder"]}
                  >
                    {"Select option"}
                  </em>
                );
              }

              return (
                <span className={S["grant-dropdown-button__selected"]}>
                  <HalfLockIcon w={12} />
                  <span style={{ paddingLeft: "5px" }}>
                    {selectedOptionData?.title}
                  </span>
                </span>
              );
            }}
            MenuProps={menuProps}
          >
            {OPTIONS.map((option) => (
              <MenuItem
                disableRipple
                className={S["grant-dropdown-button__menu-item"]}
                key={option.id}
                value={option.value}
                sx={{
                  "&.Mui-selected": {
                    backgroundColor: "transparent",
                    "&:hover": {
                      backgroundColor: "transparent",
                    },
                  },
                }}
              >
                <Stack
                  direction="column"
                  gap={"5px"}
                  sx={{ padding: "0px 20px 0px 10px" }}
                >
                  <Stack
                    sx={{
                      flexDirection: "row",
                      alignItems: "center",
                      justifyContent: "space-between",
                    }}
                  >
                    <Typography className={S["grant-dropdown-button__title"]}>
                      {option.title}
                    </Typography>
                    <CheckboxMedium
                      boxSize={15}
                      checked={selectedOption === option.value}
                    />
                  </Stack>
                  <Typography className={S["grant-dropdown-button__subtitle"]}>
                    {option.subtitle}
                  </Typography>
                </Stack>
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </div>
    );
  }
);

export default GrantDropDownButton;
