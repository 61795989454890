import React from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
} from "react-router-dom";
import { CommonContainer, CommonPublicContainer } from "../components/CommanLayout";
import { ROUTES } from "./index.utils";

interface RouteConfig {
  path: string;
  component: React.ComponentType;
  isPrivate: boolean;
  useCommonLayout: boolean;
}

// Helper Component for conditionally rendering private routes
const PrivateRoute: React.FC<{
  children: React.ReactElement;
  useCommonLayout: boolean;
}> = ({ children, useCommonLayout }) => {
  const token = localStorage.getItem("token");

  // Function to render the component, wrapped or unwrapped based on `useCommonLayout`.
  const renderComponent = () =>
    useCommonLayout ? <CommonContainer>{children}</CommonContainer> : children;

  // return renderComponent();
  // Return either the rendered component or a redirect based on the existence of a token.
  return token ? renderComponent() : <Navigate to="/login" replace />;
};

// Helper Component for conditionally rendering public routes
const PublicRoute: React.FC<{
  children: React.ReactElement;
  useCommonLayout: boolean;
  bothActive?: boolean;
}> = ({ children, useCommonLayout, bothActive = false }) => {
  const token = localStorage.getItem("token");
  const renderComponent = () =>
    useCommonLayout ? <CommonPublicContainer>{children}</CommonPublicContainer> : children;
  // Redirect to dashboard if token exists
  // return children;
  return (token && bothActive ? true : false) ? <Navigate to="/Project" replace /> : renderComponent();
};

const RoutesComponent: React.FC = () => {
  return (
    <Router>
      <Routes>
        {ROUTES.map((route: RouteConfig, index) => {
          const Element = route.component; // Ensures correct handling of JSX elements
          if (route.isPrivate) {
            return (
              <Route
                key={index}
                path={route.path}
                element={
                  <PrivateRoute useCommonLayout={route.useCommonLayout}>
                    <Element />
                  </PrivateRoute>
                }
              />
            );
          } else {
            return (
              <Route
                key={index}
                path={route.path}
                element={
                  <PublicRoute useCommonLayout={route.useCommonLayout}>
                    <Element />
                  </PublicRoute>
                }
              />
            );
          }
        })}
        <Route path="*" element={<Navigate to="/dashboard" replace />} />
      </Routes>
    </Router>
  );
};

export default RoutesComponent;
