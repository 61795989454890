import { Stack, Typography } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import { CheckboxMedium } from "../../../../common";
import {
  AttachmentView,
  IconsGroupView,
  ReplyButtonView,
  StarToggleView,
  TimeView,
  UserCount,
  UserInfoChip,
} from "../EmailCardComponents";
import { EmailFilterChips } from "../EmailFilterChips";
import { EmailFilterChipsVariantEnum } from "../EmailFilterChips/EmailFilterChipsTypes";
import { EmailClickedMenu } from "../EmailClickedMenu";

interface EmailCardLongProps {
  isSelected?: boolean;
  isClicked?: boolean;
  showReplyButton?: boolean;
  email: any; handleNavigate?: () => void;
}

const EmailCardLong: React.FC<EmailCardLongProps> = ({
  isClicked = false,
  isSelected = false,
  showReplyButton = false,
  email, handleNavigate
}) => {
  const [isHovered, setIsHovered] = useState(false);
  const cardRef = useRef<HTMLDivElement | null>(null);
  const menuRef = useRef<HTMLDivElement | null>(null);

  const handleClickOutside = (event: MouseEvent) => {
    if (
      cardRef.current &&
      !cardRef.current.contains(event.target as Node) &&
      menuRef.current &&
      !menuRef.current.contains(event.target as Node)
    ) {
      setIsHovered(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);
  return (
    <Stack
      ref={cardRef}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      sx={{
        height: "50px",
        bgcolor: "white",
        borderRadius: "5px",
        boxSizing: "border-box",
        width: "100%",
        flexDirection: "row",
        alignItems: "center",
        padding: "0px 15px 0px 11px",
        borderBottom: "1px solid #E9EDF2",
        gap: "10px",
        justifyContent: "space-between",
        boxShadow: isHovered ? "0px 3px 3px 0px rgba(0, 0, 0, 0.15)" : null,
        borderLeft:
          isSelected || isClicked ? "4px solid #E9EDF2" : "4px solid #fff ",
      }}
    >
      {/* left side components */}
      <Stack sx={{ flexDirection: "row", alignItems: "center", gap: "10px" }}>
        <CheckboxMedium boxSize={15} checked={isSelected} />
        {showReplyButton && <ReplyButtonView />}
        <Stack sx={{ flexDirection: "row", alignItems: "center", gap: "5px" }}>
          <StarToggleView isToogled={email?.isStarted} />
          <UserInfoChip
            popoverPlace="bottom"
            userName={email?.sender?.emailAddress?.name}
            userEmail={email?.sender?.emailAddress?.address}
            userIconSrc={`https://example.com/avatar${1}.png`}
            userAvatarColor={`#${Math.floor(Math.random() * 16777215).toString(16)}`}
          />
        </Stack>
        <UserCount userList={email?.ccRecipients} />

        <Typography
          sx={{
            font: "normal normal 400 11px/14px Source Serif Pro",
            overflow: "hidden",
            textOverflow: "ellipsis",
            whiteSpace: "nowrap",
            width: "225px",
          }} onClick={() => handleNavigate && handleNavigate()}
        >
          {email?.subject}
        </Typography>

        <Typography
          sx={{
            font: "normal normal 300 11px/14px Source Serif Pro",
            overflow: "hidden",
            textOverflow: "ellipsis",
            whiteSpace: "nowrap",
            width: "245px",
          }}
        >
          {email?.bodyText}
        </Typography>

        <Stack sx={{ flexDirection: "row", alignItems: "center", gap: "5px" }}>
          <EmailFilterChips
            variant={email?.AIFilterChip as EmailFilterChipsVariantEnum}
          />

          <AttachmentView AttachmentChipViewData={email?.attachments} />
        </Stack>
      </Stack>

      {/* right side components */}
      <Stack sx={{ flexDirection: "row", alignItems: "center", gap: "10px" }}>
        {!isHovered ? <TimeView time={email?.createdDateTime} /> : <IconsGroupView email={email} />}
        <div ref={menuRef}>
          <EmailClickedMenu />
        </div>
      </Stack>
    </Stack>
  );
};

export default EmailCardLong;
