import { Stack, Typography } from "@mui/material";
import React, { useState } from "react";
import {
  ButtonSmall,
  ButtonType,
  MediumInputIconBox,
  SmallButtonColor,
} from "../../../../common";
import {
  FromView,
  AdvancedActionsView,
  BasicFilterView,
  BodyKeywordView,
  HostView,
  PrioritylevelView,
  SubjectKeywordView,
} from "./view";

interface CreateSmartFilterProps {}

const CreateSmartFilter: React.FC<CreateSmartFilterProps> = ({}) => {
  const [nameValue, setNameValue] = useState("");

  return (
    <Stack
      sx={{
        bgcolor: "white",
        width: "325px",
        height: "100%",
        padding: "15px",
        boxSizing: "border-box",
      }}
    >
      <Stack
        sx={{
          overflowY: "scroll",
          scrollbarWidth: "none",
          gap: "10px",
        }}
      >
        <Stack sx={{ flexDirection: "row", alignItems: "center", gap: "5px" }}>
          <Typography
            sx={{ font: "normal normal 600 15px/19px Source Serif Pro" }}
          >
            Create Smart Filter
          </Typography>
          <Stack sx={{ height: "1px", flexGrow: 1, bgcolor: "#E9EDF2" }} />
        </Stack>
        <Typography
          sx={{
            font: "normal italic 300 13px/16px Source Serif Pro",
          }}
        >
          Smart filters automatically use your inputs and AI to sort all
          incoming mail to convienent locations.
        </Typography>

        {/* input area for Name */}

        <MediumInputIconBox
          label="Name"
          value={nameValue}
          sx={{ width: "100%" }}
          onChange={(e) => {
            const value = typeof e === "string" ? e : e.target.value;
            setNameValue(value);
          }}
        />
        <FromView />
        <HostView />
        <SubjectKeywordView />
        <BodyKeywordView />
        <BasicFilterView onChange={(newList) => console.log(newList)} />
        <PrioritylevelView onChange={(newList) => console.log(newList)} />
        <AdvancedActionsView onChange={(newList) => console.log(newList)} />
        <Stack
          sx={{
            flexDirection: "row",
            justifyContent: "end",
            marginTop: "10px",
          }}
        >
          <ButtonSmall
            label="Save"
            types={ButtonType.Button}
            colorVarient={SmallButtonColor.Blue}
          />
        </Stack>
      </Stack>
    </Stack>
  );
};

export default CreateSmartFilter;
