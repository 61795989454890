import { Box, IconButton, Stack, Typography } from "@mui/material";
import React from "react";
import { AvatarGrouped, SmallPinnedCard } from "../../../../common";
import {
  DoneIcon,
  SnoozeIcon,
  StreamIconBearishOSIconPng,
} from "../../../../../images";
import { UpdateCardLongTagsEnum } from "./UpdateCardLongTypes";
import styles from "./UpdateCardLong.module.css";
import { SnoozeButton } from "../SnoozeButton";
import { useNavigate } from "react-router-dom";
import { getHTMLContent } from "../../constant";

interface UpdateCardLongProps {
  userOrProjectName?: string;
  key?: any;
  tag?: string;
  description?: any;
  companyIconSrc?: string;
  onDoneButtonClick?: any;
  onSnoozeButtonClick?: any;
  activeAction?: boolean;
  channelId?:string
}

const UpdateCardLong: React.FC<UpdateCardLongProps> = ({
  key,
  tag,
  companyIconSrc = StreamIconBearishOSIconPng,
  description,
  onDoneButtonClick,
  onSnoozeButtonClick,
  userOrProjectName,
  activeAction,
  channelId
}) => {
  const cardType = tag === UpdateCardLongTagsEnum.Recap ? "card2" : "card1";
  const navigate = useNavigate();
  const tagColor = () => {
    switch (tag) {
      case UpdateCardLongTagsEnum.Chat:
        return "#007BB2";
      case UpdateCardLongTagsEnum.Flag:
        return "#FF0000";
      case UpdateCardLongTagsEnum.Mention:
        return "#775EE2";
      case UpdateCardLongTagsEnum.Recap:
        return "#007BB2";
      case UpdateCardLongTagsEnum.Task:
        return "#007BB2";
      default:
        return "#000";
    }
  };

  const handleNavigate = () => {
    console.log('channelId: ', channelId);
    if(channelId) {
      navigate(`/Message/${channelId}`);
    }
  };

  return (
    <Stack
      key={key}
      style={{cursor: 'pointer'}}
      className={
        cardType === "card1"
          ? styles["UpdateCardLong__container--card1"]
          : styles["UpdateCardLong__container--card2"]
      }
    >
      {/* User icon with online/offline-status and name here */}
      <Stack className={styles["UpdateCardLong__userSection"]}>
        {cardType === "card1" ? (
          <SmallPinnedCard
            size={30}
            isActive={false}
            userName="Jennifer"
            userIconSrc="https://randomuser.me/api/portraits/men/61.jpg"
          />
        ) : (
          <AvatarGrouped
            childAvatars={[
              {
                username: "John Doe",
                userIconSrc: "https://randomuser.me/api/portraits/men/61.jpg",
                avatarColor: "#FF5733",
              },
              {
                username: "Jane Smith",
                userIconSrc: "https://randomuser.me/api/portraits/women/61.jpg",
                avatarColor: "#33FF57",
              },
              {
                username: "Bob Johnson",
                userIconSrc: "https://randomuser.me/api/portraits/men/62.jpg",
                avatarColor: "#3357FF",
              },
              {
                username: "Alice Brown",
                userIconSrc: "https://randomuser.me/api/portraits/women/62.jpg",
                avatarColor: "#FF33A6",
              },
              {
                username: "Chris Davis",
                userIconSrc: "https://randomuser.me/api/portraits/men/63.jpg",
                avatarColor: "#FFC300",
              },
              {
                username: "Ella Wilson",
                userIconSrc: "https://randomuser.me/api/portraits/women/63.jpg",
                avatarColor: "#DAF7A6",
              },
            ]}
          />
        )}

        <Typography
          className={styles["UpdateCardLong__userName"]}
          sx={{
            width: cardType === "card1" ? "120px" : "170px",
          }}
        >
          {userOrProjectName}
        </Typography>
      </Stack>

      {/* Heighlighter[@tags] and message text here */}
      <Stack className={styles["UpdateCardLong__messageSection"]}>
        <Stack className={styles["UpdateCardLong__highlightSection"]}>
          <Typography
            sx={{ color: tagColor() }}
            className={styles["UpdateCardLong__tag"]}
          >
            @{tag?.toLowerCase()}
          </Typography>
          <Stack
            className={styles["UpdateCardLong__textContainer"]}
            sx={{
              width: cardType === "card1" ? "calc(100% - 80px)" : "100%",
            }}
            onClick={handleNavigate}
          >
            <Typography
              sx={{
                width: cardType === "card1" ? "calc(100% - 100px)" : "100%",
                font: "normal normal 300 13px/16px Source Serif Pro",
                overflow: "hidden",
                textOverflow: cardType === "card1" ? "ellipsis" : "initial",
                whiteSpace: cardType === "card1" ? "nowrap" : "normal",
                overflowY: cardType === "card1" ? "hidden" : "scroll",
                scrollbarWidth: "none",
              }}
            >
              <div
              dangerouslySetInnerHTML={{ __html: getHTMLContent(description)}}
            />

            </Typography>
          </Stack>
        </Stack>
      </Stack>

      {/* Buttons and company icons */}

      <Stack className={styles["UpdateCardLong__actionSection"]}>
        <Box
          sx={{ height: "20px", width: "20px" }}
          component={"img"}
          alt="logo"
          src={companyIconSrc}
        />
        {activeAction && (
          <>
            <IconButton
              onClick={onDoneButtonClick}
              className={styles["UpdateCardLong__actionButton"]}
              disableRipple
            >
              <DoneIcon w={20} color="#008D4C" />
              <Typography className={styles["UpdateCardLong__buttonText"]}>
                Done
              </Typography>
            </IconButton>

            <SnoozeButton onSelect={(value) => onSnoozeButtonClick(value)} />
          </>
        )}
      </Stack>
    </Stack>
  );
};

export default UpdateCardLong;
