import { IconButton, Stack, Typography, Menu, MenuItem } from "@mui/material";
import React, { useState } from "react";
import { SnoozeIcon } from "../../../../../images";
import styles from "./SnoozeButton.module.css";
import {
  SnoozeButtonList,
  SnoozeButtonListInterface,
} from "./SnoozeButtonTypes";

interface SnoozeButtonProps {
  options?: SnoozeButtonListInterface[];
  onSelect: (value: string) => void;
}

const SnoozeButton: React.FC<SnoozeButtonProps> = ({
  onSelect,
  options = SnoozeButtonList,
}) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const selectHandler = (value: string) => {
    onSelect(value);
    handleClose();
  };

  return (
    <Stack>
      <IconButton
        className={styles["SnoozeButton__actionButton"]}
        disableRipple
        onClick={handleClick}
      >
        <SnoozeIcon w={20} />
        <Typography className={styles["SnoozeButton__buttonText"]}>
          Snooze
        </Typography>
      </IconButton>
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        PaperProps={{
          style: { boxShadow: "0px 3px 3px 0px rgba(0, 0, 0, 0.15)" },
        }}
      >
        {options.map((snValue, index) => (
          <MenuItem
            key={index}
            onClick={() => selectHandler(snValue.value)}
            className={styles["SnoozeButton__MenuText"]}
          >
            {snValue.label}
          </MenuItem>
        ))}
      </Menu>
    </Stack>
  );
};

export default SnoozeButton;
