import { Avatar, Box, Typography } from "@mui/material";
import React, { ChangeEvent, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { MessagesState } from "../../../../redux/Slice/message/messageTypes";
import { RootState } from "../../../../redux/store";
import Grid from "@mui/material/Grid";
import { AuthState } from "../../../../redux";
import pusher from "../../../../Utils/pusher";
import {
  addChannelAction,
  addChannelIdAction,
  addChannelNameAction,
  addEditMessageContentAction,
  addEditMessageContentStateAction,
  addMessageAction,
  addReplyMessageContentAction,
} from "../../../../redux/Slice/message/messageSlice";
import {
  getChannelMessages,
  getUserConnections,
  markMessagesAsRead,
  sendMessages,
  updateMessage,
} from "../../../../redux/Slice/message/messageActions";
import { MessagesScreen } from "../messagesScreen";
import {
  CloseIcon,
  InfoIcon,
  MailOutlineIcon,
  VideocamIcon,
} from "../../../../images";
import { MessageTabBar } from "../../../common/MessageTabBar";
import { MessageActionBar } from "../../../common/MessageActionBar";
import { useNavigate, useParams } from "react-router-dom";
import MessageActivitySidebar from "../components/MessageActivitySidebar/MessageActivitySidebar";
import {
  requestNotificationPermission,
  triggerNotification,
  triggerNotificationForCreatedNewConnection,
  triggerNotificationForEditMessage,
  triggerNotificationForReaction,
} from "../../../../Utils/notifications";

const tabs: {
  label: string;
  content: React.ReactNode;
}[] = [
  {
    label: "New Update",
    content: "<LazyDetailsTab />",
  },
  {
    label: "Done",
    content: "<LazyActivityTab />",
  },
  {
    label: "Snoozed",
    content: "<LazyConnectedTab />",
  },
  {
    label: "Summaries",
    content: "<LazyConnectedTab />",
  },
];

const MessageChats = () => {
  const {
    messages,
    channels,
    currentChannelId,
    currentChannelName,
    currentChannel,
    editMessageContent,
    replyMessageContent,
  } = useSelector((state: RootState) => state.message as MessagesState);
  const { user } = useSelector((state: RootState) => state.auth as AuthState);
  const [clear, setClear] = useState<boolean>(false);
  const [message, setMessage] = useState<string>(
    editMessageContent?.content ? editMessageContent?.content : ""
  );
  const [openSchedule, setOpenSchedule] = useState<boolean>(false);
  const [selectedTab, setSelectedTab] = useState(0);
  const [messageMentionedIds, setMessageMentionedIds] = useState<string[]>([]);
  const [messageFlags, setMessageFlags] = useState<string[]>([]);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { id } = useParams();

  useEffect(() => {
    if (editMessageContent?.content) {
      setMessage(editMessageContent?.content);
    } else {
      setMessage("");
    }
  }, [editMessageContent?.content]);

  useEffect(() => {
    requestNotificationPermission();
    const currentChannelIdInst: any = id;

    if (!currentChannelId) {
      dispatch(addChannelIdAction(id));
    }

    if (user?.data?._id) {
      const channel = pusher.subscribe(user?.data?._id ? user?.data?._id : "");

      const callback = (eventName: any, data: any) => {
        if (eventName === "new-connection") {
          dispatch(getUserConnections(user?.data._id));
          triggerNotificationForCreatedNewConnection(data, dispatch);
        }
        if (eventName === "new-message") {
          if (data?.message?.channelId === currentChannelIdInst) {
            dispatch(addMessageAction(data));
            dispatch(getUserConnections(user?.data._id));
            const payload = {
              userId: user?.data?._id,
            };
            dispatch(markMessagesAsRead(payload, currentChannelIdInst));
            if (data.addEmoji) {
              triggerNotificationForReaction(dispatch, data, user?.data);
            } else if (data.isEdited) {
              triggerNotificationForEditMessage(dispatch, data, user?.data);
            } else if (data.message.userId !== user?.data?._id) {
              triggerNotification(dispatch, data, user?.data,channels);
            } else {
              console.log(data.message);
            }
          } else {
            if (data.addEmoji) {
              triggerNotificationForReaction(dispatch, data, user?.data);
            } else if (data.isEdited) {
              dispatch(getUserConnections(user?.data._id));
              triggerNotificationForEditMessage(dispatch, data, user?.data);
            } else {
              dispatch(getUserConnections(user?.data._id));
              triggerNotification(dispatch, data, user?.data,channels);
            }
          }
        }
      };

      channel.bind_global(callback);

      return () => {
        if (channel) {
          channel.unbind_global(callback);
          pusher.unsubscribe(user?.data?._id);
        }
      };
    }
  }, [id]);

  useEffect(() => {
    if (user) {
      dispatch(getUserConnections(user?.data._id));
    }
  }, []);

  useEffect(() => {
    if (currentChannelId) {
      dispatch(getChannelMessages({ id: currentChannelId }));
      const currentChannel: any = channels.find(
        (channel: any) => channel._id === currentChannelId
      );
      dispatch(addChannelAction(currentChannel));
      dispatch(addEditMessageContentAction(null));
      dispatch(addReplyMessageContentAction(null));
      dispatch(addChannelNameAction(currentChannel?.displayName));
      const payload: any = {
        userId: user?.data._id,
      };
      dispatch(markMessagesAsRead(payload, currentChannelId));
    }
  }, [currentChannelId]);

  useEffect(() => {
    if (currentChannelId) {
      const currentChannel: any = channels.find(
        (channel: any) => channel._id === currentChannelId
      );
      dispatch(addChannelAction(currentChannel));
      dispatch(addChannelNameAction(currentChannel?.displayName));
    }
  }, [channels]);

  const handleSearchChange = (event: ChangeEvent<HTMLInputElement>) => {
    const value: any = event;
    setOpenSchedule(false);
    const tempElement = document.createElement("div");
    tempElement.innerHTML = value;
    const mentions = tempElement.querySelectorAll(".tribute-mention");

    const mentionArray = Array.from(mentions).map((mention: any) =>
      mention.textContent.trim()
    );

    const paragraphs = tempElement.querySelectorAll("p");

    const flagArray = Array.from(paragraphs)
        .map((p: HTMLElement) => p.textContent?.trim())
        .filter(text => {
            return text && text.startsWith("/") && ["Low", "Medium", "High"].includes(text.substring(1));
        })
        .map((text:any) => text.substring(1).toUpperCase());

    setMessageFlags(flagArray);
    setMessageMentionedIds(mentionArray);
    setMessage(value);
  };

  const handleEmojiSelect = (emoji: { native: string }) => {
    setMessage((prevMessage) => prevMessage + emoji.native);
  };

  const handleCancelReply = async () => {
    await dispatch(addReplyMessageContentAction(null));
  };
  const editMessageState = async (editMessageContentId: any) => {
    const findMessageIndexById = (id: string): number => {
      return messages.findIndex((message) => message._id === id);
    };
    let editedMessageContentState = await {
      content: message,
      id: findMessageIndexById(editMessageContentId),
    };

    await dispatch(addEditMessageContentStateAction(editedMessageContentState));
  };

  const handleClearInput = () => {
    dispatch(addEditMessageContentAction(null));
    setClear(!clear);
    setMessage("");
    setOpenSchedule(false);
  };

  const handleClick = async () => {
    if (editMessageContent?.content) {
      let editedMessageContent = {
        content: message,
        channelId: currentChannelId,
      };
      await dispatch(
        updateMessage(editMessageContent?.id, editedMessageContent)
      );
      editMessageState(editMessageContent?.id);
    } else {
      const payload: any = {
        userId: user?.data?._id,
        content: message,
        channelId: currentChannelId,
        replyTo: replyMessageContent?.id ? replyMessageContent?.id : null,
        mentioned: messageMentionedIds ? messageMentionedIds : [],
      };
      await dispatch(sendMessages(payload));
      if (replyMessageContent?.id) {
        await dispatch(getChannelMessages({ id: currentChannelId }));
      }
    }
    setClear(!clear);
    setMessage("");
    setMessageFlags([]);
  };

  const handleKeyPress = (event: any) => {
    if (event.key === "Enter") {
      event.preventDefault();
      handleClick();
    }
  };

  const handleCloseChat = () => {
    dispatch(addChannelIdAction(null));
    dispatch(addChannelNameAction(null));
    dispatch(addChannelAction(null));
    navigate(`/Message`);
  };

  return (
    <>
      <MessageTabBar
        tabs={tabs}
        selectedTab={selectedTab}
        setSelectedTab={setSelectedTab}
      />
      <Grid container spacing={2}>
        <Grid item xs={9}>
          <Box
            display="flex"
            padding="0 30px 0 30px"
            flexDirection="column"
            height="79vh"
            width="100%"
          >
            <Box display="flex" alignItems="center" p={2} height="46px">
              {currentChannelName && (
                <Avatar
                  alt={currentChannelName}
                  src="/static/images/avatar/2.jpg"
                />
              )}
              <Typography
                variant="h6"
                width="400px"
                fontWeight={600}
                fontSize="16px"
                ml={2}
              >
                {currentChannelName}
              </Typography>

              {currentChannelName && (
                <Box
                  display="flex"
                  width="100%"
                  alignItems="end"
                  justifyContent="end"
                  p={2}
                >
                  <VideocamIcon style={{ marginRight: "16px" }} />
                  <MailOutlineIcon style={{ marginRight: "16px" }} />
                  {!currentChannel?.isGroupChat && (
                    <InfoIcon style={{ marginRight: "16px" }} />
                  )}
                  <CloseIcon onClick={handleCloseChat} />
                </Box>
              )}
            </Box>
            <Box
              id="scrollableDiv"
              flexGrow={1}
              p={2}
              height="100%"
              overflow="auto"
            >
              <MessagesScreen
                channels={channels}
                user={user}
                messages={messages}
              />
            </Box>
            {(currentChannel?.isGroupChat
                ? currentChannel?.currentUserRole?.toUpperCase() !== "VIEWER" && !currentChannel?.currentUserLeave
                : !currentChannel?.currentUserLeave) && (
              <MessageActionBar
                handleClearInput={handleClearInput}
                openSchedule={openSchedule}
                handleClick={handleClick}
                handleSearchChange={handleSearchChange}
                handleKeyPress={handleKeyPress}
                message={message}
                handleEmojiSelect={handleEmojiSelect}
                replyMessage={replyMessageContent}
                handleCancelReply={handleCancelReply}
                currentChannel={currentChannel}
                userDetails={user}
                setOpenSchedule={setOpenSchedule}
              />
            )}
          </Box>
        </Grid>
        <Grid item xs={3} style={{ overflowY: "scroll", height: "79vh" }}>
          <MessageActivitySidebar />
        </Grid>
      </Grid>
    </>
  );
};

export default MessageChats;
