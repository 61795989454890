import { RadioGroup, Stack, Typography } from "@mui/material";
import React, { useState } from "react";
import { ClockIcon } from "../../../../../images";
import {
  ButtonSmall,
  ButtonType,
  CustomRadioFormControlLabel,
  DateAndTimePicker,
  SmallButtonColor,
  StyledRadioButtonBlack,
} from "../../../../common";
import { Dayjs } from "dayjs";

interface SnoozeEmailProps {
  variant: "SnoozeEmail" | "ScheduleEmail";
}

const SnoozeEmail: React.FC<SnoozeEmailProps> = ({ variant }) => {
  const [selectedValue, setSelectedValue] = React.useState<any>("");
  const [dateTimeValue, setDateTimeValue] = React.useState<Dayjs | null>(null);

  const handleBodyRadioChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setSelectedValue(event.target.value);
  };

  return (
    <Stack
      sx={{
        width: "250px",
        bgcolor: "white",
        borderRadius: "5px",
        boxShadow: "0px 3px 3px 0px rgba(0, 0, 0, 0.15);",
        padding: "15px",
        boxSizing: "border-box",
        gap: "10px",
      }}
    >
      <Stack sx={{ flexDirection: "row", gap: "5px" }}>
        <ClockIcon w={15} />
        <Typography
          sx={{ font: "normal normal 400 14px/18px Source Serif Pro" }}
        >
          {variant === "SnoozeEmail" ? "Snooze Email" : "Schedule Email"}
        </Typography>
      </Stack>

      <Typography
        sx={{
          font: "normal italic 300 14px/18px Source Serif Pro",
          letterSpacing: "-0.05em",
        }}
      >
        {variant === "SnoozeEmail"
          ? "  Snooze notifications about this email until... "
          : "Scheduling this email will allow you to send it at a later time."}
      </Typography>
      <RadioGroup
        aria-labelledby="subjectKeyword-radio-buttons-group"
        name="subjectKeyword-radio-buttons-group"
        value={selectedValue}
        onChange={handleBodyRadioChange}
        sx={{ p: 0, marginTop: "5px", gap: "13px" }}
      >
        <CustomRadioFormControlLabel
          value="Today in the afternoon"
          label="Today in the afternoon"
          tooltipText="Afternoon is 01:00 PM"
        />
        <CustomRadioFormControlLabel
          value="Today at night"
          label="Today at night"
          tooltipText="Night is 08:00 PM"
        />
        <CustomRadioFormControlLabel
          value="Tomorrow morning"
          label="Tomorrow morning"
          tooltipText="T. Morning is 08:00 AM"
        />
        <CustomRadioFormControlLabel
          value="Tomorrow in the afternoon"
          label="Tomorrow in the afternoon"
          tooltipText="T. Afternoon is 01:00 PM"
        />
        <Stack
          sx={{
            marginTop: "-1px",
            flexDirection: "row",
            alignItems: "center",
            gap: "10px",
            font: "normal normal 300 14px/18px Source Serif Pro",
          }}
        >
          <StyledRadioButtonBlack
            radioSize={17}
            value={dateTimeValue?.toISOString()}
            checked={selectedValue === dateTimeValue?.toISOString()}
          />
          <DateAndTimePicker
            sx={{ font: "normal normal 300 14px/18px Source Serif Pro" }}
            onSelect={(value) => {
              setDateTimeValue(value);
              // setSelectedValue(value?.toISOString());
            }}
          />
        </Stack>
      </RadioGroup>
      <Stack sx={{ flexDirection: "row", justifyContent: "center" }}>
        <ButtonSmall
          onClick={() => console.log(selectedValue)}
          label="Done"
          types={ButtonType.Button}
          colorVarient={SmallButtonColor.Blue}
        />
      </Stack>
    </Stack>
  );
};

export default SnoozeEmail;
