import React from "react";
import {
  CheckedCircleIcon,
  DateIcon,
  EmailIcon,
  NotesLineIcon,
  StreamIcon,
  VideoCallIcon,
} from "../../../../../images";
import { Button, Stack, Tooltip } from "@mui/material";

interface IconListInterface {
  id: number;
  icon: React.ElementType;
  value: string;
  hoverText?: string;
}

const iconsList: IconListInterface[] = [
  { id: 1, icon: VideoCallIcon, hoverText: "text", value: "VideoCallIcon" },
  { id: 2, icon: EmailIcon, hoverText: "text", value: "EmailIcon" },
  { id: 3, icon: StreamIcon, hoverText: "text", value: "StreamIcon" },
  {
    id: 4,
    icon: CheckedCircleIcon,
    hoverText: "text",
    value: "CheckedCircleIcon",
  },
  { id: 5, icon: DateIcon, hoverText: "text", value: "DateIcon" },
  { id: 6, icon: NotesLineIcon, hoverText: "text", value: "NotesLineIcon" },
];

type MultiIconButtonProps = {
  btnSize?: number;
  disableHoverText?: boolean;
};

const MultiIconButton: React.FC<MultiIconButtonProps> = ({
  disableHoverText = false,
  btnSize = 25,
}) => {
  return (
    <Stack
      sx={{
        flexDirection: "row",
        alignItems: "center",
        gap: "10px",
      }}
    >
      {iconsList.map((icon, index) => (
        <Tooltip
          title={icon.hoverText}
          placement="top"
          arrow
          disableHoverListener={disableHoverText}
        >
          <Button
            variant="text"
            sx={{
              p: 0,
              boxSizing: "border-box",
              height: `${btnSize}px`,
              width: `${btnSize}px`,
              borderRadius: "5px",
              alignItems: "center",
              justifyContent: "center",
              border: "1px solid #E9EDF2",
              minWidth: 0,
              color:"black"
            }}
            key={index}
          >
            {React.createElement(icon.icon, {
              w: btnSize * 0.8,
              color: "black",
            })}
          </Button>
        </Tooltip>
      ))}
    </Stack>
  );
};

export default MultiIconButton;
