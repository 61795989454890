import * as React from "react";
import {
  FormControl,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Select,
  SelectChangeEvent,
  Stack,
  SxProps,
} from "@mui/material";
import S from "./LanguageDropdownMenu.module.css";
import { GreenDropdownIcon } from "../../../../../images";

interface LanguageDropdownMenuProps {
  options: string[];
  initialValue?: string;
  placeholder?: string;
  onChange?: (selectedOption: string) => void;
  sx?: SxProps;
  multiple?: boolean;
}

const LanguageDropdownMenu: React.FC<LanguageDropdownMenuProps> = React.memo(
  ({
    options,
    placeholder,
    onChange,
    initialValue,
    multiple = false,
    sx,
    ...props
  }) => {
    const [selectedLanguages, setSelectedLanguages] = React.useState<string[]>(
      initialValue ? [initialValue] : []
    );
    // const [selectedLanguages, setSelectedLanguages] = React.useState<string[]>(
    //   []
    // );
    const [open, setOpen] = React.useState(false);

    const handleChange = React.useCallback(
      (event: SelectChangeEvent<typeof selectedLanguages>) => {
        const {
          target: { value },
        } = event;
        setSelectedLanguages(
          typeof value === "string" ? value.split(",") : value
        );

        if (onChange) {
          const selectedValue =
            typeof value === "string" ? value : value.join(", ");
          onChange(selectedValue);
        }
      },
      [onChange]
    );

    const MenuProps = {
      PaperProps: {
        className: S["language-dropdown-menu__menu"],
        // style: { width: width },
        style: { width: "inherit" },
      },
    };

    return (
      <Stack sx={{ width: "100%", ...sx }}>
        <FormControl {...props}>
          <Select
            open={open}
            onClick={!open ? () => setOpen(true) : () => null}
            displayEmpty
            multiple={multiple}
            IconComponent={() => (
              <GreenDropdownIcon sx={{ height: "12px" }} open={open} />
            )}
            value={selectedLanguages}
            onChange={handleChange}
            input={
              <OutlinedInput
                className={S["language-dropdown-menu__inputbox"]}
                classes={{
                  notchedOutline: S["language-dropdown-menu__notchedOutline"],
                  focused: S["language-dropdown-menu__focused"],
                }}
              />
            }
            onOpen={() => setOpen(true)}
            onClose={() => setOpen(false)}
            renderValue={(selected) => {
              if (selected.length === 0) {
                return (
                  <em
                    className={S["language-dropdown-menu__selectPlaceholder"]}
                  >
                    {placeholder}
                  </em>
                );
              }

              return (
                <span className={S["language-dropdown-menu__selected"]}>
                  {selected?.join(", ")}
                </span>
              );
            }}
            MenuProps={MenuProps}
          >
            {options.map((name) => (
              <MenuItem
                className={S["language-dropdown-menu__menu-item"]}
                key={name}
                value={name}
              >
                <ListItemText
                  primary={name}
                  primaryTypographyProps={{
                    className: S["language-dropdown-menu__list-item-text"],
                  }}
                />
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Stack>
    );
  }
);

export default LanguageDropdownMenu;
