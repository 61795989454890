import { Stack } from "@mui/material";
import React, { lazy, Suspense, useState } from "react";
import {
  a11yProps,
  StyledTab,
  TabPanel,
  TabsWithState,
} from "../../../../common/CardTabsWithoutIcon";

interface MessageActivitySidebarProps {}

const LazyGeneralTab = lazy(() => import("./tabs/GeneralTab/GeneralTab"));
const LazyTaskTab = lazy(() => import("./tabs/Tasktab/Tasktab"));
const LazyActivitylTab = lazy(
  () => import("./tabs/ActivityTrackerTab/ActivityTrackerTab")
);

const tabs: {
  label: string;
  content: React.ReactNode;
}[] = [
  {
    label: "General",
    content: <LazyGeneralTab />,
  },
  {
    label: "Tasks",
    content: <LazyTaskTab />,
  },
  {
    label: "Activity Tracker",
    content: <LazyActivitylTab />,
  },
];

const MessageActivitySidebar: React.FC<MessageActivitySidebarProps> = ({}) => {
  const [tabValue, setTabValue] = useState(0);
  return (
    <Stack
      sx={{ bgcolor: "white", width: "250px", height: "100%", gap: "20px" }}
    >
      <Stack sx={{ borderBottom: "1px solid #E9EDF2" }}>
        <TabsWithState
          ariaLabel="channel details tabs"
          value={tabValue}
          onChange={(event, newValue) => setTabValue(newValue)}
          tabIndicatorColor="#008D4C"
        >
          {tabs.map((tab, index) => (
            <StyledTab
              style={{
                paddingBottom: "10px",
                font: "normal normal 300 13px/18px Source Serif Pro",
              }}
              key={index}
              disableRipple
              label={tab.label}
              {...a11yProps(index)}
            />
          ))}
        </TabsWithState>
      </Stack>

      {tabs.map((tab, index) => (
        <TabPanel
          style={{ display: "contents" }}
          key={index}
          value={tabValue}
          index={index}
        >
          <Suspense fallback={<div>Loading...</div>}>{tab.content}</Suspense>
        </TabPanel>
      ))}
    </Stack>
  );
};

export default MessageActivitySidebar;
