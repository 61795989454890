import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  UpdatableFields,
  EmailState,
} from "./emailTypes";
import {

  /* Get all workspaces */
  getAllEmails__Request,
  getAllEmails__Failure,
  getAllEmails__Success,

  /* Delete workspace action */
  deleteEmail__Request,
  deleteEmail__Failure,
  deleteEmail__Success,


  /* and so on... */
} from "./EmailSliceAction";


const setLocalStorageValue = (key: string, value: boolean) => {
  localStorage.setItem(key, JSON.stringify(value));
};


/*
 * ==============================================
 * Define the initial state for the workspace slice
 * ==============================================
 */

// emails.ts

const initialState: EmailState = {
  emails: {},
  error: null
};

/*
 * ==============================================
 * Create a slice for workspace logic
 * ==============================================
 */
const emails = createSlice({
  name: "emails", // Name of the slice
  initialState, // Initial state of the slice
  reducers: {
    /* Get all emails */
    getAllEmails__Request,
    getAllEmails__Failure,
    getAllEmails__Success,

    /* Delete workspace action */
    deleteEmail__Request,
    deleteEmail__Failure,
    deleteEmail__Success,

    updateField(
      state,
      action: PayloadAction<{ field: UpdatableFields; value: boolean }>
    ) {
      const { field, value } = action.payload;
      state[field] = value as EmailState[UpdatableFields]; // Type assertion here
      setLocalStorageValue(field, value);
    },
  },
});

/*
 * ======================================================
 * Export the actions to be used in dispatch calls
 * ======================================================
 */

export const {
  updateField: updateFieldEmaileAction,
  getAllEmails__Request: getAllEmails__RequestAction,
  getAllEmails__Failure: getAllEmails__FailureAction,
  getAllEmails__Success: getAllEmails__SuccessAction,

  /* Delete workspace action */
  deleteEmail__Request: deleteEmail__RequestAction,
  deleteEmail__Failure: deleteEmail__FailureAction,
  deleteEmail__Success: deleteEmail__SuccessAction,


} = emails.actions;

export default emails.reducer;
