import { IconButton, Link, Popover, Stack, Typography } from "@mui/material";
import React from "react";
import { CheckboxMedium } from "../../../../common";
import { SettingsIcon } from "../../../../../images";
import { CreateSmartFilter } from "../CreateSmartFilter";

interface SmartFiltersDropdownProps {}

const dataList: { id: number; name: string; onSettingClick: () => void }[] = [
  { id: 1, name: "Main Inbox", onSettingClick: () => null },
  { id: 2, name: "No replies", onSettingClick: () => null },
  { id: 3, name: "Bills", onSettingClick: () => null },
  { id: 4, name: "Newsletters", onSettingClick: () => null },
  { id: 5, name: "Events", onSettingClick: () => null },
  { id: 6, name: "Social Media", onSettingClick: () => null },
];

const SmartFiltersDropdown: React.FC<SmartFiltersDropdownProps> = () => {
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
    null
  );

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;
  return (
    <Stack
      sx={{
        width: "140px",
        bgcolor: "white",
        padding: "10px 15px 10px 10px",
        boxSizing: "border-box",
        borderRadius: "5px",
        boxShadow: "0px 3px 3px 0px rgba(0, 0, 0, 0.15)",
        gap: "12px",
      }}
    >
      <Stack gap={"15px"}>
        {dataList.map((item) => (
          <Stack
            sx={{
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <Stack
              sx={{
                flexDirection: "row",
                gap: "5px",
                alignItems: "center",
              }}
            >
              <CheckboxMedium boxSize={15} />
              <Typography
                sx={{
                  font: "normal normal 300 11px/11px Source Serif Pro",
                  alignItems: "center",
                  paddingTop: "3px",
                }}
              >
                {item.name}
              </Typography>
            </Stack>

            <IconButton
              sx={{ p: 0, borderRadius: "5px" }}
              disableRipple
              onClick={item.onSettingClick}
            >
              <SettingsIcon w={10} />
            </IconButton>
          </Stack>
        ))}
      </Stack>

      <IconButton
        onClick={handleClick}
        sx={{
          p: 0,
          borderRadius: 0,
          justifyContent: "center",
          alignItems: "center",
        }}
        disableRipple
      >
        <Link
          underline="hover"
          sx={{
            font: "normal normal 300 11px/14px Source Serif Pro",
            color: "#000AFF",
          }}
        >
          Create a smart filter
        </Link>
      </IconButton>

      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      >
       <CreateSmartFilter />
      </Popover>
    </Stack>
  );
};

export default SmartFiltersDropdown;
