import React, { useState, useEffect, useCallback } from "react";
import { SketchPicker, ColorResult, RGBColor } from "react-color";
import { Popover, Tooltip } from "@mui/material";
import { debounce } from "lodash"; // Add lodash or create a custom debounce function

interface RGBAColor extends RGBColor {
  a: number;
}

interface SketchExampleProps {
  initialColor?: string;
  onColorChange?: (color: string) => void;
  hoverText?: string;
  disableHoverText?: boolean;
  readonlyComponent?: boolean;
}

const isValidHex = (hex: string): boolean => {
  const hexRegex = /^#([0-9A-F]{6}|[0-9A-F]{8})$/i;
  return hexRegex.test(hex);
};

const hexToRgba = (hex: string): RGBAColor => {
  let r = 0,
    g = 0,
    b = 0,
    a = 1;
  if (hex.length === 7) {
    r = parseInt(hex.slice(1, 3), 16);
    g = parseInt(hex.slice(3, 5), 16);
    b = parseInt(hex.slice(5, 7), 16);
  } else if (hex.length === 9) {
    r = parseInt(hex.slice(1, 3), 16);
    g = parseInt(hex.slice(3, 5), 16);
    b = parseInt(hex.slice(5, 7), 16);
    a = parseInt(hex.slice(7, 9), 16) / 255;
  }
  return { r, g, b, a };
};

const rgbaToHex = (r: number, g: number, b: number, a: number = 1): string => {
  const hr = r.toString(16).padStart(2, "0");
  const hg = g.toString(16).padStart(2, "0");
  const hb = b.toString(16).padStart(2, "0");
  const ha = Math.round(a * 255)
    .toString(16)
    .padStart(2, "0");
  return `#${hr}${hg}${hb}${ha}`;
};

const SketchExample: React.FC<SketchExampleProps> = ({
  initialColor = "#007BB2FF",
  onColorChange,
  disableHoverText = false,
  readonlyComponent = false,
  hoverText = "Edit Color",
}) => {
  const defaultColor = hexToRgba("#007BB2FF");
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [color, setColor] = useState<RGBAColor>(defaultColor);

  useEffect(() => {
    if (isValidHex(initialColor)) {
      setColor(hexToRgba(initialColor));
    } else {
      setColor(defaultColor);
    }
  }, [initialColor]);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    event.preventDefault();
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const debouncedColorChange = useCallback(
    debounce((newHex: string) => {
      if (onColorChange) {
        onColorChange(newHex); // Trigger only after debounce delay
      }
    }, 300), // Adjust the delay as needed
    [onColorChange]
  );

  const handleChange = (color: ColorResult) => {
    const { r, g, b, a } = color.rgb;
    const newColor = { r, g, b, a: a !== undefined ? a : 1 };
    setColor(newColor); // Update color for smooth dragging

    const newHex = rgbaToHex(r, g, b, newColor.a);
    debouncedColorChange(newHex); // Debounced update for onColorChange
  };

  const open = Boolean(anchorEl);
  const id = open ? "color-picker-popover" : undefined;

  return (
    <div>
      <Tooltip
        placement="top"
        arrow
        title={hoverText}
        disableHoverListener={disableHoverText}
      >
        <div
          style={{
            height: "10px",
            width: "10px",
            borderRadius: "5px",
            cursor: "pointer",
            background: `rgba(${color.r}, ${color.g}, ${color.b}, ${color.a})`,
          }}
          onClick={!readonlyComponent ? (e) => handleClick(e) : () => null}
        />
      </Tooltip>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
      >
        <SketchPicker color={color} onChange={handleChange} />
      </Popover>
    </div>
  );
};

export default SketchExample;
