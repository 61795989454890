export interface SnoozeButtonListInterface {
    id: number | string;
    label: string;
    value: string
}

export const SnoozeButtonList: SnoozeButtonListInterface[] = [
    { id: 1, label: "15 mins", value: "15 mins" },
    { id: 2, label: "30 mins", value: "30 mins" },
    { id: 3, label: "45 mins", value: "45 mins" },
    { id: 4, label: "60 mins", value: "60 mins" },
    { id: 5, label: "120 mins", value: "120 mins" },
    { id: 6, label: "24 hours", value: "24 hours" },
    { id: 7, label: "48 hours", value: "48 hours" },

]