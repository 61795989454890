import { InputBase, Stack, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import styles from "./DetailsTab.module.css";
import { EditableTextArea } from "../../../../../../common";
import {useSelector} from "react-redux";
import {RootState} from "../../../../../../../redux/store";
import {AuthState} from "../../../../../../../redux";

interface DetailsTabProps {
  channelDetails: any;
  onChannelDataChange: (updatedData: any) => void;
}

const DetailsTab: React.FC<DetailsTabProps> = ({
  channelDetails,
  onChannelDataChange,
}) => {
  const [channelData, setChannelData] = useState({
    chName: channelDetails?.displayName || "",
    topicName: channelDetails?.topic || "",
    chDescription: channelDetails?.details || "",
  });
  const { user } = useSelector((state: RootState) => state?.auth as AuthState);
  const [participentDetail, setParticipentDetail] = useState<any>({});

  const handleInputChange = (field: string, value: string) => {
    const updatedData = {
      ...channelData,
      [field]: value,
    };
    setChannelData(updatedData);
    onChannelDataChange(updatedData); 
  };

  useEffect(() => {
    const participentData = channelDetails?.participants?.filter((x:any)=> x.userId === user?.data?._id)?.[0]
    setParticipentDetail(participentData)
  }, []);

  return (
    <Stack className={styles["details-tab"]}>
      {/* Channel Name */}
      <Stack className={styles["details-tab__section"]}>
        <Typography className={styles["details-tab__label"]}>
          Channel name
        </Typography>
        <InputBase
          value={channelData.chName}
          onChange={(e) => handleInputChange("chName", e.target.value)}
          className={styles["details-tab__value"]}
          placeholder="Channel Name here"
          disabled={participentDetail?.role?.toUpperCase() !== 'ADMIN'}
        />
      </Stack>

      {/* Channel Topic */}
      <Stack className={styles["details-tab__section"]}>
        <Typography className={styles["details-tab__label"]}>
          Channel topic
        </Typography>
        <InputBase
          value={channelData.topicName}
          onChange={(e) => handleInputChange("topicName", e.target.value)}
          className={styles["details-tab__value"]}
          placeholder="channel topic"
          disabled={participentDetail?.role?.toUpperCase() !== 'ADMIN'}
        />
      </Stack>

      {/* Channel Description */}
      <Stack className={styles["details-tab__section"]}>
        <Typography className={styles["details-tab__label"]}>
          Channel Description
        </Typography>
        <EditableTextArea
          placeholder="Description here"
          text={channelData.chDescription}
          onTextChange={(newText) =>
            handleInputChange("chDescription", newText)
          }
          disabled={participentDetail?.role?.toUpperCase() !== 'ADMIN'}
          style={{
            font: "normal normal 600 14px/18px Source Serif Pro",
            color: "black",
          }}
        />
      </Stack>

      {/* Created By */}
      <Stack className={styles["details-tab__section"]}>
        <Typography className={styles["details-tab__label"]}>
          Created by
        </Typography>
        <Stack sx={{ flexDirection: "row", flexWrap: "wrap", gap: "2px 5px" }}>
          <Typography className={styles["details-tab__value"]}>
            {channelDetails.creatorName}
          </Typography>
        </Stack>
      </Stack>

      {/* Channel ID */}
      <Stack className={styles["details-tab__section"]}>
        <Typography className={styles["details-tab__label"]}>
          Channel ID
        </Typography>
        <Typography className={styles["details-tab__value"]}>
          {channelDetails._id}
        </Typography>
      </Stack>
    </Stack>
  );
};

export default DetailsTab;
