import { Box, Typography } from "@mui/material";
import React from "react";
import g from "./TotalCRM.module.css";

type TotalCRMProps = {
  label: string;
  totalCount: number;
};

const TotalCRM: React.FC<TotalCRMProps> = ({ label, totalCount }) => {
  return (
    <Box className={g.boxTotalCRM}>
      <Typography className={g.boxText1TotalCRM} children={label} />
      <Typography
        className={g.boxText2TotalCRM}
        children={totalCount?.toString()}
      />
    </Box>
  );
};

export default TotalCRM;
