import { createSlice } from "@reduxjs/toolkit";
import { MessagesState } from "./messageTypes";
import {
  addReactionToMessageFailure,
  addReactionToMessageRequest,
  addReactionToMessageSuccess,
  changeMessagesActivitiesStatusFailure,
  changeMessagesActivitiesStatusRequest,
  changeMessagesActivitiesStatusSuccess,
  changeParticipentsRoleFailure,
  changeParticipentsRoleRequest,
  changeParticipentsRoleSuccess,
  createNewConnectionFailure,
  createNewConnectionRequest,
  createNewConnectionSuccess,
  forwardMessageFailure,
  forwardMessageRequest,
  forwardMessageSuccess,
  getChannelActivitiesFailure,
  getChannelActivitiesRequest,
  getChannelActivitiesSuccess,
  /* User */
  getChannelMessageFailure,
  getChannelMessageRequest,
  getChannelMessageSuccess,
  getMessageActivitiesFailure,
  getMessageActivitiesRequest,
  getMessageActivitiesSuccess,
  getUserConnectionsFailure,
  getUserConnectionsRequest,
  getUserConnectionsSuccess,
  markMessagesAsReadFailure,
  markMessagesAsReadRequest,
  markMessagesAsReadSuccess,
  removeReactionToMessageFailure,
  removeReactionToMessageRequest,
  removeReactionToMessageSuccess,
  scheduleMessageFailure,
  scheduleMessageRequest,
  scheduleMessageSuccess,
  sendMessageFailure,
  sendMessageRequest,
  sendMessageSuccess,
  setSnoozeMessagesActivitiesFailure,
  setSnoozeMessagesActivitiesRequest,
  setSnoozeMessagesActivitiesSuccess,
  updateConnectionFailure,
  updateConnectionRequest,
  updateConnectionSuccess,
  updateMessageFailure,
  updateMessageRequest,
  updateMessageSuccess,
} from "./MessageSliceActions";

/*
 * ==============================================
 * Define the initial state for the message slice
 * ==============================================
 */
const initialState: MessagesState = {
  messages: [], // Array to store messages
  channels: [], // Array to store channel information
  groups: [], // Array to store group information
  currentChannelId: null, // Currently selected channel ID (if any)
  currentGroupId: null, // Currently selected group ID (if any)
  currentUserId: null, // Currently logged-in user ID (if any)
  channelActivity:null, // Channel activity
  currentChannelName: null, // Currently selected
  loading: false, // Loading state
  error: null, // Error state
  currentChannel: {},
  editMessageContent: {},
  replyMessageContent: {},
  messageActivity:[]
};

/*
 * ==============================================
 * Create a slice for Fetch Messages
 * ==============================================
 */
const messageSlice = createSlice({
  name: "message", // Name of the slice
  initialState, // Initial state of the slice

  reducers: {
    // Reducers to handle different actions
    getChannelMessageRequest,
    getChannelMessageSuccess,
    getChannelMessageFailure,

    sendMessageRequest,
    sendMessageSuccess,
    sendMessageFailure,

    getUserConnectionsRequest,
    getUserConnectionsSuccess,
    getUserConnectionsFailure,

    createNewConnectionRequest,
    createNewConnectionSuccess,
    createNewConnectionFailure,

    updateConnectionFailure,
    updateConnectionSuccess,
    updateConnectionRequest,

    updateMessageFailure,
    updateMessageSuccess,
    updateMessageRequest,

    scheduleMessageFailure,
    scheduleMessageSuccess,
    scheduleMessageRequest,

    forwardMessageFailure,
    forwardMessageSuccess,
    forwardMessageRequest,

    addReactionToMessageFailure,
    addReactionToMessageSuccess,
    addReactionToMessageRequest,

    removeReactionToMessageFailure,
    removeReactionToMessageSuccess,
    removeReactionToMessageRequest,

    markMessagesAsReadFailure,
    markMessagesAsReadSuccess,
    markMessagesAsReadRequest,

    changeParticipentsRoleFailure,
    changeParticipentsRoleSuccess,
    changeParticipentsRoleRequest,

    getChannelActivitiesRequest,
    getChannelActivitiesSuccess,
    getChannelActivitiesFailure,

    getMessageActivitiesRequest,
    getMessageActivitiesSuccess,
    getMessageActivitiesFailure,

    changeMessagesActivitiesStatusRequest,
    changeMessagesActivitiesStatusSuccess,
    changeMessagesActivitiesStatusFailure,

    setSnoozeMessagesActivitiesRequest,
    setSnoozeMessagesActivitiesSuccess,
    setSnoozeMessagesActivitiesFailure,

    addMessage(state, action) {
      const { message } = action.payload;
      const messageIndex = state.messages.findIndex(msg => msg?._id === message?._id);
      if (messageIndex !== -1) {
        const updatedMessages = state.messages.map((msg, index) =>
          index === messageIndex ? message : msg
        );
        return {
          ...state,
          messages: updatedMessages,
        };
      } else {
        if(message?._id) {
          return {
            ...state,
            messages: [...state.messages, message],
          };
        }
      }
    },

    addChannelId(state, action) {
      //@ts-ignore
      state.currentChannelId = action.payload;
    },
    addChannelName(state, action) {
      //@ts-ignore
      state.currentChannelName = action.payload;
    },

    addChannel(state, action) {
      state.currentChannel = action.payload;
    },

    addEditMessageContent(state, action) {
      state.editMessageContent = action.payload;
    },

    addEditMessageContentState(state, action) {
      state.messages[action.payload.id].content = action.payload?.content;
      state.messages[action.payload.id].isEdited = true;
    },

    addReplyMessageContent(state, action) {
      state.replyMessageContent = action.payload;
    },
    markDoneMessage(state, action) {
      state.replyMessageContent = action.payload;
    },
    removeMessageActivity(state, action) {
      state.messageActivity = state.messageActivity.filter(
        (activity:any) => activity._id !== action.payload
      );
    },
  },
});

/*
 * ======================================================
 * Export the actions to be used in dispatch calls
 * ======================================================
 */
export const {
  /* Get Channel Messages */
  getChannelMessageRequest: getChannelMessageRequestAction,
  getChannelMessageSuccess: getChannelMessageSuccessAction,
  getChannelMessageFailure: getChannelMessageFailureAction,

  /* send Messages */
  sendMessageRequest: sendMessageRequestAction,
  sendMessageSuccess: sendMessageSuccessAction,
  sendMessageFailure: sendMessageFailureAction,

  /* get user connections */
  getUserConnectionsRequest: getUserConnectionsRequestAction,
  getUserConnectionsSuccess: getUserConnectionsSuccessAction,
  getUserConnectionsFailure: getUserConnectionsFailureAction,

  /* create new connection */
  createNewConnectionRequest: createNewConnectionRequestAction,
  createNewConnectionSuccess: createNewConnectionSuccessAction,
  createNewConnectionFailure: createNewConnectionFailureAction,

  /* update connection */
  updateConnectionRequest: updateConnectionRequestAction,
  updateConnectionSuccess: updateConnectionSuccessAction,
  updateConnectionFailure: updateConnectionFailureAction,

  /* update message */
  updateMessageRequest: updateMessageRequestAction,
  updateMessageSuccess: updateMessageSuccessAction,
  updateMessageFailure: updateMessageFailureAction,

  /* schedule message */
  scheduleMessageRequest: scheduleMessageRequestAction,
  scheduleMessageSuccess: scheduleMessageSuccessAction,
  scheduleMessageFailure: scheduleMessageFailureAction,

  /* forward message */
  forwardMessageRequest: forwardMessageRequestAction,
  forwardMessageSuccess: forwardMessageSuccessAction,
  forwardMessageFailure: forwardMessageFailureAction,

  /* add reaction message */
  addReactionToMessageRequest: addReactionToMessageRequestAction,
  addReactionToMessageSuccess: addReactionToMessageSuccessAction,
  addReactionToMessageFailure: addReactionToMessageFailureAction,

  /* remove reaction message */
  removeReactionToMessageRequest: removeReactionToMessageRequestAction,
  removeReactionToMessageSuccess: removeReactionToMessageSuccessAction,
  removeReactionToMessageFailure: removeReactionToMessageFailureAction,

  /* read message */
  markMessagesAsReadRequest: markMessagesAsReadRequestAction,
  markMessagesAsReadSuccess: markMessagesAsReadSuccessAction,
  markMessagesAsReadFailure: markMessagesAsReadFailureAction,

  /* change role */
  changeParticipentsRoleRequest: changeParticipentsRoleRequestAction,
  changeParticipentsRoleSuccess: changeParticipentsRoleSuccessAction,
  changeParticipentsRoleFailure: changeParticipentsRoleFailureAction,

  /* get channel activities */
  getChannelActivitiesRequest: getChannelActivitiesRequestAction,
  getChannelActivitiesSuccess: getChannelActivitiesSuccessAction,
  getChannelActivitiesFailure: getChannelActivitiesFailureAction,

  /* get message activities */
  getMessageActivitiesRequest: getMessageActivitiesRequestAction,
  getMessageActivitiesSuccess: getMessageActivitiesSuccessAction,
  getMessageActivitiesFailure: getMessageActivitiesFailureAction,

  /* get message activities */
  changeMessagesActivitiesStatusRequest: changeMessagesActivitiesStatusRequestAction,
  changeMessagesActivitiesStatusSuccess: changeMessagesActivitiesStatusSuccessAction,
  changeMessagesActivitiesStatusFailure: changeMessagesActivitiesStatusFailureAction,

  /* get message activities */
  setSnoozeMessagesActivitiesRequest: setSnoozeMessagesActivitiesRequestAction,
  setSnoozeMessagesActivitiesSuccess: setSnoozeMessagesActivitiesSuccessAction,
  setSnoozeMessagesActivitiesFailure: setSnoozeMessagesActivitiesFailureAction,


  /* add message */
  addMessage: addMessageAction,

  addChannelId: addChannelIdAction,

  addChannelName: addChannelNameAction,

  addChannel: addChannelAction,

  addEditMessageContent: addEditMessageContentAction,

  addEditMessageContentState: addEditMessageContentStateAction,

  addReplyMessageContent: addReplyMessageContentAction,
  removeMessageActivity: removeMessageActivityAction,

} = messageSlice.actions;

/*
 * ======================================================
 * Export the reducer to be used in the store
 * ======================================================
 */
export default messageSlice.reducer;
