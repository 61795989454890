import { IconButton, Stack, Tooltip, Typography } from "@mui/material";
import React, { lazy, Suspense } from "react";
import {
  a11yProps,
  StyledTab,
  TabPanel,
  TabsWithState,
} from "../../../../common/CardTabsWithoutIcon";
import { XCircleIcon } from "../../../../../images";

interface MailSettingsProps { handleClose: () => void; }



const LazyAccountsTab = lazy(() => import("./tabs/AccountsTab/AccountsTab"));
const LazyAppearanceTab = lazy(
  () => import("./tabs/AppearanceTab/AppearanceTab")
);
const LazyAutomaticRepliesTab = lazy(
  () => import("./tabs/AutomaticRepliesTab/AutomaticRepliesTab")
);
const LazyCleanAndJunkTab = lazy(
  () => import("./tabs/CleanAndJunkTab/CleanAndJunkTab")
);
const LazyGeneralTab = lazy(() => import("./tabs/GeneralTab/GeneralTab"));
const LazyRulesTab = lazy(() => import("./tabs/RulesTab/RulesTab"));



const MailSettings: React.FC<MailSettingsProps> = ({ handleClose }) => {
  const [tabValue, setTabValue] = React.useState(0);

  const tabs: {
    label: string;
    content: React.ReactNode;
  }[] = [
      {
        label: "Appearance",
        content: <LazyAppearanceTab />,
      },
      {
        label: "General",
        content: <LazyGeneralTab />,
      },
      {
        label: "Rules",
        content: <LazyRulesTab />,
      },
      {
        label: "Clean & Junk",
        content: <LazyCleanAndJunkTab />,
      },
      {
        label: "Automatic Replies",
        content: <LazyAutomaticRepliesTab />,
      },
      {
        label: "Accounts",
        content: <LazyAccountsTab handleClose={handleClose} />,
      },
    ];

  return (
    <Stack
      sx={{
        bgcolor: "white",
        width: "100%",
        height: "100%",
        boxSizing: "border-box",
        padding: "20px",
        gap: "20px",
      }}
    >
      <Stack
        sx={{
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <Typography
          sx={{
            font: "normal normal 900 20px/25px Source Serif Pro",
            marginBottom: "10px",
          }}
        >
          Mail Setting
        </Typography>

        <Tooltip title="Close" placement="top" arrow>
          <IconButton onClick={(event) => handleClose()} sx={{ p: 1 }} disableRipple>
            <XCircleIcon w={20} color="black" />
          </IconButton>
        </Tooltip>
      </Stack>
      <TabsWithState
        ariaLabel="Email details tabs"
        value={tabValue}
        onChange={(event, newValue) => setTabValue(newValue)}
        tabIndicatorColor="#008D4C"
        sx={{
          maxWidth: "max-content",
          gap: "20px",
          borderBottom: "1px solid #E9EDF2",
          "& .css-1aquho2-MuiTabs-indicator": {
            bottom: "0px !important",
          },
        }}
      >
        {tabs.map((tab, index) => (
          <StyledTab
            style={{
              font: "normal normal 400 18px/23px Source Serif Pro",
            }}
            key={index}
            disableRipple
            label={tab.label}
            {...a11yProps(index)}
          />
        ))}
      </TabsWithState>

      {tabs.map((tab, index) => (
        <TabPanel
          style={{ display: "contents" }}
          key={index}
          value={tabValue}
          index={index}
        >
          <Suspense fallback={<div>Loading...</div>}>{tab.content}</Suspense>
        </TabPanel>
      ))}
    </Stack>
  );
};

export default MailSettings;
