import { Divider, ListItem, Stack, Typography } from "@mui/material";
import React from "react";
import styles from "./MessageDetailsMenus.module.css";
import { MessageDetailsMenusListInterface } from "./MessageDetailsMenusType";

interface MessageDetailsMenusProps {
  options?: MessageDetailsMenusListInterface[];
  handleClick?:any
}

const MessageDetailsMenus: React.FC<MessageDetailsMenusProps> = ({
  options,
  handleClick
}) => {
  return (
    <Stack  className={styles["message-details-menus"]} style={{zIndex:"101",position:"fixed"}}>
      {options?.map((item, index) => {
        const isFirst = index === 0;
        const isLast = index === options?.length - 1;

        return item.divider ? (
          <Divider
            key={index}
            className={styles["message-details-menus__divider"]}
          />
        ) : (
          <ListItem
            button
            onClick={() => handleClick(item)}
            key={index}
            className={`${styles["message-details-menus__item"]} ${
              isFirst
                ? styles["message-details-menus__item--first"]
                : isLast
                  ? styles["message-details-menus__item--last"]
                  : ""
            }`}
          >
            <Typography
              className={styles["message-details-menus__text"]}
              sx={{ color: item.textColor }}
            >
              {item.value}
            </Typography>
          </ListItem>
        );
      })}
    </Stack>
  );
};

export default MessageDetailsMenus;
