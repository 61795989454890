// menuUtils.ts

export type MenuItemData = {
    icon?: React.ReactNode;
    label?: string;
    draggable?: boolean;
    help?: boolean;
    divider?: boolean;
    inputType?: boolean;
};


export const handleDragStart = (
    event: React.DragEvent<HTMLDivElement>,
    index: number
) => {
    event.dataTransfer.setData("index", index?.toString());
};

export const handleDrop = (
    event: React.DragEvent<HTMLDivElement>,
    menuItems: MenuItemData[],
    setMenuItems: React.Dispatch<React.SetStateAction<MenuItemData[]>>
) => {
    event.preventDefault();
    const fromIndex = Number(event.dataTransfer.getData("index"));
    const toIndex = Number(event.currentTarget.getAttribute("data-index"));
    const updatedItems = [...menuItems];
    const [draggedItem] = updatedItems.splice(fromIndex, 1);
    updatedItems.splice(toIndex, 0, draggedItem);
    setMenuItems(updatedItems);
};

export const allowDrop = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
};

export const handleKeyPress = (
    event: React.KeyboardEvent<HTMLInputElement>,
    label: string,
    setMenuItems: React.Dispatch<React.SetStateAction<MenuItemData[]>>
) => {
    if (event.key === "Enter") {
        event.preventDefault();
        const newItem: MenuItemData = {
            label,
            draggable: true,
        };
        setMenuItems((prevItems) => [
            ...prevItems.slice(0, -1),
            newItem,
            prevItems[prevItems.length - 1],
        ]);
    }
};

export const handleAddOption = (
    label: string,
    icon: React.ReactNode,
    setMenuItems: React.Dispatch<React.SetStateAction<MenuItemData[]>>
) => {
    if (label?.trim() !== "") {
        const newItem: MenuItemData = {
            icon,
            label,
            draggable: true,
        };
        setMenuItems((prevItems) => [
            ...prevItems.slice(0, -1),
            newItem,
            prevItems[prevItems.length - 1],
        ]);
    }
};
