import React from "react";
import {
  ThreeDotLargeMenuHeaderOptionsInterface,
  ThreeDotLargeMenuItemProps,
  ThreeDotMenuLarge,
} from "../../../../common";
import {
  AnalyticsIcon,
  ArchiveIcon,
  BearishOSIcon,
  CheckedCircleIcon,
  ClockIcon,
  DeleteIcon,
  DocumentIcon,
  EmailIcon,
  EyeIcon,
  ForwardIcon,
  ProjectIcon,
  ReplyIcon,
  RightArrowIcon,
  StrongestrelationshipIcon,
} from "../../../../../images";
import {
  Dialog,
  DialogContent,
  IconButton,
  Popover,
  Stack,
  Typography,
} from "@mui/material";
import { ArchiveSuccess } from "../ArchiveSuccess";
import { MoveEmail } from "../MoveEmail";
import { AddEmailtoTask } from "../AddEmailtoTask";

interface EmailClickedMenuProps {
  tooltipText?: string;
  displayOption?: "left" | "right";
}

const OptionsList: ThreeDotLargeMenuItemProps[] = [
  { text: "Reply all", icon: ReplyIcon },
  { text: "Archive", icon: ArchiveIcon },
  { text: "Mark as read", icon: EyeIcon },
  { text: "Move to", icon: RightArrowIcon },
  {
    text: "Delete",
    icon: DeleteIcon,
    iconProps: { color: "#FF4500" },
  },
  { divider: true },
  { text: "Mark as unread", icon: EmailIcon },
  { text: "Open contact card", icon: StrongestrelationshipIcon },
  { text: "View analytics", icon: AnalyticsIcon },
  { text: "Print PDF", icon: DocumentIcon },
  { divider: true },
  { text: "Add to task", icon: CheckedCircleIcon },
  {
    text: "Connect to project",
    icon: ProjectIcon,
    iconProps: { color: "black", w: 15 },
  },
  {
    text: "Send in text",
    icon: BearishOSIcon,
    iconProps: { color: "black", w: 15 },
  },
];

export const ThreeDotLargeMenuHeaderOptions: ThreeDotLargeMenuHeaderOptionsInterface[] =
  [
    {
      textOrIcon: ReplyIcon,
      title: "Reply",
      titleStyle: { font: "normal normal 300 13px/16px Source Serif Pro" },
    },
    {
      textOrIcon: ClockIcon,
      title: "Snooze",
      titleStyle: { font: "normal normal 300 13px/16px Source Serif Pro" },
    },
    {
      textOrIcon: ForwardIcon,
      title: "Forward",
      titleStyle: { font: "normal normal 300 13px/16px Source Serif Pro" },
    },
  ];

const EmailClickedMenu: React.FC<EmailClickedMenuProps> = ({
  tooltipText,
  displayOption,
}) => {
  const [openModal, setOpenModal] = React.useState(false);
  const [selectedOption, setSelectedOption] = React.useState<string | null>(
    null
  );

  const handleClick = (title: string) => {
    setSelectedOption(title);
    handleAction(title); // Call the action handler
    setOpenModal(true); // Open the modal
  };

  const handleClose = () => {
    setOpenModal(false);
    setSelectedOption(null);
  };
  const handleAction = (title: string) => {
    switch (title) {
      case "Reply all":
        console.log("Reply all clicked");
        break;
      case "Archive":
        setOpenModal(true); // Open the modal without calling handleClick
        break;
      case "Mark as read":
        console.log("Mark as read clicked");
        break;
      case "Move to":
        setOpenModal(true); 
        break;
      case "Delete":
        console.log("Delete clicked");
        break;
      case "Mark as unread":
        console.log("Mark as unread clicked");
        break;
      case "Open contact card":
        console.log("Open contact card clicked");
        break;
      case "View analytics":
        console.log("View analytics clicked");
        break;
      case "Print PDF":
        console.log("Print PDF clicked");
        break;
      case "Add to task":
        setOpenModal(true); 
        break;
      case "Connect to project":
        setOpenModal(true); 
        break;
      case "Send in text":
        console.log("Send in text clicked");
        break;
      default:
        console.log("Unknown action");
    }
  };
  
  const renderModalContent = () => {
    switch (selectedOption) {
      case "Archive":
        return <ArchiveSuccess />;
      case "Move to":
        return <MoveEmail />;
      case "Add to task":
        return <AddEmailtoTask />;
      case "Connect to project":
        return <AddEmailtoTask />;
      default:
        return null; // Fallback if no option matches
    }
  };
  
  return (
    <>
      <ThreeDotMenuLarge
        options={OptionsList.map((option) => ({
          ...option,
        }))}
        headersOptions={ThreeDotLargeMenuHeaderOptions}
        displayOption={displayOption}
        tooltipText={tooltipText}
        getClickedOption={(title: any) => handleClick(title)}
      />

      <Dialog open={openModal} onClose={handleClose}>
        <DialogContent>
        {renderModalContent()}
        </DialogContent>
      </Dialog>
    </>
  );
};

export default EmailClickedMenu;
