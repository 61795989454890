import { Box, Stack, Typography } from "@mui/material";
import React from "react";
import { adjustHexColor } from "../../../../../Utils";
import { StreamBearishOSLogoIconPng } from "../../../../../images";
import styles from "./UpcomingCallCard.module.css";

interface UpcomingCallCardProps {
  isSelected?: boolean;
}

const UpcomingCallCard: React.FC<UpcomingCallCardProps> = ({
  isSelected = false,
}) => {
  return (
    <Stack className={styles.upcomingCallCard}>
      <Stack className={styles.upcomingCallCard__indicator} />
      <Stack
        className={styles.upcomingCallCard__content}
        sx={{ bgcolor: isSelected ? adjustHexColor("#E9EDF2", 35) : "inherit" }}
      >
        <Stack className={styles.upcomingCallCard__header}>
          <Box
            component={"img"}
            src={StreamBearishOSLogoIconPng}
            sx={{ height: "20px", width: "20px" }}
            alt="logo"
          />
          <Typography className={styles.upcomingCallCard__meetingName}>
            Meeting name here Lorem ipsum dolor sit amet.
          </Typography>
        </Stack>
        <Typography className={styles.upcomingCallCard__time}>
          08:00 am - 10:00 am PST
        </Typography>
      </Stack>
    </Stack>
  );
};

export default UpcomingCallCard;
