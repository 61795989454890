import { createSlice } from "@reduxjs/toolkit";

import {
    createCalendar__Request,
    createCalendar__Failure,
    createCalendar__Success,
    getAllCalendars__Request,
    getAllCalendars__Failure,
    getAllCalendars__Success,
    getOneCalendar__Request,
    getOneCalendar__Failure,
    getOneCalendar__Success,
    updateCalendar__Request,
    updateCalendar__Failure,
    updateCalendar__Success,
    deleteCalendar__Request,
    deleteCalendar__Failure,
    deleteCalendar__Success,
    createCalendarSettings__Request,
    createCalendarSettings__Failure,
    createCalendarSettings__Success,
    getCalendarSettings__Request,
    getCalendarSettings__Failure,
    getCalendarSettings__Success,
    updateCalendarSettings__Request,
    updateCalendarSettings__Failure,
    updateCalendarSettings__Success,
    addConnectedCalendar__Request,
    addConnectedCalendar__Failure,
    addConnectedCalendar__Success,
    removeConnectedCalendar__Request,
    removeConnectedCalendar__Failure,
    removeConnectedCalendar__Success,
    createEvent__Request,
    createEvent__Failure,
    createEvent__Success,
    getAllEvents__Request,
    getAllEvents__Failure,
    getAllEvents__Success,
    createTask__Request,
    createTask__Failure,
    createTask__Success,
    getAllTasks__Request,
    getAllTasks__Failure,
    getAllTasks__Success,
    createBookingLink__Request,
    createBookingLink__Failure,
    createBookingLink__Success,
    getBookingLinks__Request,
    getBookingLinks__Failure,
    getBookingLinks__Success,
    eventResponse__Request,
    eventResponse__Failure,
    eventResponse__Success,
} from "./CalendarSliceAction";

import { CalendarState } from "./CalendarType";

const initialState: CalendarState = {
    error: null,
    data: null,
};

const CalendarSlice = createSlice({
    name: "calendar", // Name of the slice
    initialState, // Initial state of the slice
    reducers: {
        createCalendar__Request,
        createCalendar__Failure,
        createCalendar__Success,

        getAllCalendars__Request,
        getAllCalendars__Failure,
        getAllCalendars__Success,

        getOneCalendar__Request,
        getOneCalendar__Failure,
        getOneCalendar__Success,

        updateCalendar__Request,
        updateCalendar__Failure,
        updateCalendar__Success,

        deleteCalendar__Request,
        deleteCalendar__Failure,
        deleteCalendar__Success,

        createCalendarSettings__Request,
        createCalendarSettings__Failure,
        createCalendarSettings__Success,

        getCalendarSettings__Request,
        getCalendarSettings__Failure,
        getCalendarSettings__Success,

        updateCalendarSettings__Request,
        updateCalendarSettings__Failure,
        updateCalendarSettings__Success,

        addConnectedCalendar__Request,
        addConnectedCalendar__Failure,
        addConnectedCalendar__Success,

        removeConnectedCalendar__Request,
        removeConnectedCalendar__Failure,
        removeConnectedCalendar__Success,

        createEvent__Request,
        createEvent__Failure,
        createEvent__Success,

        getAllEvents__Request,
        getAllEvents__Failure,
        getAllEvents__Success,

        createTask__Request,
        createTask__Failure,
        createTask__Success,

        getAllTasks__Request,
        getAllTasks__Failure,
        getAllTasks__Success,

        createBookingLink__Request,
        createBookingLink__Failure,
        createBookingLink__Success,

        getBookingLinks__Request,
        getBookingLinks__Failure,
        getBookingLinks__Success,

        eventResponse__Request,
        eventResponse__Failure,
        eventResponse__Success,
        /* Get all fileUploads */
    },
});

export const {
    /* Create Calendar actions */
    createCalendar__Request: createCalendar__RequestAction,
    createCalendar__Failure: createCalendar__FailureAction,
    createCalendar__Success: createCalendar__SuccessAction,

    /* Get all Calendars actions */
    getAllCalendars__Request: getAllCalendars__RequestAction,
    getAllCalendars__Failure: getAllCalendars__FailureAction,
    getAllCalendars__Success: getAllCalendars__SuccessAction,

    /* Get one Calendar actions */
    getOneCalendar__Request: getOneCalendar__RequestAction,
    getOneCalendar__Failure: getOneCalendar__FailureAction,
    getOneCalendar__Success: getOneCalendar__SuccessAction,

    /* Update Calendar actions */
    updateCalendar__Request: updateCalendar__RequestAction,
    updateCalendar__Failure: updateCalendar__FailureAction,
    updateCalendar__Success: updateCalendar__SuccessAction,

    /* Delete Calendar actions */
    deleteCalendar__Request: deleteCalendar__RequestAction,
    deleteCalendar__Failure: deleteCalendar__FailureAction,
    deleteCalendar__Success: deleteCalendar__SuccessAction,

    /* Create Calendar Settings actions */
    createCalendarSettings__Request: createCalendarSettings__RequestAction,
    createCalendarSettings__Failure: createCalendarSettings__FailureAction,
    createCalendarSettings__Success: createCalendarSettings__SuccessAction,

    /* Get Calendar Settings actions */
    getCalendarSettings__Request: getCalendarSettings__RequestAction,
    getCalendarSettings__Failure: getCalendarSettings__FailureAction,
    getCalendarSettings__Success: getCalendarSettings__SuccessAction,

    /* Update Calendar Settings actions */
    updateCalendarSettings__Request: updateCalendarSettings__RequestAction,
    updateCalendarSettings__Failure: updateCalendarSettings__FailureAction,
    updateCalendarSettings__Success: updateCalendarSettings__SuccessAction,

    /* Add Connected Calendar actions */
    addConnectedCalendar__Request: addConnectedCalendar__RequestAction,
    addConnectedCalendar__Failure: addConnectedCalendar__FailureAction,
    addConnectedCalendar__Success: addConnectedCalendar__SuccessAction,

    /* Remove Connected Calendar actions */
    removeConnectedCalendar__Request: removeConnectedCalendar__RequestAction,
    removeConnectedCalendar__Failure: removeConnectedCalendar__FailureAction,
    removeConnectedCalendar__Success: removeConnectedCalendar__SuccessAction,

    /* Create Event actions */
    createEvent__Request: createEvent__RequestAction,
    createEvent__Failure: createEvent__FailureAction,
    createEvent__Success: createEvent__SuccessAction,

    /* Get all Events actions */
    getAllEvents__Request: getAllEvents__RequestAction,
    getAllEvents__Failure: getAllEvents__FailureAction,
    getAllEvents__Success: getAllEvents__SuccessAction,

    /* Create Task actions */
    createTask__Request: createTask__RequestAction,
    createTask__Failure: createTask__FailureAction,
    createTask__Success: createTask__SuccessAction,

    /* Get all Tasks actions */
    getAllTasks__Request: getAllTasks__RequestAction,
    getAllTasks__Failure: getAllTasks__FailureAction,
    getAllTasks__Success: getAllTasks__SuccessAction,

    /* Create Booking Link actions */
    createBookingLink__Request: createBookingLink__RequestAction,
    createBookingLink__Failure: createBookingLink__FailureAction,
    createBookingLink__Success: createBookingLink__SuccessAction,

    /* Get Booking Links actions */
    getBookingLinks__Request: getBookingLinks__RequestAction,
    getBookingLinks__Failure: getBookingLinks__FailureAction,
    getBookingLinks__Success: getBookingLinks__SuccessAction,

    /* Event Response actions */
    eventResponse__Request: eventResponse__RequestAction,
    eventResponse__Failure: eventResponse__FailureAction,
    eventResponse__Success: eventResponse__SuccessAction,
} = CalendarSlice.actions;

export default CalendarSlice.reducer;
