import React, {
  useState,
  FC,
  ChangeEvent,
  useEffect,
  KeyboardEvent,
} from "react";
import { InputBase, Stack } from "@mui/material";
import g from "./SearchBar.module.css";
import { SearchIcon } from "../../../../../../../images";

// Define the props interface with TypeScript
interface SearchBarProps {
  placeholder?: string;
  font?: string;
  borderLine?: boolean;
  iconSize?: number;
  onSearch?: (value: string) => void;
  setClearInput?: boolean;
  customStyles?: React.CSSProperties;
  onKeyDown?: any;
  borderRadius?:any;
}

const SearchBar: FC<SearchBarProps> = ({
  placeholder = "Search…",
  font = "normal normal normal 14px/20px Source Serif Pro",
  borderLine = true,
  iconSize = 20,
  onSearch,
  setClearInput,
  customStyles = {},
  onKeyDown,
  borderRadius
}) => {
  const [searchTerm, setSearchTerm] = useState<string>("");

  const handleSearchChange = (event: ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    setSearchTerm(value);
    if (onSearch) {
      onSearch(value);
    }
  };

  const handleKeyPress = (event: KeyboardEvent<HTMLInputElement>) => {
    if (event.key === "Enter") {
      event.preventDefault();
      onKeyDown();
    }
  };

  useEffect(() => {
    if (searchTerm) {
      setSearchTerm("");
    }
  }, [setClearInput]);

  return (
    <Stack
      direction="row"
      alignItems="center"
      justifyContent="flex-start"
      gap={0.5}
      borderRadius={borderRadius}
      className={borderLine ? g.searchParamBorder : ""}
    >
      <SearchIcon style={{marginLeft: '12px',marginRight:"8px" }} w={iconSize} />
      <InputBase
        type="search"
        placeholder={placeholder}
        sx={{ font, ...customStyles }}
        value={searchTerm}
        onKeyDown={handleKeyPress}
        onChange={handleSearchChange}
        className={g.searchParams}
      />
    </Stack>
  );
};

export default SearchBar;
