import { Stack, Typography } from "@mui/material";
import React from "react";
import UpcomingCallCard from "../UpcomingCallCard/UpcomingCallCard";

interface UpcomingCallCardContainerProps {
  maxheight?: string;
  labelText?: string;
  gap?: string;
}

const UpcomingCallCardContainer: React.FC<UpcomingCallCardContainerProps> = ({
  labelText,
  gap = "10px",
  maxheight = "350px",
}) => {
  return (
    <Stack sx={{ width: "100%", maxHeight: maxheight, bgcolor: "white",marginTop:"10px",padding:"10px" }}>
      <Stack
        sx={{
          flexDirection: "row",
          alignItems: "center",
          gap: "10px",
          marginBottom: "10px",
        }}
      >
        <Typography
          sx={{ font: "normal normal 600 15px/19px Source Serif Pro" }}
        >
          {labelText}
        </Typography>
        <Stack
          sx={{
            height: "1px",
            borderRadius: "2px",
            flex: 1,
            bgcolor: "#E9EDF2",
          }}
        />
      </Stack>
      <Stack sx={{ gap: gap, overflow: "auto", scrollbarWidth: "none" }}>
        <UpcomingCallCard />
      </Stack>
    </Stack>
  );
};

export default UpcomingCallCardContainer;
