import * as React from "react";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import { IconButton } from "@mui/material";
import { GearIcon } from "../../../../../images";

export default function ColorTabsEmail() {
  const [value, setValue] = React.useState("one");

  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setValue(newValue);
  };

  return (
    <Box
      sx={{
        width: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "flex-start",
      }}
    >
      <Tabs
        value={value}
        onChange={handleChange}
        textColor="inherit" // Change to primary or custom color as needed
        aria-label="secondary tabs example"
        sx={{
          padding: 0, // Reduce padding
          "& .MuiTab-root": {
            fontFamily: "Source Serif Pro", // Change font
            fontWeight: 500, // Adjust font weight if needed
            padding: "4px 8px", // Reduce padding for each tab,
            fontSize: "15px",
            textTransform:"capitalize"
          },
          "& .MuiTabs-indicator": {
            backgroundColor: "#008D4C", // Set indicator color to orange
          },
        }}
      >
        <Tab value="one" label="New Updates" />
        <Tab value="two" label="Done" />
        <Tab value="three" label="Snoozed" />
        <Tab value="four" label="Priority" />
      </Tabs>

      <IconButton>
        <GearIcon w={15} />
      </IconButton>
    </Box>
  );
}
