import { Dialog, Popper, Stack, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { SmallThreeDotMenu } from "../../../../SmallThreeDotMenu";
import { adjustHexColor } from "../../../../../../Utils";
import { SmallThreeDotMenuListInterface } from "../../../../SmallThreeDotMenu/SmallThreeDotMenuTypes";
import {
  CopyIcon,
  EditPencilIcon,
  ForwardIcon,
  ReplyIcon,
  TranslateIcon,
} from "../../../../../../images";
import { useDispatch, useSelector } from "react-redux";
import { AuthState } from "../../../../../../redux";
import { RootState } from "../../../../../../redux/store";
import { MessagesState } from "../../../../../../redux/Slice/message/messageTypes";
import {
  addReactionToMessage,
  forwardMessage,
  getUserConnections,
} from "../../../../../../redux/Slice/message/messageActions";
import {
  addEditMessageContentAction,
  addReplyMessageContentAction,
} from "../../../../../../redux/Slice/message/messageSlice";
import { MessageReplyBarView } from "../../../../MessageReplyBar";
import dayjs from "dayjs";
import DOMPurify from "dompurify";
import { MessageHistory } from "../../../../MessageHistory";
import { ForwardMessage } from "../../../../ForwardMessage";
import { EmojiPickerSmall } from "../../../../EmojiPickerSmall";
import { ChatEmojiPreview } from "../../../../ChatEmojiPreview";
import {getHTMLContent} from "../../../../../pages/Message/constant";

export interface QuickMessagePreviewViewProps {
  messageType?: "send" | "received" | string;
  messageSender?: string;
  messageText?: any;
  timeStamp?: string;
  messageId?: any;
  replyMessage?: any;
  isEdited?: boolean;
  displayName?: string;
  editMessageHistory?: any;
  reaction?: any;
}

const QuickMessagePreviewView: React.FC<QuickMessagePreviewViewProps> = ({
  messageSender,
  messageText,
  messageType,
  timeStamp,
  messageId,
  replyMessage,
  isEdited,
  displayName,
  editMessageHistory,
  reaction,
}) => {
  const { user } = useSelector((state: RootState) => state.auth as AuthState);
  const { channels, currentChannelId, currentChannel } = useSelector(
    (state: RootState) => state.message as MessagesState
  );

  const dispatch = useDispatch();
  const formattedTime = dayjs(timeStamp).format("hh:mm A");

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [selectedEmoji, setSelectedEmoji] = useState<string>("");
  const [openForwardMessagePopup, setOpenForwardMessagePopup] =
    useState<boolean>(false);
  const [selectUsersForForwardMessage, setSelectUsersForForwardMessage] =
    useState<any>([]);

  useEffect(() => {
    const handleaddEmoji = async () => {
      if (selectedEmoji) {
        const payload = {
          emoji: selectedEmoji,
          userId: user?.data?._id,
          messageId: messageId,
          channelId: currentChannelId,
        };
        await dispatch(addReactionToMessage(payload));
        setSelectedEmoji("");
      }
    };
    handleaddEmoji();
  }, [selectedEmoji]);

  const handleMouseEnter = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMouseLeave = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "hover-popper" : undefined;
  const sanitizedHtml = (html: any) => {
    return DOMPurify.sanitize(html);
  };

  const handleMenuAction = (value: string) => {
    if (value === "Copy") {
      if (messageText) {
        const tempDiv = document.createElement("div");
        tempDiv.innerHTML = messageText;

        const plainText: any = tempDiv?.innerText || tempDiv?.textContent;

        navigator.clipboard.writeText(plainText).then(
          () => console.log("Message copied to clipboard!"),
          (err) => console.error("Failed to copy message:", err)
        );
      }
    }
    if (value === "Edit") {
      if (messageText) {
        let editedMessageContent = {
          content: sanitizedHtml(messageText[0]),
          id: messageId,
        };
        dispatch(addReplyMessageContentAction(null));
        dispatch(addEditMessageContentAction(editedMessageContent));
      }
    }
    if (value === "Reply") {
      console.log("reply");
      let payload = {
        content: messageText,
        id: messageId,
      };
      dispatch(addEditMessageContentAction(null));
      dispatch(addReplyMessageContentAction(payload));
    }
    if (value === "Forward") {
      setOpenForwardMessagePopup(true);
    }
  };
  const handleForwardMessage = async () => {
    const recipientIdsArray = selectUsersForForwardMessage?.map(
      (user: any) => user._id
    );
    console.log("Forward");
    let payload = {
      userId: user?.data?._id,
      recipientIds: recipientIdsArray,
      messageId: messageId,
    };
    await dispatch(forwardMessage(payload));
    await dispatch(getUserConnections(user?.data._id));
    setOpenForwardMessagePopup(false);
  };

  const handleClickOnCancel = () => {
    setOpenForwardMessagePopup(false);
    setSelectUsersForForwardMessage([]);
  };

  return (
    <Stack
      sx={{
        gap: "5px",
        boxSizing: "border-box",
        alignItems: messageType === "received" ? "start" : "end",
        marginTop: "18px",
      }}
    >
      <Stack sx={{ flexDirection: "row", alignItems: "center", gap: "15px" }}>
        <Dialog onClose={handleClickOnCancel} open={openForwardMessagePopup}>
          <ForwardMessage
            setSelectUsersForForwardMessage={setSelectUsersForForwardMessage}
            usersList={channels}
            onClickSendButton={handleForwardMessage}
            onClickCancelButton={handleClickOnCancel}
            setOpenForwardMessagePopup={setOpenForwardMessagePopup}
          />
        </Dialog>
        {isEdited && (
          <Stack
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
          >
            <Typography
              sx={{
                font: "normal normal 300 11px/14px Source Serif Pro",
                cursor: "pointer",
              }}
            >
              Edited
            </Typography>

            <Popper id={id} open={open} anchorEl={anchorEl} placement="bottom">
              <MessageHistory
                styles={{ marginLeft: messageType === "send" ? "0" : "280px" }}
                editMessageHistory={editMessageHistory}
              />
            </Popper>
          </Stack>
        )}
        <Typography
          sx={{ font: "normal normal 900 11px/14px Source Serif Pro" }}
        >
          {messageType === "send" ? "You" : displayName}, {formattedTime}
        </Typography>
      </Stack>
      <Stack
        sx={{
          flexDirection: messageType === "received" ? "row" : "row-reverse",
          gap: "8px",
          alignItems: "start",
          boxSizing: "border-box",
        }}
      >
        <Stack>
          <Typography
            sx={{
              bgcolor:
                messageType === "received"
                  ? adjustHexColor("#775EE2", 25)
                  : adjustHexColor("#F5F5F5", 25),
              font: "normal normal 300 13px/16px Source Serif Pro",
              borderRadius: "5px",
              border: `1px solid ${adjustHexColor("#E9EDF2", 30)}`,
              padding: "3px 5px",
              boxSizing: "border-box",
              wordBreak: "break-word",
              // make this css true when this component have emoji,
              paddingBottom: reaction ? "10px" : "5px",
            }}
          >
            <MessageReplyBarView replyMessage={replyMessage} />
            {/* <div
              dangerouslySetInnerHTML={{ __html: messageText }}
            /> */}
            <div dangerouslySetInnerHTML={{ __html: getHTMLContent(messageText) }} />
          </Typography>
          <Stack
            sx={{
              flexDirection: "row",
              flexWrap: "wrap",
              gap: "3px",
              position: "relative",
              bottom: "10px",
              padding: "0px 5px",
            }}
          >
            {reaction?.map((item: any, i: any) => {
              return (
                <ChatEmojiPreview
                  messageId={messageId}
                  userId={user?.data._id}
                  emoji={item}
                  key={i}
                />
              );
            })}
          </Stack>
        </Stack>

        <Stack
          sx={{
            flexDirection: "row",
            gap: "8px",
            boxSizing: "border-box",
            alignItems: "center",
          }}
        >
          {!currentChannel?.currentUserLeave && (
            <>
              <EmojiPickerSmall
                componentIcon={"😊"}
                onSelect={(emoji: any) => setSelectedEmoji(emoji.unified)}
              />
              <SmallThreeDotMenu
                menuItems={
                  messageType === "received"
                    ? MESSAGE_OPTIONS_LIST_FOR_RECIVER
                    : MESSAGE_OPTIONS_LIST_FOR_SEND
                }
                onClickAction={handleMenuAction}
                displayOption={messageType === "received" ? "left" : "right"}
              />
            </>
          )}
        </Stack>
      </Stack>
    </Stack>
  );
};

export default QuickMessagePreviewView;

const MESSAGE_OPTIONS_LIST_FOR_RECIVER: SmallThreeDotMenuListInterface[] = [
  {
    id: 1,
    icon: CopyIcon,
    text: "Copy",
  },
  {
    id: 2,
    icon: ReplyIcon,
    text: "Reply",
  },
  {
    id: 3,
    icon: ForwardIcon,
    text: "Forward",
  },
  {
    id: 4,
    icon: TranslateIcon,
    text: "Translate",
  },
];

const MESSAGE_OPTIONS_LIST_FOR_SEND: SmallThreeDotMenuListInterface[] = [
  {
    id: 1,
    icon: EditPencilIcon,
    text: "Edit",
  },
  {
    id: 2,
    icon: CopyIcon,
    text: "Copy",
  },
  {
    id: 3,
    icon: ReplyIcon,
    text: "Reply",
  },
  {
    id: 4,
    icon: ForwardIcon,
    text: "Forward",
  },
  {
    id: 5,
    icon: TranslateIcon,
    text: "Translate",
  },
];
