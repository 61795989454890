import { Stack, Tooltip, Typography } from "@mui/material";
import React from "react";
import { CheckboxMedium } from "../../../../../../common";

interface ListProps {
  name: string;
  isSelected: boolean;
}

interface AdvancedActionsViewProps {
  onChange: (list: ListProps[]) => void;
}

const initialFilterList: ListProps[] = [
  {
    name: "Move to",
    isSelected: false,
  },
  {
    name: "Mark as read",
    isSelected: false,
  },
  {
    name: "Mark important (star)",
    isSelected: false,
  },
  {
    name: "Move to trash",
    isSelected: false,
  },
];

const AdvancedActionsView: React.FC<AdvancedActionsViewProps> = ({
  onChange,
}) => {
  const [filterList, setFilterList] = React.useState(initialFilterList);

  const handleClick = (index: number) => {
    const updatedList = filterList.map((item, i) =>
      i === index ? { ...item, isSelected: !item.isSelected } : item
    );
    setFilterList(updatedList);
    onChange(updatedList); // Call the onChange prop with the updated list
  };
  return (
    <Stack gap={"15px"} mt={"5px"}>
      <Tooltip
        title={
          <Typography
            sx={{
              font: "normal normal 300 11px/15px Source Serif Pro",
              maxWidth: "125px",
            }}
          >
            By default smart filters will create a folder/label and
            automatically move emails there. Clicking an advanced action will
            change the default moving option.
          </Typography>
        }
        placement="right-end"
      >
        <Typography
          sx={{
            font: "normal normal 300 14px/18px Source Serif Pro",
            width: "fit-content",
          }}
        >
          Advanced Actions
        </Typography>
      </Tooltip>
      {filterList.map((item, index) => (
        <Stack
          key={index}
          sx={{
            flexDirection: "row",
            alignItems: "center",
            gap: "5px",
            cursor: "pointer",
          }}
          onClick={() => handleClick(index)}
        >
          <CheckboxMedium boxSize={17} checked={item.isSelected} />
          <Typography
            sx={{ font: "normal normal 300 13px/16px Source Serif Pro " }}
          >
            {item.name}
          </Typography>
        </Stack>
      ))}
    </Stack>
  );
};

export default AdvancedActionsView;
