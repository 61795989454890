import { Box } from '@mui/material';
import React, { useState } from 'react'
import { SearchBar } from '../../pages/CRM/components/MenuScroll/components/SearchBar';
import { a11yProps, StyledTab, TabsWithState } from '../CardTabsWithoutIcon';

interface MessageTabBarProps {
  tabs ?: any;
  selectedTab ?: number;
  setSelectedTab ?: (newValue: number) => void;
}

const MessageTabBar: React.FC<MessageTabBarProps> = ({
  tabs,
  selectedTab,
  setSelectedTab,
}) => {
  return (
    <Box
      display="flex"
      padding="0 30px 0 30px"
      flexDirection="column"
      width="100%"
    >
      <Box display="flex" justifyContent="end" alignItems="center" p={2}>
        <SearchBar
          iconSize={15}
          borderRadius="5px"
          font="normal normal 400 11px/14px Source Serif Pro"
          customStyles={{ width: "330px", display: "flex", color: "black" }}
          placeholder="Search all chats"
        />
      </Box>
      <Box display="flex" alignItems="center" p={2}>
        <TabsWithState
          ariaLabel="Email details tabs"
          value={selectedTab}
          onChange={(event, newValue) => setSelectedTab?.(newValue)}
          tabIndicatorColor="#008D4C"
        >
          {tabs.map((tab: any, index: any) => (
            <StyledTab
              style={{
                font: "normal normal 600 13px/16px Source Serif Pro",
                borderBottom: "1px solid #ddd",
              }}
              key={index}
              disableRipple
              label={tab.label}
              {...a11yProps(index)}
            />
          ))}
        </TabsWithState>
      </Box>
    </Box>
  );
};

export default MessageTabBar;