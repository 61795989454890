export interface SmallThreeDotMenuListInterface {
  id: number;
  icon?: React.FC<{ w?: number; color?: string; direction?: "up" | "down" | "left" | "right"; }>;
  text: string;
  color?: string;
  direction?: "up" | "down" | "left" | "right";
}

export const commentsThreeDotMenuList: SmallThreeDotMenuListInterface[] = [
  { id: 1, text: "View Resolved Comments" },
  { id: 2, text: "View Current Comments" },
  { id: 3, text: "View All Comments" },
];
