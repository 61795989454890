import React from "react";
import { EmailCardLong } from "../EmailCardLong";
import { EmailCardSquare } from "../EmailCardSquare";
import { EmailFilterChipsVariantEnum } from "../EmailFilterChips/EmailFilterChipsTypes";
import { Stack } from "@mui/material";
import { useNavigate } from "react-router-dom";

type Email = {
  isSelected: boolean;
  isClicked: boolean;
  isStarted: boolean;
  userName: string;
  userEmail: string;
  userIconSrc: string;
  userAvatarColor: string;
  UserCount: Array<{
    userName: string;
    userEmail: string;
    userIconSrc: string;
    userAvatarColor: string;
  }>;
  subject: string;
  bodyText: string;
  AIFilterChip: EmailFilterChipsVariantEnum;
  AttachmentChipView: Array<{ filename: string }>;
};

type EmailsData = {
  emails: Email[];
};

type Props = {
  viewMode?: string;
  emails: {
    data: EmailsData;
  } | null; // Emails can be null or undefined
};

const EmailCardManager = ({ viewMode = "square", emails }: Props) => {
  const navigate = useNavigate();
  const handleNavigate = () => {
    navigate(`/Email/${1}/${2}`);
  };

  console.log(emails, "emails in EmailCardManager");

  return (
    <Stack
      direction={viewMode === "square" ? "row" : "column"}
      alignItems={"center"}
      flexWrap={viewMode === "square" ? "wrap" : "unset"}
      justifyContent={viewMode === "square" ? "space-between" : "flex-start"}
      gap={"5px"}
    >
      {emails?.data?.emails?.map((email: Email, index: number) => (
        <React.Fragment key={index}>
          {viewMode !== "square" ? (
            <EmailCardLong 
              email={email}
              isClicked={email.isClicked}
              isSelected={email.isSelected}
              handleNavigate={handleNavigate}
            />
          ) : (
            <EmailCardSquare
              email={email}
              isClicked={email.isClicked}
              isSelected={email.isSelected}
              handleNavigate={handleNavigate}
            />
          )}
        </React.Fragment>
      ))}
    </Stack>
  );
};

export default EmailCardManager;
