import { Stack, Typography } from "@mui/material";
import React, { useState, useRef, useEffect } from "react";
import { CheckboxMedium } from "../../../../common";
import { EmailClickedMenu } from "../EmailClickedMenu";
import { EmailFilterChips } from "../EmailFilterChips";
import { EmailFilterChipsVariantEnum } from "../EmailFilterChips/EmailFilterChipsTypes";
import {
  AttachmentView,
  IconsGroupView,
  ReplyButtonView,
  StarToggleView,
  TimeView,
  UserCount,
  UserInfoChip,
} from "../EmailCardComponents";

interface EmailCardSquareProps {
  viewType?: "condensed" | "expanded";
  isSelected?: boolean;
  isClicked?: boolean;
  showReplyButton?: boolean;
  email?: any;
  handleNavigate?: () => void;
}

const EmailCardSquare: React.FC<EmailCardSquareProps> = ({
  viewType = "expanded",
  isSelected = false,
  isClicked = false,
  showReplyButton = false,
  email,
  handleNavigate,
}) => {
  const [isHovered, setIsHovered] = useState(false);
  const [isToggled, setIsToggled] = useState(email?.isStarted);
  const cardRef = useRef<HTMLDivElement | null>(null);
  const menuRef = useRef<HTMLDivElement | null>(null);



  const handleClickOutside = (event: MouseEvent) => {
    if (
      cardRef.current &&
      !cardRef.current.contains(event.target as Node) &&
      menuRef.current &&
      !menuRef.current.contains(event.target as Node)
    ) {
      setIsHovered(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);


  console.log(email, "emailemailemail//")


  return (
    <Stack
      ref={cardRef}
      sx={{
        width: "275px",
        bgcolor: "white",
        padding: "10px 10px 10px 6px",
        boxSizing: "border-box",
        borderRadius: "5px",
        borderBottom: "1px solid #E9EDF2",
        gap: "10px",
        boxShadow: isHovered ? "0px 3px 3px 0px rgba(0, 0, 0, 0.15)" : null,
        borderLeft:
          isSelected || isClicked ? "4px solid #E9EDF2" : "4px solid #fff ",
      }}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      {/* first line components */}
      <Stack
        sx={{
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <Stack sx={{ flexDirection: "row", alignItems: "center", gap: "5px" }}>
          {showReplyButton && <ReplyButtonView />}
          <StarToggleView
            isToogled={isToggled}
            onStarClick={() => setIsToggled((val: any) => !val)}
          />
          <UserInfoChip
            popoverPlace="bottom"
            userName={email?.sender?.emailAddress?.name}
            userEmail={email?.sender?.emailAddress?.address}
            userIconSrc={`https://example.com/avatar${1}.png`}
            userAvatarColor={`#${Math.floor(Math.random() * 16777215).toString(16)}`}
          />
          <UserCount userList={email?.ccRecipients} />
        </Stack>

        <Stack
          sx={{
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "end",
          }}
        >
          {!isHovered ? <TimeView time={email?.createdDateTime} /> : <IconsGroupView email={email} />}
        </Stack>
      </Stack>

      {/* -----  second line ----- */}

      <Stack sx={{ flexDirection: "row", alignItems: "center", gap: "5px" }}>
        <CheckboxMedium boxSize={15} checked={isSelected} />
        <Typography
          sx={{
            font: "normal normal 400 11px/14px Source Serif Pro",
            overflow: "hidden",
            textOverflow: "ellipsis",
            whiteSpace: "nowrap",
          }}
          onClick={() => handleNavigate && handleNavigate()}
        >
          {email?.subject}
        </Typography>
      </Stack>

      {/* ---------------- third line ------------ */}
      {viewType === "expanded" && (
        <Typography
          sx={{
            font: "normal normal 300 11px/14px Source Serif Pro",
            overflow: "hidden",
            textOverflow: "ellipsis",
            whiteSpace: "nowrap",
          }}
        >
          {email?.bodyPreview}
        </Typography>
      )}

      {/* ------- options and attachment -------- */}
      <Stack
        sx={{
          flexDirection: "row",
          justifyContent: viewType === "expanded" ? "space-between" : "end",
        }}
      >
        {viewType === "expanded" && (
          <Stack
            sx={{ flexDirection: "row", alignItems: "center", gap: "5px" }}
          >
            <EmailFilterChips
              variant={email?.folderName}
            />

            <AttachmentView
              AttachmentChipViewData={email?.attachments}
            />
          </Stack>
        )}
        <div ref={menuRef}>
          <EmailClickedMenu />
        </div>
      </Stack>
    </Stack>
  );
};

export default EmailCardSquare;
