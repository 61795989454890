export enum ButtonType {
  Submit = "submit",
  Button = "button",
}

enum ColorVariant {
  Orange = "orange",
  LightOrange = "lightOrange",
  CRMOrange = "crmOrange",
  Green = "green",
  MildGreen = "mildGreen",
  MidGreen = "midGreen",
  LightGreen = "lightGreen",
  White = "white",
  Gray = "gray",
  Blue = "blue",
  MessagePurple = "MessagePurple",
  Transparent = "transparent",
  BlueMessageButton = "#775EE2",
  Black = "black",
  DelayedRed = "delayedRed",
  CalendarMarronPink = 'calendarMarronPink',
  CallsSalmon = 'callsSalmon'
}

export enum SmallButtonColor {
  Orange = ColorVariant.Orange,
  CRMOrange = ColorVariant.CRMOrange,
  LightOrange = ColorVariant.LightOrange,
  Green = ColorVariant.Green,
  MildGreen = ColorVariant.MildGreen,
  MidGreen = ColorVariant.MidGreen,
  White = ColorVariant.White,
  Gray = ColorVariant.Gray,
  MessagePurple = ColorVariant.MessagePurple,
  Transparent = ColorVariant.Transparent,
  Blue = ColorVariant.Blue,
  Black = ColorVariant.Black,
  DelayedRed = ColorVariant.DelayedRed,
  CalendarMarronPink = ColorVariant.CalendarMarronPink,
  CallsSalmon = ColorVariant.CallsSalmon
}

export enum SmallThinButtonColor {
  Orange = ColorVariant.Orange,
  CalendarMarronPink = ColorVariant.CalendarMarronPink,
}


export enum SmallButtonDropdownColor {
  Orange = ColorVariant.Orange,
  Transparent = ColorVariant.Transparent,
}

export enum ButtonXLargeColor {
  Orange = ColorVariant.Orange,
  Green = ColorVariant.Green,
}

export enum MainActionColor {
  Orange = ColorVariant.Orange,
  Green = ColorVariant.Green,
  Blue = ColorVariant.Blue,
  BlueMessageButton = ColorVariant.BlueMessageButton,
}

export enum XlargeCreateButtonColor {
  Orange = ColorVariant.Orange,
  LightOrange = ColorVariant.LightOrange,
  Green = ColorVariant.Green,
}

export enum ObjectiveModalButtonsColor {
  LightGreen = ColorVariant.LightGreen,
  Transparent = ColorVariant.Transparent,
}

export enum SendMessageButtonColor {
  MessagePurple = ColorVariant.MessagePurple,
  Blue = ColorVariant.Blue,
}

export enum InternalShareButtonOptionValue {
  Editor = "Editor",
  Guest = "Guest",
  Commenter = "Commenter",
  Remove = "Remove",
  Admin = "Admin",
}
