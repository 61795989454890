import { PayloadAction } from "@reduxjs/toolkit";
import { CalendarState } from "../CalendarType";

// Successes
export const createCalendar__Success = (
  state: CalendarState,
  action: PayloadAction<any>
) => {
  state.data = action?.payload?.data;
};

export const getAllCalendars__Success = (
  state: CalendarState,
  action: PayloadAction<any>
) => {
  state.data = action?.payload?.data;
};

export const getOneCalendar__Success = (
  state: CalendarState,
  action: PayloadAction<any>
) => {
  state.data = action?.payload?.data;
};

export const updateCalendar__Success = (
  state: CalendarState,
  action: PayloadAction<any>
) => {
  state.data = action?.payload?.data;
};

export const deleteCalendar__Success = (
  state: CalendarState,
  action: PayloadAction<any>
) => {
  state.data = action?.payload?.data;
};

export const createCalendarSettings__Success = (
  state: CalendarState,
  action: PayloadAction<any>
) => {
  state.data = action?.payload?.data;
};

export const getCalendarSettings__Success = (
  state: CalendarState,
  action: PayloadAction<any>
) => {
  state.data = action?.payload?.data;
};

export const updateCalendarSettings__Success = (
  state: CalendarState,
  action: PayloadAction<any>
) => {
  state.data = action?.payload?.data;
};

export const addConnectedCalendar__Success = (
  state: CalendarState,
  action: PayloadAction<any>
) => {
  state.data = action?.payload?.data;
};

export const removeConnectedCalendar__Success = (
  state: CalendarState,
  action: PayloadAction<any>
) => {
  state.data = action?.payload?.data;
};

export const createEvent__Success = (
  state: CalendarState,
  action: PayloadAction<any>
) => {
  state.data = action?.payload?.data;
};

export const getAllEvents__Success = (
  state: CalendarState,
  action: PayloadAction<any>
) => {
  state.data = action?.payload?.data;
};

export const createTask__Success = (
  state: CalendarState,
  action: PayloadAction<any>
) => {
  state.data = action?.payload?.data;
};

export const getAllTasks__Success = (
  state: CalendarState,
  action: PayloadAction<any>
) => {
  state.data = action?.payload?.data;
};

export const createBookingLink__Success = (
  state: CalendarState,
  action: PayloadAction<any>
) => {
  state.data = action?.payload?.data;
};

export const getBookingLinks__Success = (
  state: CalendarState,
  action: PayloadAction<any>
) => {
  state.data = action?.payload?.data;
};

export const eventResponse__Success = (
  state: CalendarState,
  action: PayloadAction<any>
) => {
  state.data = action?.payload?.data;
};
