import * as React from "react";
import { useAutocomplete } from "@mui/base/useAutocomplete";
import { DropdownWithImgBadgeInterface } from "./DropdownWithImageBadgeTypes";
import { AvatarNormal } from "../AvatarNormal";
import { Stack, Typography } from "@mui/material";
import { InputWrapper, Listbox, Root, StyledTag, UserCount } from "./view";

/**
 * Props for the CustomizedHook component.
 */
interface CustomizedHookProps {
  /**
   * The list of user options to display in the autocomplete.
   */
  options?: DropdownWithImgBadgeInterface[];

  /**
   * Initial selected user options.
   */
  initialData?: DropdownWithImgBadgeInterface[];

  /**
   * Callback function that is called when the selected options change.
   * @param selectedOptions - The currently selected user options.
   */
  onChangeSelection: (selectedOptions: DropdownWithImgBadgeInterface[]) => void;

  /**
   * The maximum number of users to display in chips. Any additional users will be shown as "+num".
   * If not provided, all selected users will be displayed as chips.
   * Select positive number only, do not use 0 or negative number here
   */
  showMaxSelected?: number;

  /**
   * If true, users cannot be added by typing an email. Only options from the list can be selected.
   * Default is false.
   */
  disableAddUserInList?: boolean;

  /**
   * Placeholder text to display when no users are selected or typed.
   */
  placeholder?: string;

  /**
   * If set to true, this component will be in read-only mode.
   * In this mode, no options can be selected or deselected.
   * The default value is false.
   */
  readonlyComponent?: boolean;
}

export default function CustomizedHook({
  options,
  initialData = [],
  onChangeSelection,
  showMaxSelected,
  disableAddUserInList = false,
  readonlyComponent = false,
  placeholder,
}: CustomizedHookProps) {
  const [inputValue, setInputValue] = React.useState("");
  const [customOptions, setCustomOptions] = React.useState<
    DropdownWithImgBadgeInterface[]
  >(options || []);

  // Initialize with initialData
  const [selectedUserList, setSelectedUserList] =
    React.useState<DropdownWithImgBadgeInterface[]>(initialData);

  const [primaryUserList, setPrimaryUserList] = React.useState<
    DropdownWithImgBadgeInterface[]
  >([]);

  const [additionalUserList, setAdditionalUserList] = React.useState<
    DropdownWithImgBadgeInterface[]
  >([]);

  const isValidEmail = (email: string) => {
    return /^[^\s@]+@[^\s@]+\.com$/.test(email);
  };

  React.useEffect(() => {
    const lastOptionIndex = customOptions.findIndex(
      (option) => option.name === inputValue && option.email === inputValue
    );

    if (
      isValidEmail(inputValue) &&
      lastOptionIndex === -1 &&
      !disableAddUserInList
    ) {
      // Add the new valid email option
      setCustomOptions([
        ...customOptions,
        { name: inputValue, email: inputValue, userIconSrc: "" },
      ]);
    } else if (!isValidEmail(inputValue) && lastOptionIndex !== -1) {
      // Remove the invalid email option
      const updatedOptions = customOptions.filter(
        (option) => option.name !== inputValue
      );
      setCustomOptions(updatedOptions);
    }
  }, [inputValue, customOptions, options]);

  const userListSeperator = () => {
    if (showMaxSelected && selectedUserList.length > showMaxSelected) {
      setPrimaryUserList(selectedUserList.slice(0, showMaxSelected));
      setAdditionalUserList(selectedUserList.slice(showMaxSelected));
    } else {
      setPrimaryUserList(selectedUserList);
      setAdditionalUserList([]);
    }
  };

  React.useEffect(() => {
    userListSeperator();
  }, [selectedUserList]);

  const changeHandler = (newValue: DropdownWithImgBadgeInterface[]) => {
    setSelectedUserList(newValue);
    onChangeSelection(newValue);
  };

  const {
    getRootProps,
    getInputProps,
    getTagProps,
    getListboxProps,
    getOptionProps,
    groupedOptions,
    value,
    focused,
    setAnchorEl,
  } = useAutocomplete({
    id: "customized-hook-demo",
    multiple: true,
    options: customOptions,
    readOnly: readonlyComponent,
    getOptionLabel: (option) => option.email + option.name,
    defaultValue: initialData,
    inputValue: inputValue,
    onInputChange: (event, newInputValue) => {
      setInputValue(newInputValue);
    },
    onChange: (event, newValue) => {
      changeHandler(newValue);
    },
  });

  return (
    <Root>
      <div {...getRootProps()}>
        <InputWrapper ref={setAnchorEl} className={focused ? "focused" : ""}>
          {primaryUserList.map(
            (option: DropdownWithImgBadgeInterface, index: number) => {
              const { key, ...tagProps } = getTagProps({ index });
              return (
                <StyledTag
                  key={key}
                  {...tagProps}
                  clendarInviteStatus={option.calendarInviteStatus}
                  userData={option}
                  popoverPlace="top"
                />
              );
            }
          )}
          {showMaxSelected && additionalUserList.length > 0 && (
            <UserCount
              getTagProps={getTagProps}
              startIndex={showMaxSelected}
              userList={additionalUserList}
            />
          )}
          <input
            {...getInputProps()}
            readOnly={readonlyComponent}
            value={inputValue}
            style={{ minWidth: "50px" }}
            placeholder={
              placeholder && primaryUserList.length === 0 ? placeholder : ""
            }
          />
        </InputWrapper>
      </div>
      {groupedOptions?.length > 0 ? (
        <Listbox {...getListboxProps()}>
          {(groupedOptions as DropdownWithImgBadgeInterface[])?.map(
            (option, index) => {
              const { ...optionProps } = getOptionProps({ option, index });
              return (
                <li
                  {...optionProps} // Spread props from getOptionProps (including the key)
                  style={{
                    height: "40px",
                    display: "flex",
                    alignItems: "center",
                    flexDirection: "row",
                    gap: "10px",
                  }}
                >
                  <AvatarNormal
                    username={option.name}
                    size={30}
                    imgSrc={option.userIconSrc}
                  />
                  <Stack>
                    <Typography
                      sx={{
                        font: "normal normal 400 14px/18px Source Serif Pro",
                      }}
                    >
                      {option.name}
                    </Typography>
                    <Typography
                      sx={{
                        font: "normal normal 300 12px/14px Source Serif Pro",
                      }}
                    >
                      {option.email}
                    </Typography>
                  </Stack>
                </li>
              );
            }
          )}
        </Listbox>
      ) : null}
    </Root>
  );
}
