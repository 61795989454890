import { IconButton, Link, Stack, Tooltip, Typography } from "@mui/material";
import React from "react";
import { XCircleIcon } from "../../../images";
import { ConnectedItensListCard } from "../ConnectedItensListCard";

interface ProjectsSidebarProps {}

const ProjectsSidebar: React.FC<ProjectsSidebarProps> = ({}) => {
  return (
    <Stack
      sx={{
        width: "385px",
        height: "100%",
        bgcolor: "white",
        padding: "15px 15px 0px 15px",
        boxSizing: "border-box",
      }}
    >
      {/* ----------------    card title + Back button + close button --------------- */}
      <Stack
        sx={{
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "start",
          marginBottom: "10px",
        }}
      >
        <Typography
          sx={{
            font: "normal normal 400 14px/18px Source Serif Pro",
            paddingBottom: "5px",
            borderBottom: "1px solid #E9EDF2",
          }}
        >
          Projects
        </Typography>

        <Tooltip title="close" placement="top" arrow>
          <IconButton sx={{ p: 0 }} disableRipple>
            <XCircleIcon w={15} />
          </IconButton>
        </Tooltip>
      </Stack>
      {/* ----------------    card title + Back button + close button container end here --------------- */}

      {/*---------    scrollable container here  --------- */}

      <Stack
        sx={{
          flex: 1,
          overflowY: "scroll",
          scrollbarWidth: "none",
          gap: "20px",
          paddingBottom: "20px",
        }}
      >
        {/* --- text and connect button or link --- */}
        <Stack
          sx={{
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "space-between",
            font: "normal normal 400 14px/18px Source Serif Pro",
          }}
        >
          <Typography sx={{ font: "inherit" }}>Connected Projects</Typography>
          <Link
            sx={{ font: "inherit", color: "#000AFF", cursor: "pointer" }}
            underline="hover"
          >
            Connect new
          </Link>
        </Stack>
        {/* ----------- */}

        <Stack sx={{ gap: "15px" }}>
          <ConnectedItensListCard />
        </Stack>
      </Stack>
      {/*---------    scrollable container end here  --------- */}
    </Stack>
  );
};

export default ProjectsSidebar;
