import { PayloadAction } from "@reduxjs/toolkit";
import { CalendarState } from "../CalendarType";


// Failures
export const createCalendar__Failure = (
    state: CalendarState,
    action: PayloadAction<string>
) => {
    state.error = action.payload;
    state.data = null;
};

export const getAllCalendars__Failure = (
    state: CalendarState,
    action: PayloadAction<string>
) => {
    state.error = action.payload;
    state.data = null;
};

export const getOneCalendar__Failure = (
    state: CalendarState,
    action: PayloadAction<string>
) => {
    state.error = action.payload;
    state.data = null;
};

export const updateCalendar__Failure = (
    state: CalendarState,
    action: PayloadAction<string>
) => {
    state.error = action.payload;
    state.data = null;
};

export const deleteCalendar__Failure = (
    state: CalendarState,
    action: PayloadAction<string>
) => {
    state.error = action.payload;
    state.data = null;
};

export const createCalendarSettings__Failure = (
    state: CalendarState,
    action: PayloadAction<string>
) => {
    state.error = action.payload;
    state.data = null;
};

export const getCalendarSettings__Failure = (
    state: CalendarState,
    action: PayloadAction<string>
) => {
    state.error = action.payload;
    state.data = null;
};

export const updateCalendarSettings__Failure = (
    state: CalendarState,
    action: PayloadAction<string>
) => {
    state.error = action.payload;
    state.data = null;
};

export const addConnectedCalendar__Failure = (
    state: CalendarState,
    action: PayloadAction<string>
) => {
    state.error = action.payload;
    state.data = null;
};

export const removeConnectedCalendar__Failure = (
    state: CalendarState,
    action: PayloadAction<string>
) => {
    state.error = action.payload;
    state.data = null;
};

export const createEvent__Failure = (
    state: CalendarState,
    action: PayloadAction<string>
) => {
    state.error = action.payload;
    state.data = null;
};

export const getAllEvents__Failure = (
    state: CalendarState,
    action: PayloadAction<string>
) => {
    state.error = action.payload;
    state.data = null;
};

export const createTask__Failure = (
    state: CalendarState,
    action: PayloadAction<string>
) => {
    state.error = action.payload;
    state.data = null;
};

export const getAllTasks__Failure = (
    state: CalendarState,
    action: PayloadAction<string>
) => {
    state.error = action.payload;
    state.data = null;
};

export const createBookingLink__Failure = (
    state: CalendarState,
    action: PayloadAction<string>
) => {
    state.error = action.payload;
    state.data = null;
};

export const getBookingLinks__Failure = (
    state: CalendarState,
    action: PayloadAction<string>
) => {
    state.error = action.payload;
    state.data = null;
};

export const eventResponse__Failure = (
    state: CalendarState,
    action: PayloadAction<string>
) => {
    state.error = action.payload;
    state.data = null;
};
