import React, { useState } from "react";
import { Box, Button, Stack } from "@mui/material";
import {
  ArchiveIcon,
  DeleteIcon,
  InfoIconBlack,
  PaintRollerIcon,
  SendIcon,
  SnoozeIcon,
  StarIcon,
  WorkspaceCIcon,
} from "../../../../../../../images";

type MenuOption = {
  name: string;
  icon: React.ReactNode;
};

const MenuOptions = () => {
  // Dummy state for menu options
  const [menuOptions] = useState<MenuOption[]>([
    { name: "Starred", icon: <StarIcon w={18} /> },
    { name: "Snoozed", icon: <SnoozeIcon w={18} /> },
    { name: "Sent", icon: <SendIcon w={18} /> },
    { name: "Drafts", icon: <PaintRollerIcon w={18} /> },
    { name: "Scheduled", icon: <WorkspaceCIcon color="#000" w={18} /> },
    { name: "Spam", icon: <InfoIconBlack w={18} /> },
    { name: "Archives", icon: <ArchiveIcon w={18} /> },
    { name: "Trash", icon: <DeleteIcon w={18} /> },
  ]);

  return (
    <Box>
      <Stack gap={"5px"}>
        {menuOptions.map((option, index) => (
          <Button
            key={index}
            sx={{
              backgroundColor: "none",
              textTransform: "capitalize",
              font: "normal normal 300 14px/17px Source Serif Pro",
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-start",
              width: "100%",
              color: "black",
            }}
          >
            {option.icon}
            <span style={{ marginLeft: "8px" }}>{option.name}</span>
          </Button>
        ))}
      </Stack>
    </Box>
  );
};

export default MenuOptions;
