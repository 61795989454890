import { Box, Stack, Typography } from "@mui/material";
import React from "react";
import {
  AngelListBlackPng,
  CreatedIcon,
  FacebookBlackPng,
  InstagramBlackPng,
  LinkedInBlackPng,
  XBlackPng,
} from "../../../../../images";
import { ButtonSmall, ButtonType, SmallButtonColor } from "../../../../common";
import styles from "./EditSocialMediaMenu.module.css";
import { PlainInputBox } from "../PlainInputBox";

interface EditSocialMediaMenuProps {}

const socialMediaLinks = [
  { icon: AngelListBlackPng, name: "AngelList" },
  { icon: XBlackPng, name: "X" },
  { icon: FacebookBlackPng, name: "Facebook" },
  { icon: InstagramBlackPng, name: "Instagram" },
  { icon: LinkedInBlackPng, name: "LinkedIn" },
];

const EditSocialMediaMenu: React.FC<EditSocialMediaMenuProps> = () => {
  return (
    <Stack className={styles.editSocialMediaMenu}>
      <Stack className={styles.editSocialMediaMenu__section}>
        <Stack className={styles.editSocialMediaMenu__header}>
          <CreatedIcon w={15} />
          <Typography
            sx={{
              font: "normal normal 400 14px/18px Source Serif Pro",
            }}
          >
            Socials
          </Typography>
        </Stack>
        <Typography className={styles.editSocialMediaMenu__description}>
          BEBA AI works to automatically find your connections' socials. You can
          also manually adjust them here.
        </Typography>
      </Stack>

      {socialMediaLinks.map((social) => (
        <Stack
          key={social.name}
          className={styles.editSocialMediaMenu__section}
        >
          <Stack className={styles.editSocialMediaMenu__row}>
            <Box
              component="img"
              src={social.icon}
              alt={`${social.name} icon`}
              sx={{ height: "15px", width: "15px" }}
            />
            <Typography
              sx={{ font: "normal normal 300 14px/18px Source Serif Pro" }}
            >
              {social.name}
            </Typography>
          </Stack>
          <PlainInputBox
            className={styles.editSocialMediaMenu__input}
            placeholder="Paste your URL here..."
          />
        </Stack>
      ))}

      <Stack className={styles.editSocialMediaMenu__saveButton}>
        <ButtonSmall
          label="Save"
          types={ButtonType.Button}
          colorVarient={SmallButtonColor.Blue}
        />
      </Stack>
    </Stack>
  );
};

export default EditSocialMediaMenu;
