import React, { useEffect, useRef, useState } from "react";
import { Box, Stack, styled, SxProps } from "@mui/material";
import { AvatarNormal } from "../AvatarNormal";
import { useDispatch } from "react-redux";
import { ChannelDetailsMenu, MessageDetailsMenus } from "../../pages/Message/components";
import {
  messageDetailsMenusChatListForPinChannels,
  messageDetailsMenusChatListForPinChats, messageDetailsMenusChatListForWithOutLeave, upperchenalMenuWithoutLeave,
} from "../../pages/Message/components/MessageDetailsMenus/MessageDetailsMenusType";
import {
  getUserConnections,
  updateConnections,
} from "../../../redux/Slice/message/messageActions";

interface AvatarWithCompanyProps {
  size: 30 | 40;
  userName: string;
  userIconSrc?: string;
  isActive?: boolean;
  companyLogoSrc?: string;
  sx?: SxProps;
  onClick?: any;
  chatItem?: any;
  userId?: any;
}

const AvatarWithCompany: React.FC<AvatarWithCompanyProps> = ({
  size,
  isActive,
  userName,
  companyLogoSrc,
  userIconSrc,
  sx,
  onClick,
  chatItem,
  userId,
}) => {
  const [menuOpen, setMenuOpen] = useState(false);
  const dispatch = useDispatch();
  const menuRef = useRef<HTMLDivElement>(null);
  const detailRef = useRef<HTMLDivElement>(null);
  const [channelData, setChannelData] = useState({
    chName: "",
    topicName: "",
    chDescription: "",
  });
  const [openChannelDetailSection, setOpenChannelDetailSection] =
    useState<boolean>(false);
  const handleClickOutside = (event: MouseEvent) => {
    if (menuRef.current && !menuRef.current.contains(event.target as Node)) {
      setMenuOpen(false);
    }
  };

  useEffect(() => {
    if (menuOpen) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [menuOpen]);

  useEffect(() => {
    if (openChannelDetailSection) {
      document.addEventListener("mousedown", handleClickOutsideForDetail);
    } else {
      document.removeEventListener("mousedown", handleClickOutsideForDetail);
    }
    return () => {
      document.removeEventListener("mousedown", handleClickOutsideForDetail);
    };
  }, [openChannelDetailSection]);

  useEffect(() => {
    if (
      channelData.chName ||
      channelData.topicName ||
      channelData.chDescription
    ) {
      const timer = setTimeout(() => {
        HandleUpdateChannelDetails(channelData);
      }, 3000);

      return () => clearTimeout(timer);
    }
  }, [channelData]);

  const handleClickOutsideForDetail = (event: MouseEvent) => {
    if (
      detailRef.current &&
      !detailRef.current.contains(event.target as Node)
    ) {
      setOpenChannelDetailSection(false);
    }
  };

  const handleRightClick = (event: React.MouseEvent) => {
    event.preventDefault();
    setMenuOpen(true);
  };

  const handleClick = async (data: any) => {
    if (data.value?.includes("Un-Pin")) {
      const channelIsPinned = {
        isPinned: false,
        userId: userId,
      };
      await dispatch(updateConnections(chatItem._id, channelIsPinned));
      await dispatch(getUserConnections(userId));
    }
    if (data.value === "View Channel Details") {
      setMenuOpen(false);
      setOpenChannelDetailSection(true);
    }
    if (data.value?.includes("Leave")){
      const channelIsLeave = {
        channelId: chatItem._id,
        userId: userId,
        isLeave:true

      };
      await dispatch(updateConnections(chatItem._id, channelIsLeave))
      await dispatch(getUserConnections(userId));
    }
  };

  const HandleOnChangedData = (data: any) => {
    setChannelData({
      chName: data.chName,
      topicName: data.topicName,
      chDescription: data.chDescription,
    });
  };

  const handleCloseChannelDetailSection = () => {
    setOpenChannelDetailSection(false);
  };

  const HandleUpdateChannelDetails = async (channelDetailData: any) => {
    if (!channelDetailData.chName || !channelDetailData.chDescription) return;
    if (
      channelDetailData.chName ||
      channelDetailData.topicName ||
      channelDetailData.chDescription
    ) {
      const payload = {
        name: channelDetailData.chName,
        topic: channelDetailData.topicName,
        details: channelDetailData.chDescription,
      };
      try {
        await dispatch(updateConnections(chatItem._id, payload));
        await dispatch(getUserConnections(userId));
      } catch (error) {
        console.error("Failed to update channel details:", error);
      }
    }
  };


  // Styled Badge for the top badge (image)
  const TopBadgeStyle = {
    position: "absolute",
    top: size === 40 ? 0 : 0,
    right: size === 40 ? 0 : -1,
    width: size === 40 ? "10px" : "8px",
    height: size === 40 ? "10px" : "8px",
    boxSizing: "border-box",
  };

  // Custom Box for the bottom badge (color)
  const BottomBadge = styled(Box)(({ theme }) => ({
    position: "absolute",
    bottom: size === 40 ? 0 : 0.5,
    right: size === 40 ? 0 : 1,
    width: size === 40 ? "10px" : "5px",
    height: size === 40 ? "10px" : "5px",
    boxSizing: "border-box",
    borderRadius: "50%",
    backgroundColor: isActive ? "#008D4C" : "#FFB100",
  }));

  return (
    <Stack
      sx={{
        position: "relative",
        width: `${size}px`,
        height: `${size}px`,
        boxSizing: "border-box",
        borderRadius: "50%",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "center",
        cursor: "pointer",
      }}
      onClick={onClick}
      onContextMenu={handleRightClick}
    >
      <AvatarNormal
        username={userName}
        size={size}
        imgSrc={userIconSrc}
        sx={{
          border: size === 40 ? "2px solid #E9EDF2" : "1px solid #E9EDF2",
          ...sx,
        }}
      />
      {companyLogoSrc && (
        <Box
          component={"img"}
          src={companyLogoSrc}
          alt="Top Badge"
          sx={TopBadgeStyle}
        />
      )}
      {menuOpen && (
        <div ref={menuRef}>
          <MessageDetailsMenus
            handleClick={handleClick}
            options={chatItem.isGroupChat ?
                !chatItem?.currentUserLeave ? messageDetailsMenusChatListForPinChannels : messageDetailsMenusChatListForWithOutLeave :
                !chatItem?.currentUserLeave ? messageDetailsMenusChatListForPinChats : upperchenalMenuWithoutLeave}
          />
        </div>
      )}
      {isActive !== undefined && <BottomBadge />}

      {openChannelDetailSection && (
        <div ref={detailRef}>
          <ChannelDetailsMenu
            onChangedData={HandleOnChangedData}
            channelDetails={chatItem}
            closeChannelDetailSection={handleCloseChannelDetailSection}
          />
        </div>
      )}

    </Stack>
  );
};

export default AvatarWithCompany;
