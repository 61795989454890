import {createApiAction} from "../constantApi_config";
import { createMeetingFailureAction, createMeetingRequestAction, createMeetingSuccessAction, getUserMeetingsFailureAction, getUserMeetingsRequestAction, getUserMeetingsSuccessAction } from "./VideoCenterSlice";

export const createMeeting = () => createApiAction({
    onStart: createMeetingRequestAction.type,
    onSuccess: createMeetingSuccessAction.type,
    onError: createMeetingFailureAction.type,
    method: "POST",
    url: "/meeting/create",
    axiosRequired: true,
    unwrap: false
});

export const getMeeting = (meetingId: string) => createApiAction({
    onStart: "meeting/get/request",
    onSuccess: "meeting/get/success",
    onError: "meeting/get/failure",
    method: "GET",
    url: `/meeting/${meetingId}`,
    axiosRequired: true,
    unwrap: false,
    
});
export const getUserMeetings = (token:any) => createApiAction({
    onStart: getUserMeetingsRequestAction.type,
    onSuccess: getUserMeetingsSuccessAction.type,
    onError: getUserMeetingsFailureAction.type,
    method: "GET",
    url: `/meeting/list`,
    axiosRequired: true,
    unwrap: false,
    headers: { language: "en",Authorization: `Bearer ${token} `}
});

// export const getAttendee = (meetingId: string, attendeeId: string) => createApiAction({
//     onStart: "meeting/get/request",
//     onSuccess: "meeting/get/success",
//     onError: "meeting/get/failure",
//     method: "GET",
//     url: `/meeting/${meetingId}/${attendeeId}`,
//     axiosRequired: true,
//     unwrap: false
// });

export const createAttendee = (data: any) => createApiAction({
    onStart: "meeting/attendee/create/request",
    onSuccess: "meeting/attendee/create/success",
    onError: "meeting/attendee/create/failure",
    method: "POST",
    url: "/meeting/attendee/create",
    axiosRequired: true,
    data,
    unwrap: false
});