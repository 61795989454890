// App.tsx
import React, { lazy, useEffect, useState } from "react";
import { Box } from "@mui/material";
import pusher from "../../../Utils/pusher";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../redux/store";
import { AuthState } from "../../../redux";
import { getUserConnections } from "../../../redux/Slice/message/messageActions";
import { MessageTabBar } from "../../common/MessageTabBar";
import LazyNewUpdateActivityTab from "./newUpdateActivityTab";
import { DoneActivityTab } from "./doneActivityTab";
import SnoozeActivityTab from "./snoozeActivityTab";
import {
  requestNotificationPermission,
  triggerNotification,
  triggerNotificationForCreatedNewConnection,
  triggerNotificationForReaction,
} from "../../../Utils/notifications";
import { MessagesState } from "../../../redux/Slice/message/messageTypes";

const tabs: {
  label: string;
  content: React.ReactNode;
}[] = [
  {
    label: "New Update",
    content: <LazyNewUpdateActivityTab />,
  },
  {
    label: "Done",
    content: "<LazyActivityTab />",
  },
  {
    label: "Snoozed",
    content: "<LazyConnectedTab />",
  },
  {
    label: "Summaries",
    content: "<LazyConnectedTab />",
  },
];

const Message: React.FC = () => {
  const dispatch = useDispatch();
  const { user } = useSelector((state: RootState) => state.auth as AuthState);
  const { currentChannelId,channels } = useSelector(
    (state: RootState) => state.message as MessagesState
  );

  const [selectedTab, setSelectedTab] = useState(0);

  useEffect(() => {
    requestNotificationPermission();
    if (user?.data?._id) {
      var channel: any = pusher.subscribe(
        user?.data?._id ? user?.data?._id : ""
      );
    }
    var callback = (eventName: any, data: any) => {
      if (eventName === "new-connection") {
        dispatch(getUserConnections(user?.data._id));
        triggerNotificationForCreatedNewConnection(data, dispatch);
      } else if (eventName === "new-message") {
        dispatch(getUserConnections(user?.data._id));
        if (data.addEmoji) {
          triggerNotificationForReaction(dispatch, data, user?.data);
        } else {
            triggerNotification(dispatch, data, user?.data,channels);
        }
      }
    };
    if (channel) {
      channel.bind_global(callback);
    }

    return () => {
      if (channel) {
        channel.unbind_global(callback);
        pusher.unsubscribe(user?.data?._id);
      }
    };
  }, [currentChannelId]);

  useEffect(() => {
    if (user) {
      dispatch(getUserConnections(user?.data._id));
    }
  }, []);
  let content;

  switch (selectedTab) {
    case 0:
      content = <LazyNewUpdateActivityTab />;
      break;
    case 1:
      content = <DoneActivityTab />;
      break;
    case 2:
      content = <SnoozeActivityTab />;
      break;
    // case 3:
    //   content = <ComponentFour />;
    //   break;
    default:
      content = <div>Please select a tab.</div>; // Default case
  }

  useEffect(() => {
    if (selectedTab === 0) {
    }
  }, [selectedTab]);

  return (
    <Box
      display="flex"
      padding="0 30px 0 30px"
      flexDirection="column"
      height="92vh"
      width="100%"
    >
      <div style={{ display: "flex", flexDirection: "column", gap: "5px" }}>
        <MessageTabBar
          tabs={tabs}
          selectedTab={selectedTab}
          setSelectedTab={setSelectedTab}
        />
        {content}
      </div>
    </Box>
  );
};

export default Message;
