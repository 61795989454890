type MenuList = {
  divider?: false;
  value: string;
  textColor?: string;
};

type MenuDivider = {
  divider: true;
  value?: never;
  textColor?: never;
};

export type MessageDetailsMenusListInterface = MenuList | MenuDivider;

// Example usage:
export const messageDetailsMenusChannelList: MessageDetailsMenusListInterface[] =
  [
    { value: "View Channel Details" },
    { value: "Copy link" },
    { divider: true },
    { value: "Mute Channel" },
    { value: "Notification Settings" },
    { value: "Pin Channel" },
    { divider: true },
    { value: "Leave Channel", textColor: "#FF0000" },
  ];

//GROUP
export const messageDetailsMenusChatListForPinChannels: MessageDetailsMenusListInterface[] = [
  { value: "View Channel Details" },
  { value: "Copy link" },
  { divider: true },
  { value: "Mute Channel" },
  { value: "Hide Channel", textColor: "#9747FF" },
  { value: "Un-Pin Channel" },
  { divider: true },
  { value: "Leave Channel", textColor: "#FF0000" },
];
//GROUP
export const messageDetailsMenusChatListForWithOutLeave: MessageDetailsMenusListInterface[] = [
  { value: "View Channel Details" },
  { value: "Copy link" },
  { divider: true },
  { value: "Mute Channel" },
  { value: "Hide Channel", textColor: "#9747FF" },
  { value: "Un-Pin Channel" },
  { divider: true },
];

//PERSONAL
export const messageDetailsMenusChatList: MessageDetailsMenusListInterface[] = [
    { value: "Copy link" },
    { divider: true },
    { value: "Mute Chat" },
    { value: "Hide Chat", textColor: "#9747FF" },
    { value: "Pin Chat" },
    { divider: true },
    // { value: "Leave Chat", textColor: "#FF0000" },
];

//PERSONAL
export const messageDetailsMenusChatListForPinChats: MessageDetailsMenusListInterface[] = [
    { value: "Copy link" },
    { divider: true },
    { value: "Mute Chat" },
    { value: "Hide Chat", textColor: "#9747FF" },
    { value: "Un-Pin Chat" },
    { divider: true },
    // { value: "Leave Chat", textColor: "#FF0000" },
];


//GROUP
export const hideLeaveForGroup: MessageDetailsMenusListInterface[] =
    [
        { value: "View Channel Details" },
        { value: "Copy link" },
        { divider: true },
        { value: "Mute Channel" },
        { value: "Notification Settings" },
        { value: "Pin Channel" },
        { divider: true },
    ];

//PERSONAL
export const hideLeaveForPrsonalChat: MessageDetailsMenusListInterface[] = [
    { value: "Copy link" },
    { divider: true },
    { value: "Mute Chat" },
    { value: "Hide Chat", textColor: "#9747FF" },
    { value: "Pin Chat" },
    { divider: true },
];

//PERSONAL
export const upperchenalMenuWithoutLeave: MessageDetailsMenusListInterface[] = [
    { value: "Copy link" },
    { divider: true },
    { value: "Mute Chat" },
    { value: "Hide Chat", textColor: "#9747FF" },
    { value: "Un-Pin Chat" },
    { divider: true },
];



