import * as React from "react";
import {
  Box,
  ClickAwayListener,
  InputBase,
  InputAdornment,
  Stack,
  SxProps,
  Popover,
} from "@mui/material";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DateCalendar } from "@mui/x-date-pickers/DateCalendar";
import { Dayjs } from "dayjs";
import { ClockIcon } from "../../../images";

interface DatePickerWithIconProps {
  hideIcon?: boolean;
  sx?: SxProps;
  dateFormat?: "MM/DD/YY" | "MM-DD-YYYY" | "MMM D, YYYY";
  initialDate?: Dayjs | null; // Added initialDate prop
  onDateChange?: (date?: string) => void; // Optional date toString callback
  readonlyComponent?: boolean;
  // initialTime
}

const DatePickerWithIcon: React.FC<DatePickerWithIconProps> = ({
  hideIcon,
  sx,
  dateFormat = "MM/DD/YY", // default to "MM/DD/YY"
  initialDate = null, // default initial date
  onDateChange,
  readonlyComponent = false,
}) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [selectedDate, setSelectedDate] = React.useState<Dayjs | null>(
    initialDate
  );

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    if (!readonlyComponent) {
      setAnchorEl(event.currentTarget);
    }
  };

  const handleClickAway = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "single-date-picker-popover" : undefined;

  // Helper function to determine placeholder format
  const getPlaceholder = (format: string) => {
    switch (format) {
      case "MM/DD/YY":
        return "00/00/00";
      case "MM-DD-YYYY":
        return "00-00-0000";
      case "MMM D, YYYY":
        return "00-00-0000";
      default:
        return "";
    }
  };

  // Format the date based on the provided dateFormat prop
  const formatDate = selectedDate ? selectedDate.format(dateFormat) : "";

  const handleDateChange = (newValue: Dayjs | null) => {
    setSelectedDate(newValue);
    setAnchorEl(null);

    // Return the date as a string if available, else undefined
    if (onDateChange) {
      onDateChange(newValue ? newValue.format(dateFormat) : undefined);
    }
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <ClickAwayListener onClickAway={handleClickAway}>
        <Stack
          sx={{
            cursor: "pointer",
            position: "relative",
            flexDirection: "row",
            alignItems: "center",
            paddingTop: "1px",
            width: "65px",
            font: "normal normal 300 11px/11px Source Serif Pro",
            ...sx,
          }}
        >
          <InputBase
            sx={{
              borderRadius: "5px",
              height: "14px",
              width: "inherit",
              boxSizing: "border-box",
              paddingTop: "0px",
              font: "inherit",
            }}
            inputProps={{
              style: {
                cursor: "pointer",
              },
            }}
            startAdornment={
              !hideIcon && (
                <InputAdornment
                  position="end"
                  sx={{
                    marginLeft: "auto",
                    padding: "0px 5px 0px 0px",
                    cursor: "pointer",
                  }}
                >
                  <ClockIcon w={14} />
                </InputAdornment>
              )
            }
            onClick={handleClick}
            value={formatDate}
            placeholder={getPlaceholder(dateFormat)} // Use the helper function
            readOnly
          />

          <Popover
            id={id}
            open={open}
            anchorEl={anchorEl}
            onClose={handleClickAway}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "left",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "left",
            }}
          >
            <Box
              sx={{
                bgcolor: "background.paper",
                boxShadow: 1,
                cursor: "pointer",
                mt: 1,
              }}
            >
              <DateCalendar value={selectedDate} onChange={handleDateChange} />
            </Box>
          </Popover>
        </Stack>
      </ClickAwayListener>
    </LocalizationProvider>
  );
};

export default DatePickerWithIcon;
