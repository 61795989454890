import { Stack, Typography, Popper, Paper, SxProps } from "@mui/material";
import React, { useState, useRef } from "react";
import styles from "./UserInfoChip.module.css";
import { AvatarNormal, SmallContactCardHover } from "../../../../../common";
import { UserInfoChipProps } from "./UserInfoChipType";

interface UserinfoType extends UserInfoChipProps {
  popoverPlace: "bottom" | "right";
  showOnlyName?: boolean;
  fontSx?: SxProps;
  userFirstName?: string;
}

const UserInfoChip: React.FC<UserinfoType> = ({
  popoverPlace,
  showOnlyName = false,
  fontSx,
  userEmail,
  userName,
  userFirstName,
  userAvatarColor,
  userIconSrc,
}) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const timeoutRef = useRef<NodeJS.Timeout | null>(null); // Reference to store the timeout ID

  const handleChipHover = (event: React.MouseEvent<HTMLElement>) => {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current); // Clear the existing timeout when the mouse re-enters
    }
    setAnchorEl(event.currentTarget);
  };

  const handleChipLeave = () => {
    timeoutRef.current = setTimeout(() => {
      setAnchorEl(null);
    }, 50); // Start the timeout when the mouse leaves
  };

  // Function to get Popper placement based on the popoverPlace prop
  const getPopperPlacement = () => {
    switch (popoverPlace) {
      case "bottom":
        return "bottom-start";
      case "right":
        return "right-start";
      default:
        return "bottom-start"; // Default value if none of the cases match
    }
  };

  return (
    <div onMouseEnter={handleChipHover} onMouseLeave={handleChipLeave}>
      {showOnlyName ? (
        <Typography
          className={styles.userInfoChip__text}
          sx={{
            cursor: "pointer",
            font: "normal normal 300 11px/14px Source Serif Pro",
            ...fontSx,
          }}
        >
          {userFirstName || userName}
        </Typography>
      ) : (
        <Stack className={styles.userInfoChip}>
          <AvatarNormal
            size={15}
            username={userName}
            avatarColor={userAvatarColor}
            imgSrc={userIconSrc}
          />
          <Typography
            className={styles.userInfoChip__text}
            sx={{
              font: "normal normal 300 11px/14px Source Serif Pro",
              ...fontSx,
            }}
          >
            {userFirstName || userName}
          </Typography>
        </Stack>
      )}

      <Popper
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        placement={getPopperPlacement()}
        onMouseLeave={handleChipLeave}
        sx={{
          zIndex: 10,
          border: "0px",
          borderRadius: "5px",
        }}
      >
        <Paper
          sx={{
            boxShadow: "0px 3px 3px 0px rgba(0, 0, 0, 0.15)",
          }}
        >
          <SmallContactCardHover
            userEmail={userEmail}
            userName={userName}
            userIconSrc={userIconSrc}
            userAvatarColor={userAvatarColor}
          />
        </Paper>
      </Popper>
    </div>
  );
};

export default UserInfoChip;
